import React from "react";
import NewsCard from "../News/NewsCard";
import NewsCategoryTitle from "../NewsCategory/NewsCategoryTitle";
import { useSelector } from "react-redux";
import { withTranslation } from 'react-i18next';

const Layout6 = ({ titleColor, data, name, logo, props, analyticsInfo }) => {

  const isPreview = useSelector(state => state.info.isPreview);
  const warningOn = data.length < 4 ? true : false;
  const { t } = props;

  return data ? (
    <div>
      <NewsCategoryTitle
        props={props}
        isFixedLayout={false}
        titleColor={titleColor}
        name={name}
        analyticsInfo={analyticsInfo}
        logo={logo}
      />
      {isPreview && warningOn &&
        <div class="alert alert-warning pl-3">
          <strong>{t("errors.warning")}</strong> {t("errors.insuficientContent")}
        </div>
      }
      {data.length > 0 ?
        <div className="row pl-3">
          {data.map((card, index) => (
            <div key={index} className="col-12 col-md-3 pl-0">
              <NewsCard
                id={card?.id}
                type="card"
                icon={card?.fields?.has_video ? "video_icon" : "link_icon"}
                key={index}
                cardSize="card_news one_col_tall_news"
                cardinfo={card}
                image={card?.fields?.cover_image?.directURLArray.large}
                imageClass={card?.fields?.image_alignment}
                createdDate={
                  card?.fields?.authoring_date?.value
                }
                summary={card?.fields?.title}
                tagList={card?.fields?.tags_list}
                analyticsInfo={analyticsInfo}
              />
            </div>
          ))}
        </div>
        : null}
    </div>
  ) : null;
};

export default withTranslation()(Layout6);
