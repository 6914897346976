import React, { useEffect, useState, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

const Footer = (props) => {

  const { t } = props;

  const dispatch = useDispatch();

  const categories = useSelector((state) => state.categories.categoriesList);
  const locale = useSelector((state) => state.info.locale);
  const pathname = useSelector((state) => state.info.pathname);

  const [isScrollable, setIsScrollable] = useState(true);
  const [isVisible, setIsVisible] = useState(true);
  const [hasSocialMedia, setHasSocialMedia] = useState(false);
  /* 
    useEffect(() => {
  
      let body = document.getElementById("root");
      // let footer = document.getElementById("footer-body");
  
      console.log("altura", window.innerHeight + window.scrollY);
      console.log("window", document.body.scrollHeight);
  
      if ((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight) {
        setIsScrollable(false);
      }
      else {
        setIsScrollable(true);
      }
  
    }, [window.location.pathname]) */


  /*  footer invisivel quando está nas paginas com infinite scroll */

  useEffect(() => {

    if (window.location.pathname.includes("category") || window.location.pathname.includes("Video-Library") || window.location.pathname.includes("ekhsearch")) {
      setIsVisible(false);
      setHasSocialMedia(false)
    }
    else if (window.location.pathname === "/en" || window.location.pathname === "/ar" || window.location.pathname === "/") {
      setIsVisible(true);
      setHasSocialMedia(true);
    }
    else {
      setIsVisible(true);
      setHasSocialMedia(false);
    }
  }, [window.location.pathname])

  return (
    <div id="footer-body" className={isScrollable ? "" : "fixed-footer"}>
      {hasSocialMedia &&
        <div className="col-12 text-center social_foooter mt-5 mb-5" >
          <p style={{ textAlign: "center" }} className="text-uppercase">{t("home.followUs")}</p>
          <ul className="nav_social align-self-center m-0 p-0">
            <li className="mr-4">
              <a target="_blank" href={locale === "enUS" ? "https://twitter.com/ekhnews_en?s=21" : "https://twitter.com/alekhbariyatv?s=21"} alt="Goto alekhbariya twitter" title="twitter">
              <i class="fa-brands fa-x-twitter"></i>
              </a>
            </li>
            <li className="mr-4">
              <a target="_blank" href="https://www.facebook.com/alekhbariya.sba" alt="Goto alekhbariya facebook" title="facebook">
                <i className="fab fa-facebook-f"></i>
              </a>
            </li>
            <li className="mr-4">
              <a target="_blank" href="https://www.instagram.com/alekhbariya.tv" alt="Goto alekhbariya instagram" title="instagram">
                <i className="fab fa-instagram"></i>
              </a>
            </li>
            <li>
              <a target="_blank" href="https://youtube.com/ekhbariyatv" alt="Goto alekhbariya youtube" title="youtube">
                <i className="fab fa-youtube"></i>
              </a>
            </li>
          </ul>
        </div>
      }
      <div className="container-fluid foooter mt-auto">
        <div className="container">
          <div className="row d-block d-md-none">
            <div className="col-12 text-center pt-3 pb-3 back_to_top">
              <p style={{ textAlign: "center"}}>
                <a href="#">Back to top</a>
              </p>
            </div>
            <div className="col-12 text-center pt-3 pb-3">
              <p style={{ textAlign: "center"}}>
                <Link onClick={() =>
                  dispatch({
                    type: "CHANGED_PATH",
                    path: `/${locale === "arSA" ? "ar" : "en"}/privacy`
                  })
                }
                  to={{
                    pathname: `/${locale === "arSA" ? "ar" : "en"}/privacy`
                  }}>
                  <li><a href="" title="">{t("home.privacy")}</a></li>
                </Link>
                </p>
            </div>
            </div>
              
          <div className="row">
            <div className="col-12">
              <ul className="p-0 m-0 list-unstyled">
                {Object.values(categories).map((category, index) => {
                  if (category.navigation_menu_checkbox) {
                    return (<li key={index}>
                      <Link onClick={() =>
                        dispatch({
                          type: "CHANGED_PATH",
                          path: `/${locale === "arSA" ? "ar" : "en"}/category/${category.category_name}`
                        })
                      }
                        to={{
                          pathname: `/${locale === "arSA" ? "ar" : "en"}/category/${category.category_name}`
                        }}
                      >
                        {category.category_name}
                      </Link>
                      </li>
                    )
                  }
                })}
                {/*<Link onClick={() =>
                  dispatch({
                    type: "CHANGED_PATH",
                    path: `/${locale === "arSA" ? "ar" : "en"}/programs`
                  })
                }
                  to={{
                    pathname: `/${locale === "arSA" ? "ar" : "en"}/programs`
                  }}
                >
                  <li><a href="" title="">{t("header.programs")}</a></li>
                </Link>*/}
                <li><Link onClick={() =>
                  dispatch({
                    type: "CHANGED_PATH",
                    path: `/${locale === "arSA" ? "ar" : "en"}/Video-Library`
                  })
                }
                  to={{
                    pathname: `/${locale === "arSA" ? "ar" : "en"}/Video-Library`
                  }}
                >
                  {t("videoLibrary")}
                </Link></li>
                {/*<Link onClick={() =>
                  dispatch({
                    type: "CHANGED_PATH",
                    path: `/${locale === "arSA" ? "ar" : "en"}/podcasts`
                  })
                }
                  to={{
                    pathname: `/${locale === "arSA" ? "ar" : "en"}/podcasts`
                  }}
                >
                  <li><a href="" title="">{t("podcasts.podcasts")}</a></li>
                </Link>*/}
                <li><Link onClick={() =>
                  dispatch({
                    type: "CHANGED_PATH",
                    path: `/${locale === "arSA" ? "ar" : "en"}/Help`
                  })
                }
                  to={{
                    pathname: `/${locale === "arSA" ? "ar" : "en"}/Help`
                  }}>
                  {t("home.help")}
                </Link></li>
                <Link onClick={() =>
                  dispatch({
                    type: "CHANGED_PATH",
                    path: `/${locale === "arSA" ? "ar" : "en"}/privacy`
                  })
                }
                  to={{
                    pathname: `/${locale === "arSA" ? "ar" : "en"}/privacy`
                  }}>
                  <li><a href="" title="">{t("home.privacy")}</a></li>
                </Link>
                
              </ul>
            </div>
            <div className="col-12 text-center mt-4 store_logos">
                <a target="_blank" href="https://apps.apple.com/sa/app/%D8%A7%D9%84%D8%A5%D8%AE%D8%A8%D8%A7%D8%B1%D9%8A%D8%A9/id1593447272" title="">
                  <img src="/assets/images/app_store_ar.svg" alt="apple app store"/>
                </a>
                <a target="_blank" href="https://play.google.com/store/apps/details?id=smm.ekh.mobile" title="">
                  <img src="/assets/images/google-play-badge_ar.svg" alt="play store"/>
                </a>
            </div>
            <div className="col-12 text-center mt-5">
              <p style={{ textAlign: "center" }}>{t("home.rightsReserved")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}

export default withTranslation()(Footer);