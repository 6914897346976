import { CHANGE_LANGUAGE, CHANGE_PATH, SET_THEME, IS_PREVIEW, STREAM_REFRESH, COOKIE_CONSENT } from "../constants/infoConstants";

export function info(state = [], action) {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return {
        ...state,
        locale: action.locale
      };
    case CHANGE_PATH:
      return {
        ...state,
        pathname: action.path
      };
    case SET_THEME:
      return {
        ...state,
        theme: action.payload
      };
    case IS_PREVIEW:
      return {
        ...state,
        isPreview: action.isPreview
      };
    case STREAM_REFRESH:
      return {
        ...state,
        streamRefresh: action.streamRefresh
      };
    case COOKIE_CONSENT:
      return {
        ...state,
        cookieConsent: {...state.cookieConsent, consent: action.consent}
      };

    default:
      return state;
  }
}
