import env from "../../config/env";
import axios from "axios";

import {
  PROGRAMS_LIST_REQUEST,
  PROGRAMS_LIST_SUCESS,
  PROGRAMS_LIST_FAIL
} from "../constants/programsConstants";

export const fetchAllProgramsThunk = (locale, latestEpisodeFlag) => async (dispatch) => {

  try {
    dispatch({ type: PROGRAMS_LIST_REQUEST });
    const { data } = await axios.get(
      `${env.serverUrl}/ekh-rest-api/programs/list?contentLanguage=${locale}&latestEpisodeFlag=${latestEpisodeFlag}`,
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    dispatch({
      type: PROGRAMS_LIST_SUCESS,
      payload: treatItems(data, 'Program')
    });
  } catch (error) {
    dispatch({
      type: PROGRAMS_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }


}

export function fetchEPG() {

  return new Promise((resolve, reject) => {
    axios({
      url: `${env.serverUrl}/ekh-rest-api/generic/epg/live`,
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log("EPG Request Error");
        console.log(error);
        reject();
      });
  });
}

export function fetchAllProgramEpisodes(id, name, token) {

  let episodeNameFilter = "";

  if (name !== "") episodeNameFilter = `&name=${name}`

  return new Promise((resolve, reject) => {
    axios({
      url: encodeURI(`${env.serverUrl}/ekh-rest-api/programs/videos/list?folderID=${id}${episodeNameFilter}`),
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
      cancelToken: token
    })
      .then((response) => {

        if (response.data.length === 0) reject();
        else resolve(treatItems(response.data, "Episode"));

      })
      .catch((error) => {
        console.log("Programs Episode Request Error");
        console.log(error);
        reject();
      });
  });

}

export function fetchEpisodeDetail(id) {

  return new Promise((resolve, reject) => {
    axios({
      url: `${env.serverUrl}/ekh-rest-api/programs/getVideoDetails?videoID=${id}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((response) => {
        resolve(treatItems(response.data));
      })
      .catch((error) => {
        console.log("Episode Detail Request Error");
        console.log(error);
        reject();
      });
  });
}

function treatItems(data, itemType) {

  let items = [];
  let new_item = {};
  let last_item = {};
  let episode_item = {};

  if (itemType === "Program") {
    data.forEach((item, index) => {
      new_item = buildProgramObject(item);
      items.push(new_item);
    });
  }
  else if (itemType === "Episode") {
    data.forEach((item, index) => {
      new_item = buildEpisodeObject(item);
      items.push(new_item);
      // neste caso last item ee o primeiro, porque estamos a falar do ultimo episodio disponivel
      if (index === 0) last_item = new_item;
    });
  }
  else {
    data.forEach((item, index) => {
      episode_item = buildEpisodeDetailObject(item);
    });
  }

  if (itemType === "Program") return items
  else if (itemType === "Episode") return { items: items, lastEpisode: last_item }
  else return episode_item;
}

function buildProgramObject(item) {

  return {
    id: item.Id ? item.Id : 0,
    title: item.Title ? item.Title : "No title",
    image: item.Picture1 ? item.Picture1 : "https://images.unsplash.com/photo-1612461313144-fc1676a1bf17?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1291&q=80",
    latestEpisode: item.latestEpisode ? item.latestEpisode.Title : "No latest episode",
    latestEpisodeDate: item.latestEpisode ? item.latestEpisode.UploadDate : "No latest episode"
  }
}

function buildEpisodeObject(item) {

  return {
    startTime: item.EpochTime ? item.EpochTime : "Upload date undefined",
    programName: item.Folder[0].Name ? item.Folder[0].Name : "name undefined by KWIKmotion",
    episodeName: item.Title ? item.Title : "No title",
    thumbnail: item.Thumbnail ? item.Thumbnail : "no thumbnail available",
    isProgram: false,
    description: item.Description ? item.Description : '',
    id: item.Key ? item.Key : "id undefined by KWIKmotion"
  };
}

function buildEpisodeDetailObject(item) {

  let sourceURL = ""
  let programName = ""

  item.Source.map((videoSource) => {
    sourceURL = videoSource.HLS[0].Link;
  })

  item.folder.map((elem) => {
    programName = elem.name;
  })

  return {
    source: sourceURL ? sourceURL : "",
    duration: item.DurationInSeconds,
    programName: programName,
    title: item.Title,
    summary: item.Description,
  }
}
