import React from "react"
import ContentLoader from "react-content-loader"

const MyLoader = (props) => (
  <ContentLoader
    speed={2}
    width={650}
    height={350}
    viewBox="0 0 550 280"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="22" y="1" rx="3" ry="3" width="650" height="450" />

  </ContentLoader>
)

export default MyLoader

