import "./assets/css/alekhbariya_link.css";
import "./assets/css/alekhbariya.css";

import React from "react";
import App from "./App";
import ReactDOM from "react-dom";

import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import env from "./config/active.env";

import GA4React from "ga-4-react";
const ga4react = new GA4React("G-TYHZ34ZNT2", {
  // "debug_mode": env === "smm-ekh-prd" ? false : true
});

i18next.init({
  interpolation: { escapeValue: false } // React already does escaping
});

(async () => {
  try {
    //might be blocked by software addons such as AdBlock Plus, uBlock Origin, Ghostery, Brave browser, and more
    env !== "smm-ekh-prd" && (window["ga-disable-G-TYHZ34ZNT2"] = true);
    await ga4react.initialize();
  } catch (error) {
    console.log("error initializing ga4react:", error);
  }

  ReactDOM.render(
    <React.StrictMode>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
})();
