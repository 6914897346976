import React, { useState, useEffect, useRef } from "react";
import { fetchLatestVideos, fetchVideos } from '../../store/actions/videolibrary';
import { useDispatch, useSelector } from "react-redux";
import VideoCard from "../Podcasts/VideoCard";
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import {isMobile} from "react-device-detect";
import useMobileDetect from "use-mobile-detect-hook";
import Carousel from 'react-elastic-carousel';
import OwlCarousel from "react-owl-carousel-rtl";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import sendAnalyticsEvent from "../../scripts/analyticsEvent";


const VideoLibraryLayout = (props) => {

    const [videos, setVideos] = useState(null);
    const [loadingVideos, setLoadingVideos] = useState(true);
    const locale = useSelector((state) => state.info.locale);
    const cookieConsent = useSelector((state) => state.info.cookieConsent.consent);

    const { t } = props;
    
    const dispatch = useDispatch();

    const sliderRef = useRef();
    const sliderRefMobile = useRef();
    const detectMobile = useMobileDetect();
    const [isMobile, setIsMobile] = useState(false);

    const breakPoints = [
        { width: 1, itemsToShow: 1.25 },
        { width: 550, itemsToShow: 2.5 },
        { width: 768, itemsToShow: 3.25 },
        { width: 1200, itemsToShow: 4.25 }
      ];

    const responsive = {
        margin: 30,
        responsive: true,
        dots: false,
        responsive: {
            0: {
                items: 4,
            },
            400: {
                items: 4,
            },
            600: {
                items: 4.5,
            },
            700: {
                items: 5.5,
            },
            1000: {
                items: 5,
            }
        }
      };


    useEffect(() => {
        if (detectMobile.isMobile()) setIsMobile(true);
        
    }, [detectMobile]);


    useEffect(() => {
        // FETCH VIDEOS CONTENT
        setLoadingVideos(true);
        async function fetchAllVideos() {
            await fetchVideos(locale, isMobile ? 4 : 8, 0, "", null, null)
            //await //fetchLatestVideos(locale, isMobile ? 4 : 8)
                .then((videoList) => {
                    setVideos(videoList.items);
                    setLoadingVideos(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoadingVideos(false);
                });
        }
        fetchAllVideos();
    }, [locale]);

    function next() {
        sendAnalyticsEvent({cookieConsent: cookieConsent ,type:"click", label:`carousel arrow`, value:"next", section:props.analyticsInfo?.section, page:props.analyticsInfo?.page})

        sliderRef.current.next();
      }
      function previous() {
        sendAnalyticsEvent({cookieConsent: cookieConsent ,type:"click", label:`carousel arrow`, value:"previous", section:props.analyticsInfo?.section, page:props.analyticsInfo?.page})

        sliderRef.current.prev();
      }

    return (
        <div>
            <div class="row mt-0 mb-0 mt-md-3">
                {!loadingVideos && videos &&
                    videos.length > 0 && <div class="col-12 d-flex justify-content-start mb-3 mb-md-0">
                        <h1 class="align-self-end no_cat" /* style={{paddingRight: locale === "enUS"  ? "" : "29px", fontSize: "2.25rem", fontWeight: "900"}} */>{t("podcasts.latestVideos")}</h1>
                        <Link
                            onClick={() =>{
                                dispatch({ type: "CHANGED_PATH", path: `/${locale === "arSA" ? "ar" : "en"}/Video-Library` });
                                sendAnalyticsEvent({cookieConsent: cookieConsent ,type:"click", label:`view all`, section:props.analyticsInfo?.section, page:props.analyticsInfo?.page})
                            }
                            }
                            to={{
                                pathname: `/${locale === "arSA" ? "ar" : "en"}/Video-Library`
                            }}
                            className="view_all align-self-end cat_1 mb-1 mb-md-2">
                            {t("generic.viewAll")}
                        </Link>
                    </div>}
                    {videos && videos.length >= 4 ?
                        <div className="col-12 d-none d-md-flex carousel_buttons">
                            <button className="previous" onClick={previous}></button>
                            <button className="next" onClick={next}></button>
                        </div> : null}
            </div>
            <div className="row">
                {isMobile && videos ?
                    <Carousel ref={sliderRefMobile} breakPoints={breakPoints}  isRTL showArrows={false} >
                        { videos.map((cardnew, index) => {
                            return (
                            <div className="carousel-item-elastic">
                                <VideoCard
                                    loadingVideos={loadingVideos}
                                    type="card"
                                    icon="video_icon"
                                    cardSize="card_news three_row_news two_row"
                                    cardinfo={cardnew}
                                    image={cardnew?.fields?.cover_image?.directURL}
                                    imageClass={cardnew?.fields?.image_alignment}
                                    createdDate={
                                        cardnew?.fields?.authoring_date?.value
                                    }
                                    summary={cardnew?.description}
                                    video={cardnew?.fields?.video?.directURL}
                                    entryId={cardnew?.fields?.video?.entryId}
                                    analyticsInfo={props.analyticsInfo}
                                />
                            </div>
                                );
                        })}
                    </Carousel> : videos &&
                    <OwlCarousel ref={sliderRef} className="owl-carousel owl-theme" responsive={responsive} autoWidth={true} loop rtl dots={false}>
                    { videos.map((cardnew, index) => {
                            return (
                                    <VideoCard
                                        loadingVideos={loadingVideos}
                                        type="card"
                                        icon="video_icon"
                                        cardSize="card_news highlight_news"
                                        cardinfo={cardnew}
                                        image={cardnew?.fields?.cover_image?.directURL}
                                        imageClass={cardnew?.fields?.image_alignment}
                                        createdDate={
                                            cardnew?.fields?.authoring_date?.value
                                        }
                                        summary={cardnew?.description}
                                        video={cardnew?.fields?.video?.directURL}
                                        entryId={cardnew?.fields?.video?.entryId}
                                        analyticsInfo={props.analyticsInfo}
                                    />
                            );
                        })}
                    </OwlCarousel> }
                    
            </div>
        </div>
    )

}

export default withTranslation()(VideoLibraryLayout);