import React from 'react';
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

const PodcastCard = (props) => {

    const { t } = props.props;

    const locale = useSelector(state => state.info.locale);

    return (
        <div className={props.type == "Video-Podcast" ? "col-12 col-md-4 pl-0 mb-3" : "col-6 col-md-2 pl-0 mb-3"}>
            <div className={props.type == "Video-Podcast" ? "card_news three_row_news program" : "card_news square program"}>
                <Link to={{ pathname: `/${locale === "arSA" ? "ar" : "en"}/podcasts/${props.type}/${props.id}` }} title="">
                    {props.image &&
                        <img className={props.imageClass} src={props.image} alt="Podcast cover" />
                    }
                </Link>
            </div>
            <p className="album_title">{props.name}</p>
            <dl className="album">
                {props.type == "Video-Podcast" ? <dt>{t('podcasts.video')}</dt> : <dt>{t('podcasts.audio')}</dt>}
                <dd>{props.count} {props.count > 1 || props.count == 0 ? t('podcasts.episodesWithE') : t('podcasts.oneEpisode')}</dd>

            </dl>
        </div>
    )
}

export default withTranslation()(PodcastCard);