import React from "react";
import NewsCard from "../News/NewsCard";
import NewsCategoryTitle from "../NewsCategory/NewsCategoryTitle";
import { useSelector } from "react-redux";
import { withTranslation } from 'react-i18next';


const Layout5 = ({ titleColor, data, name, logo, props, analyticsInfo }) => {

  const isPreview = useSelector(state => state.info.isPreview);
  const warningOn = data.length < 3 ? true : false;
  const { t } = props;

  return data ? (
    <div>
      <NewsCategoryTitle
        props={props}
        isFixedLayout={false}
        titleColor={titleColor}
        name={name}
        analyticsInfo={analyticsInfo}
        logo={logo}
      />
      {isPreview && warningOn &&
        <div class="alert alert-warning pl-3">
          <strong>{t("errors.warning")}</strong> {t("errors.insuficientContent")}
        </div>
      }
      {data.length > 0 ?
        <div className="row pl-3">
          {data.slice(0, 2).map((card, index) => (
            <div key={index} className="col-12 col-md-3 pl-0">
              <NewsCard
                id={card?.id}
                type="card"
                icon={card?.fields?.has_video ? "video_icon" : "link_icon"}
                cardSize="card_news one_col_tall_news"
                cardinfo={card}
                image={card?.fields?.cover_image?.directURLArray.medium}
                imageClass={card?.fields?.image_alignment}
                createdDate={
                  card?.fields?.authoring_date?.value
                }
                summary={card?.fields?.title}
                tagList={card?.fields?.tags_list}
                analyticsInfo={analyticsInfo}
              />
            </div>
          ))}
          <div className="col-12 col-md-6 pl-0">
            <NewsCard
              id={data[2]?.id}
              type="card"
              icon={data[2]?.fields?.has_video ? "video_icon" : "link_icon"}
              cardSize="card_news square big_news"
              cardinfo={data[2]}
              image={data[2]?.fields?.cover_image?.directURLArray.medium}
              imageClass={data[2]?.fields?.image_alignment}
              createdDate={
                data[2]?.fields?.authoring_date?.value
              }
              summary={data[2]?.fields?.title}
              tagList={data[2]?.fields?.tags_list}
              analyticsInfo={analyticsInfo}
            />
          </div>
        </div>
        : null}
    </div>
  ) : null;
};

export default withTranslation()(Layout5);
