import React from "react";
import "./App.css";
import Routes from "./routes";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import i18n from "./i18n";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";



import store from "./store";

const App = (props) => {
  
  return (
    <div className="App">
      <ReactNotification />
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <Routes />
          
        </Provider>
      </I18nextProvider>
    </div>
  );
};

export default App;
