import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';

import NewsCard from '../../components/News/NewsCard';
import { fetchNewsByTag } from '../../store/actions/news';


const RelatedNews = (props) => {

  const { t } = props;

  const locale = useSelector(state => state.info.locale)

  const [newsByTag, setNewsByTag] = useState(null);

  const tagList = props.tags;
  const newsId = props.newsId;

  useEffect(() => {

    async function fetchTagNewsData(tags) {

      await fetchNewsByTag(tags, 3, locale)
        .then(news => {
          setNewsByTag(news.items);
        })
        .catch(error => {
          console.log(error);
        })
    }

    fetchTagNewsData(tagList)

  }, [tagList])


  let [summary, setSummary] = useState(newsByTag && newsByTag[0].fields && newsByTag[0].fields.summary ? newsByTag[0].fields.summary : null);

  return (
    <div>
      <div class="row mt-5">
        {newsByTag && newsByTag.length != 1 ?
          <div class="col-12 pl-3">
            <h2 class="related">{t('newsDetailPage.featured')}</h2>
          </div>
          : null}
      </div>
      <div class="row">
        {newsByTag && newsByTag.slice(0, 3).map((element, index) => (
          <div class="col-12 mb-3">
            {element.id !== newsId ?
              <NewsCard
                id={element?.id}
                key={index}
                loadingHighlights={false}
                type="card"
                icon={element?.fields?.has_video ? "video_icon" : "link_icon"}
                cardSize="card_news three_row_news"
                cardinfo={element}
                image={element?.fields?.cover_image?.directURL}
                imageClass={element?.fields?.image_alignment}
                createdDate={
                  element?.fields?.authoring_date?.value
                }
                summary={element?.fields?.title}
                tagList={element?.fields?.tags_list}
                analyticsInfo={props.analyticsInfo}
              />
              : null}
          </div>
        ))}
      </div>
    </div>

  )

}
export default withTranslation()(RelatedNews);