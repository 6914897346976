import React from 'react';
import { withTranslation } from 'react-i18next';

const PodcastDetailCard = (props) => {

    const { t } = props;

    return (
        <div>
            <div className="row mt-5">
                <div className="col-12 col-md-6">
                    <div className={props.type == "Video-Podcast" ? "card_news three_row_news program" : "card_news square program"}>
                        <a href="#" title="">
                            <img className={props.imageClass} src={props.image} alt="Podcast cover" />
                        </a>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    {props.title &&
                        <h2>{props.title}</h2>}
                    {props.description &&
                        <p>{props.description}</p>}
                    <dl className="inline_dl">
                        {props.type == "Video-Podcast" ? <dt>{t('podcasts.video')}</dt> : <dt>{t('podcasts.audio')}</dt>}
                        <dd>{props.numberOfEpisodes} {props.numberOfEpisodes > 1 || props.numberOfEpisodes == 0 ? t('podcasts.episodesWithE') : t('podcasts.oneEpisode')}</dd>
                    </dl>
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(PodcastDetailCard);

