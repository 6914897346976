import React, { useEffect, Fragment } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import SearchListPage from "./pages/SearchListPage";
import NewsDetailPage from "./pages/NewsDetailPage";
import Home from "./pages/HomePage";
import CategoryListPage from "./pages/CategoryListPage";
import ProgramListPage from "./pages/ProgramListPage";
import ProgramDetailPage from "./pages/ProgramDetailPage";
import NewsTickerListPage from "./pages/NewsTickerListPage";
import VideoLibraryListPage from "./pages/VideoLibraryListPage";
import VideoLandingPage from "./pages/VideoLandingPage";
import MostReadNewsListPage from "./pages/MostReadNewsListPage";
import VideoPodcastDetailPage from "./pages/VideoPodcastDetailPage";
import AudioPodcastDetailPage from "./pages/AudioPodcastDetailPage";
import PodcastListPage from "./pages/PodcastListPage";

import HelpPage from "./pages/HelpPage";
import AboutPage from "./pages/AboutPage";
import NotFound from "./pages/NotFoundPage";
import PrivacyPage from "./pages/PrivacyPage";

import Streaming from "./components/Utils/Streaming";

import ScrollToTop from "./store/actions/ScrollToTop";
import { setPreview } from "./store/actions/info";
import { fetchAllCategoriesThunk } from "./store/actions/news";
import { fetchAllProgramsThunk } from "./store/actions/programs";
import CookieConsent from "./components/Utils/CookieConsent"
import sendAnalyticsEvent from "./scripts/analyticsEvent";
import { setCookieConsent } from "./store/actions/info";



const Routes = () => {

  const locale = useSelector((state) => state.info.locale);
  const cookieConsent = useSelector((state) => state.info.cookieConsent.consent);
  const dispatch = useDispatch();

  useEffect(() => {

    let homepageContentId = "";

    if (window.location.href.includes("homepagePreview")) {
      homepageContentId = window.location.href.split("/").slice(-1)[0];
      dispatch(setPreview(true));
      dispatch(fetchAllCategoriesThunk(locale, true, homepageContentId));
    } else {
      dispatch(setPreview(false));
      dispatch(fetchAllCategoriesThunk(locale, false, 0));
    }

    dispatch(fetchAllProgramsThunk(locale, true));

  }, [dispatch, locale, window.location.href]);

  const dispatchSetCookieConsent = () => {
    let cookieConsentLS = localStorage.getItem("cookieConsent") ? localStorage.getItem("cookieConsent").consent : undefined;
    console.log("cookie:" + cookieConsentLS)
    dispatch(setCookieConsent(cookieConsentLS));
  }

  useEffect(() => {
    window.onbeforeunload = (event) => {
      sendAnalyticsEvent({type:"onbeforeunload", cookieConsent: cookieConsent});
      sessionStorage.removeItem("eventsArray")
      return
    }
    
    window.addEventListener("storage", dispatchSetCookieConsent);
    return () => {
      window.removeEventListener("onbeforeunload");
      window.removeEventListener("storage", dispatchSetCookieConsent);
    }
  }, [])

  

  return (
    <>
      <Router>
        <Fragment>
          <ScrollToTop />
          <Header />
          {cookieConsent === undefined && <CookieConsent/>}
          <Switch>
            <Route exact path={[
                `/`,
                `/en`
              ]}>
              <Redirect to="/ar" />
            </Route>
            <Route
              exact
              path={[
                `/en`,
                `/ar`,
                `/homepagePreview/:homepageContentId`
              ]}
              component={Home}
            />
            <Route
              exact
              path={[
                `/en/ekhsearch`,
                `/ar/ekhsearch`
              ]}
              component={SearchListPage}
            />
            <Route
              exact
              path={[
                `/en/category/:categoryName`,
                `/ar/category/:categoryName`,
                `/en/category/:categoryName?q=`,
                `/ar/category/:categoryName?q=`
              ]}
              component={CategoryListPage}
            />
            <Route
              exact
              path={[
                `/en/news/:newsId`,
                `/ar/news/:newsId`
              ]}
              component={NewsDetailPage}
            />
            <Route
              exact
              path={[
                `/en/podcasts`,
                `/ar/podcasts`
              ]}
              component={PodcastListPage}
            />
            <Route
              exact
              path={[
                `/en/podcasts/Video-Podcast/:podcastID`,
                `/ar/podcasts/Video-Podcast/:podcastID`
              ]}
              component={VideoPodcastDetailPage}
            />
            <Route
              exact
              path={[
                `/en/podcasts/Audio-Podcast/:podcastID`,
                `/ar/podcasts/Audio-Podcast/:podcastID`
              ]}
              component={AudioPodcastDetailPage}
            />
            <Route
              exact
              path={[
                `/en/programs`,
                `/ar/programs`]}
              component={ProgramListPage}
            />
            <Route
              exact
              path={[
                `/en/programs/:programName`,
                `/ar/programs/:programName`
              ]}
              component={ProgramDetailPage}
            />
            <Route
              exact
              path={[
                `/en/streaming`,
                `/ar/streaming`
              ]}
              component={Streaming}
            />
            <Route
              exact
              path={[
                `/en/newsticker`,
                `/ar/newsticker`
              ]}
              component={NewsTickerListPage}
            />
            <Route
              exact
              path={[
                `/en/MostReadNews`,
                `/ar/MostReadNews`
              ]}
              component={MostReadNewsListPage}
            />
            <Route
              exact
              path={[
                `/en/Video-Library`,
                `/ar/Video-Library`
              ]}
              component={VideoLibraryListPage}
            />
            <Route
              exact
              path={[
                `/en/Video-Library/:videoId`,
                `/ar/Video-Library/:videoId`,
                `/en/Video-Podcast/Episode/:videoId`,
                `/ar/Video-Podcast/Episode/:videoId`,
                `/en/programVideo/:videoId`,
                `/ar/programVideo/:videoId`
              ]}
              component={VideoLandingPage}
            />
            <Route
              exact
              path={[
                `/en/Help`,
                `/ar/Help`
              ]}
              component={HelpPage}
            />
            <Route
              exact
              path={[
                `/streaming`
              ]}
              component={Streaming}
            />
            {/* <Route
              exact
              path={[
                `/en/About`,
                `/ar/About`
              ]}
              component={AboutPage}
            /> */}
            <Route
              exact
              path={[
                `/en/privacy`,
                `/ar/privacy`
              ]}
              component={PrivacyPage}
            />
            <Route component={NotFound} />
          </Switch>
          <Footer />
        </Fragment>
      </Router>
    </>
  );
};
//export default withRouter(connect(mapStateToProps, mapDispachToProps)(Main));

export default Routes;
