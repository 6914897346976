const colorToCSSClassMapper = {
  "#007CE6": "cat_1",
  "#D30800": "cat_2",
  "#AC9F0A": "cat_3",
  "#A83A3A": "cat_4",
  "#739D4F": "cat_5",
  "#00C8D0": "cat_6",
  "#747474": "cat_7",
  "#E66A00": "cat_8",
  "#3D9598": "cat_9",
  "#873D98": "cat_10",
  "#001627": "cat_11",
};

export default colorToCSSClassMapper;
