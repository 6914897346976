import env from '../../config/env';
import axios from 'axios';

export function fetchVideos(locale, limit = "", offset, input, isHighlighted, token, startDate, endDate) {
  return new Promise((resolve, reject) => {
    axios({
      url: `${env.serverUrl}/ekh-rest-api/video_library/search?contentLanguage=${locale}&offset=${offset}${startDate? "&startDate="+startDate : ""}${endDate? "&endDate="+endDate : ""}&resultLimit=${limit ? limit : 100}${input !== "" ? `&summary=${input}&title=${input}` : ""}${isHighlighted !== null ? `&isHighlight=${isHighlighted}`: ""}`,
      method: "GET",

      headers: {
        "Content-Type": "application/json"
      },
      cancelToken: token
    })
      .then((response) => {
        resolve(response.data.items.length === 0 ? null : response.data);
      })
      .catch((error) => {
        console.log(error);
        reject();
      });
  });
}

export function fetchLatestVideos(locale, limit) {
  return new Promise((resolve, reject) => {
    axios({
      url: `${env.serverUrl}/ekh-rest-api/video_library/searchLatest?contentLanguage=${locale}&resultLimit=${limit}`,
      method: "GET",

      headers: {
        "Content-Type": "application/json"
      },
    })
      .then((response) => {
        resolve(response.data.items.length === 0 ? null : response.data);
      })
      .catch((error) => {
        console.log(error);
        reject();
      });
  });
}


export function fetchVideoLibraryById(locale, id) {
  return new Promise((resolve, reject) => {
    axios({
      url: `${env.serverUrl}/ekh-rest-api/video_library/${id}?contentLanguage=${locale}`,
      method: "GET",

      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject();
      });
  });
}