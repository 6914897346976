import env from "../config/env.js";
import axios from "axios";
import {guid, getBrowserName, getDeviceType, getSourceMedium} from "../scripts/utils";

const sendEvent = async (props, country) => {
  let eventTime = new Date().toJSON();

    // const firstVisit = localStorage.getItem("visitInfo")
    //         ? JSON.parse(localStorage.getItem("visitInfo"))
    //         : null;
    
    //       let visitInfo = {}
    //       if(!firstVisit){
    //         let uid = guid();
    //         visitInfo.id = guid();
            
    //         localStorage.setItem("visitInfo", JSON.stringify(visitInfo));
    //       }
    
          let sessionInfoSS = sessionStorage.getItem("sessionInfo")
            ? JSON.parse(sessionStorage.getItem("sessionInfo"))
            : null;
    
            let sessionInfo = {}
            if(!sessionInfoSS){
              sessionInfo.referrerType = document.referrer;
              sessionInfo.referrer = document.referrer;
              // sessionInfo.sessionStart = eventTime;
              sessionInfo.userAgent = navigator.userAgent
              sessionInfo.origin = getSourceMedium(document.referrer);
              // sessionInfo.id = guid();
              sessionInfo.landingPage = props.page;
              // sessionInfo.firstVisit = !firstVisit+"";
              sessionInfo.country = country || "";
              sessionStorage.setItem("sessionInfo", JSON.stringify(sessionInfo));
            }
            
            
    
          let info = {};
    
          let eventInfo = {
            type: props.type || "",
            pagevisited: props.page || "",
            value: props.value || "",
            section:props.section || "",
            label:props.label || "",
            eventcategory:props.category || "",
          }
          
          // info.sessionStart = sessionInfoSS?.sessionStart || sessionInfo.sessionStart;
          // info.sessionId = sessionInfoSS?.id || sessionInfo.id;
          info.timestamp = eventTime;
          info.visitedPageSection = window.location.href
          // info.userId = firstVisit?.id || visitInfo.id
          info.country =  sessionInfoSS?.country || sessionInfo.country;
          info.origin = sessionInfoSS?.origin || sessionInfo.origin;
          info.source = "EKH Portal"
          info.referrer = document.referrer;
          info.language = window.navigator.language;
          info.landingPage = sessionInfoSS?.landingPage || sessionInfo.landingPage
          info.browserType = getBrowserName();
          // info.firstVisit = sessionInfoSS?.firstVisit || sessionInfo.firstVisit;
          info.deviceType = getDeviceType();
          info.eventInfo = eventInfo;
    
          const config = {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json"
            }
          };
    
          
          sessionStorage.setItem("sessionLastEvent", JSON.stringify(eventTime));
          let eventsArray = sessionStorage.getItem("eventsArray")
            ? JSON.parse(sessionStorage.getItem("eventsArray"))
            : [];
            eventsArray.push(info);

          if(props.cookieConsent){
            const data = await axios.post(
              encodeURI(
              `${env.serverUrlAnalytics}/analytics/event?contentLanguage=${props.locale? props.locale : "arSA"}`)
              ,
              eventsArray,
              config
            );
            sessionStorage.removeItem("eventsArray")
          }
          else{
            
            sessionStorage.setItem("eventsArray", JSON.stringify(eventsArray));
          }
}

 const sendAnalyticsEvent = async (props) => {
  if (!navigator.geolocation) {
    sendEvent(props)
  } else {
    let sessionInfoSS = JSON.parse(sessionStorage.getItem("sessionInfo"));
    if(!sessionInfoSS || !sessionInfoSS.country){
      navigator.geolocation.getCurrentPosition(async (res) =>{
        axios.get(
          `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${res.coords.latitude}&longitude=${res.coords.longitude}&localityLanguage=en`
          ,
          {responseType: 'stream'}
        ).then(data => {
          sendEvent(props, data.data.countryName)
        }); 
      }, 
      error => {
        console.log("error", error)        
        sendEvent(props)
      });
    }
    else{
      sendEvent(props)
    }
    
    
    
  }
  
          
  };




  export const sendcookieConsent = async (props) =>{
    const config = {
      withCredentials: true,
      // credentials: "include",
      headers: {
        
        "Content-Type": "application/json",
        
        keepalive: true,
        // credentials: "include"
        // credentials: "same-origin"
        
      }
    };

      let eventsArray = sessionStorage.getItem("eventsArray")
      ? JSON.parse(sessionStorage.getItem("eventsArray"))
      : [];
      if(eventsArray.length > 0){
      const data = await axios.post(
        encodeURI(
        `${env.serverUrlAnalytics}/analytics/event?type=entered&contentLanguage=${props?.locale ? props.locale : "enUS"}`)
        ,
        eventsArray,
        config
      );
      sessionStorage.removeItem("eventsArray")
    }
  }

  export default sendAnalyticsEvent;