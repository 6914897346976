import active from './active.env';

const envs = {
  'smm-ekh-prd': {
    serverUrl: "https://api-prd.alekhbariya.net/ekh",
    serverUrlAnalytics: 'https://api-prd.alekhbariya.net/smm-analytics',
    clientSide: 'https://www.alekhbariya.net',
    googleID: 'G-TBGBMXVCTD',
    showPrograms: false,
    partnerId: "4329372",
    uiConfId: "47425753"
  },
  'smm-ekh-tst': {
    serverUrl: 'https://api-tst.alekhbariya.net/ekh',
    serverUrlAnalytics: 'https://api-tst.alekhbariya.net/smm-analytics',
    clientSide: 'https://www-test.alekhbariya.net',
    googleID: 'G-SY969J8ZHK',
    showPrograms: true,
    partnerId: "4172723",
    uiConfId: "47425753"
  },
  'smm-ekh-dev': {
    serverUrl: 'https://api-dev.alekhbariya.net/ekh',
    serverUrlAnalytics: 'https://api-dev.alekhbariya.net/smm-analytics',
    clientSide: 'https://www-dev.alekhbariya.net',
    googleID: 'G-TBGBMXVCTD',
    showPrograms: true,
    partnerId: "3296113",
    uiConfId: "47425753"
  },
  'smm-ekh-link': {
    serverUrl: 'http://140.238.86.84:9073',
    clientSide: 'http://132.145.50.69',
    googleID: 'G-TBGBMXVCTD',
    showPrograms: true
  },
  'smm-ekh-local': {
    serverUrl: 'http://localhost:8080',
    clientSide: 'https://localhost:3000',
    showPrograms: true,
    partnerId: "3296113",
    uiConfId: "47425753"
  },
  'smm-ekh-local-analytics': {
    serverUrl: 'https://api-dev.alekhbariya.net/ekh',
    serverUrlAnalytics: 'http://localhost:8080/smm-analytics',
    clientSide: 'https://localhost:3000',
    showPrograms: true,
    partnerId: "3296113",
    uiConfId: "47425753"
  },
};

export default envs[active];
