import React from 'react';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from 'react-i18next';


const ProgramsCard = ({ name, image, props }) => {

	const dispatch = useDispatch();
	const locale = useSelector(state => state.info.locale);
	const { t } = props;

	return (

		<Link className="col-12 col-md-3 pl-0"
			onClick={() =>
				dispatch({
					type: "CHANGED_PATH",
					path: `/${locale === "arSA" ? "ar" : "en"}/programs/${name}`
				})
			}
			to={{
				pathname: `/${locale === "arSA" ? "ar" : "en"}/programs/${name}`
			}}
		>

			<div className="card_news three_row_news program">
				<dl class="link_icon" style={{ color: "white" }}>
					<dt>{name}</dt>
				</dl>
				<a href="#" title="">
					<img src={image} alt={t('programs.program')} />
				</a>
			</div>

		</Link>

	)

}


export default withTranslation()(ProgramsCard);
