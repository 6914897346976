import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { withTranslation } from 'react-i18next';

import ShareMetaData from '../components/Utils/SocialMediaShare/ShareMetaData';
import PodcastCard from '../components/Podcasts/PodcastCard'
import { fetchAudioPodcasts, fetchVideoPodcasts } from "../store/actions/podcast"
import sendAnalyticsEvent from "../scripts/analyticsEvent";



const PodcastListPage = (props) => {

    const { t } = props;

    const locale = useSelector(state => state.info.locale);
    const cookieConsent = useSelector((state) => state.info.cookieConsent.consent);

    const [videoPodcasts, setVideoPodcasts] = useState(null)
    const [audioPodcasts, setAudioPodcasts] = useState(null)
    const [loading, setLoading] = useState(false);
    const [query, setQuery] = useState("");

    useEffect(() => {
        async function fetchVideoPodcastData() {
            setLoading(true)
            await fetchVideoPodcasts(query, locale, "15")
                .then(podcastList => {
                    setLoading(false)
                    setVideoPodcasts(podcastList.items);
                })
                .catch(error => {
                    console.log(error);
                })
        }
        async function fetchAudioPodcastData() {
            setLoading(true)
            await fetchAudioPodcasts(query, locale, "15")
                .then(podcastList => {
                    setLoading(false)
                    setAudioPodcasts(podcastList.items);
                })
                .catch(error => {
                    console.log(error);
                })
        }


        fetchAudioPodcastData()
        fetchVideoPodcastData()
    }, [query, locale])

    useEffect(() => {
        sendAnalyticsEvent({type:"viewed",page:"podcast list", locale: locale, cookieConsent: cookieConsent});

    }, )

    return (<div>
        <ShareMetaData title={t('podcasts.podcasts')}></ShareMetaData>
        <div className="container-fluid featured light_theme">
            <div className="row mb-5">
                <div className="col-12 col-lg-6 d-flex justify-content-start align-items-end">
                    <h1 className="align-self-end no_cat">{t('podcasts.podcasts')}</h1>
                </div>
                <div className="col-12 col-lg-6 d-flex justify-content-end align-items-end pl-3 pl-lg-0 mt-5 mt-lg-0">
                    <form className="body_search w-100">
                        <input value={query} onChange={(event) => setQuery(event.target.value)} className="body_search_input" type="search" placeholder={t('podcasts.searchPodcasts')}
                            aria-label="Search" />
                        <button onClick={(event) => event.preventDefault()} className="search_button" type="submit"><i className="fal fa-search"></i></button>
                    </form>
                </div>
            </div>
            <div className="row mt-4 mb-4">
                <div className="col-12 d-flex justify-content-start mt-3">
                    <h2 className="align-self-end cat_11">{t('podcasts.audioPodcasts')}</h2>
                </div>
            </div>
            <div className="row pl-3">
                {!loading && audioPodcasts && audioPodcasts.map((card, index) => (
                    <PodcastCard props={props}
                        key={index}
                        image={card?.fields?.cover_image?.directURL}
                        imageClass={card?.fields?.image_alignment}
                        id={card?.id}
                        name={card?.fields?.title}
                        type={card?.type}
                        count={card?.episodeCount}
                    />
                ))}
            </div>
            <div className="row mt-0 mb-4">
                <div className="col-12 d-flex justify-content-start mt-3">
                    <h2 className="align-self-end cat_11">{t('podcasts.videoPodcasts')}</h2>
                </div>
            </div>
            <div className="row pl-3">
                {!loading && videoPodcasts && videoPodcasts.map((card, index) => (
                    <PodcastCard props={props}
                        key={index}
                        image={card?.fields?.cover_image?.directURL}
                        imageClass={card?.fields?.image_alignment}
                        id={card?.id}
                        name={card?.fields?.title}
                        type={card?.type}
                        count={card?.episodeCount}
                    />
                ))}
            </div>
        </div>
    </div>)


}
export default withTranslation()(PodcastListPage);