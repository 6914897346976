import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import PodcastDetailCard from "../components/Podcasts/PodcastDetailCard";
import Share from "../components/Utils/SocialMediaShare/Share";

import { fetchEpisodeSearch, fetchPodcastsByID } from "../store/actions/podcast";
import { convertDate } from "../scripts/utils";


const AudioPodcastDetailPage = (props) => {

    const { t } = props

    const { podcastID } = useParams()
    const { podcastType } = useParams()

    const history = useHistory();

    const locale = useSelector(state => state.info.locale);

    const [podcast, setPodcast] = useState(null)
    const [episodeSearch, setEpisodeSearch] = useState(null)
    const [latestEpisode, setLatestEpisode] = useState(null)
    const [searchLoading, setSearchLoading] = useState(false);
    const [input, setInput] = useState(null)
    const [loading, setLoading] = useState(false);
    const [firstPlay, setFirstPlay] = useState(true)

    const [play, setPlay] = useState(false);
    const [song, setSong] = useState();
    const [disabled, setDisabled] = useState(-1);

    const audioRef = useRef();

    const [id, setId] = useState();
    const [title, setTitle] = useState();
    const [summary, setSummary] = useState();
    const [numberEpisodes, setNumberEpisodes] = useState(null)


    const playSong = (song, id, title, summary) => {

        setSong(song);
        setPlay(true);
        setId(id);
        setTitle(title);
        setSummary(summary);
        audioRef.current.audio.current.play();
        if (firstPlay) { setFirstPlay(false) }
        console.log(play);
        console.log(song);
    };

    console.log("first play", firstPlay)
    console.log("audio", audioRef)

    const pauseSong = (song, id, title, summary) => {
        setSong(song);
        setPlay(false);
        setId(id);
        setTitle(title);
        setSummary(summary)
        audioRef.current.audio.current.pause()
        console.log(play);
        console.log(song);
    };

    const changeMusicNext = () => {
        setDisabled(disabled > 0 ? disabled - 1 : disabled)
        setSong(episodeSearch && episodeSearch[disabled - 1]?.fields?.episode?.directURL);
        audioRef.current.audio.current.play();
        console.log("next", disabled);
        console.log("nextSong", episodeSearch[disabled]?.fields?.episode?.directURL);

    };

    const changeMusicPrev = () => {
        setDisabled(episodeSearch && episodeSearch.length - 1 === disabled ? disabled : disabled + 1);
        setSong(episodeSearch && episodeSearch[disabled + 1]?.fields?.episode?.directURL);
        audioRef.current.audio.current.play();
        console.log("prev", disabled);
        console.log("prevSong", episodeSearch[disabled]?.fields?.episode?.directURL);

    };


    //backend url takes video_podcast instead of video-podcast like front end
    let urlType
    podcastType == "Video-Podcast" ? urlType = "video_podcast" : urlType = "audio_podcast"

    /*useEffect (() => {
        {play ? audioRef.current.audio.current.play() : audioRef.current.audio.current.pause()}
      }
    ,[play])*/

    //Fetch audio and video podcast detail
    useEffect(() => {
        async function fetchPodcastData() {
            setLoading(true)
            await fetchPodcastsByID(urlType, podcastID, locale)
                .then(data => {
                    setLoading(false)
                    setPodcast(data.items);
                })
                .catch(error => {
                    console.log(error);
                })
        }
        fetchPodcastData()

    }, [locale])

    useEffect(() => {
        setPodcast(null);
        setInput('');
    }, [locale])

    //Search
    const handleInput = (event) => {
        event.preventDefault();
        setInput(event.target.value);
    };

    useEffect(() => {
        applySearch(true);
    }, [locale]);

    useEffect(() => {
        applySearch(false);
    }, [input]);

    const applySearch = async (firstTimeFlag) => {
        setSearchLoading(true);
        setEpisodeSearch(null);
        await fetchEpisodeSearch(input, urlType, locale, podcastID)
            .then((searchList) => {
                setSearchLoading(false);
                setEpisodeSearch(searchList.items);
                if (firstTimeFlag) { setNumberEpisodes(searchList.items.length); setLatestEpisode(searchList.items[0]) };
                setSong(searchList.items[0].fields?.episode?.directURL);
                setId(searchList.items[0].fields?.audio?.id);
                setTitle(searchList.items[0].fields?.title);
                setSummary(searchList.items[0].description);
                audioRef.current.audio.current.pause()
            })
            .catch((error) => {
                console.log(error);
            });
    };

    console.log("id", id)

    function useIsMount() {
        const isMountRef = useRef(locale);
        useEffect(() => {
            isMountRef.current = locale;
        }, []);
        return isMountRef.current;
    };

    const isMount = useIsMount();

    useEffect(() => {
        if (isMount === locale) {
            console.log('First Render');
        } else {
            history.push(`/podcasts`);
            console.log('Subsequent Render');

        }
    });

    return (
        <div>
            <div className="container-fluid m-0 p-0 text-dark">
                <div className="hero podcast">
                    <div className="row h-100 mr-0 ml-0">
                        <div className="col-12 col-lg-6 h-100 p-0 live_now pl-4 pr-4">
                            {!loading && podcast &&
                                <PodcastDetailCard props={props} image={podcast[0]?.fields?.cover_image?.directURL}
                                    imageClass={podcast[0]?.fields?.image_alignment}
                                    title={podcast[0]?.fields?.title}
                                    description={podcast[0]?.fields?.summary}
                                    type={"Audio-Podcast"}
                                    numberOfEpisodes={numberEpisodes} />}
                            <div className="row mt-5">
                                {!loading && latestEpisode &&
                                    <div className="col-12">
                                        <h3 className="text-uppercase"> {t('podcasts.lastEpisode')}</h3>
                                        {latestEpisode?.fields?.title &&
                                            <h2 className="episode">{latestEpisode.fields?.title}</h2>}
                                        <a href="#" key={0} onClick={() => { play ? pauseSong(latestEpisode?.fields?.episode?.directURL, latestEpisode?.fields?.audio?.id, latestEpisode?.fields?.title, latestEpisode?.description, latestEpisode?.fields?.cover_image?.directURL) : playSong(latestEpisode?.fields?.episode?.directURL, latestEpisode?.fields?.audio?.id, latestEpisode?.fields?.title, latestEpisode?.description, latestEpisode?.fields?.cover_image?.directURL); setDisabled(0) }} className="align-self-center stretched-link ml-auto">
                                            <div key={0} className={disabled === 0 ? (play ? "audio_pause big_icon" : "audio_icon big_icon") : "audio_icon big_icon"}>
                                            </div>
                                        </a>
                                        <div className="clearfix">
                                        </div>
                                        {latestEpisode?.fields?.summary &&
                                            <p>{latestEpisode?.fields?.summary}</p>}
                                    </div>}
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 epg podcast p-0 h-100">
                            <div className="title w-100 d-flex pt-3 pb-3 pr-3 align-items-center">
                                <h4>{t('podcasts.episodes')}</h4>
                                <form className="body_search w-50 ml-auto">
                                    <input value={input}
                                        onChange={(e) =>
                                            handleInput(e)} className="body_search_input" type="search" placeholder={t('podcasts.searchEpisodes')}
                                        aria-label="Search" />
                                    <button className="search_button" type="submit"><i
                                        className="fal fa-search"></i></button>
                                </form>
                            </div>
                            <div className="epg_wrapper detail">
                                {!searchLoading && episodeSearch && episodeSearch.map((card, index) => (
                                    <div key={index} className={disabled === index ? "epg_card podcast_card active d-flex align-items-center" : "epg_card podcast_card d-flex align-items-center"}>
                                        <dl className="time">
                                            <dt>{convertDate(card?.fields?.authoring_date?.value.substring(0, 10))}</dt>
                                            {card?.fields?.duration &&
                                                <dd>{card?.fields?.duration + " " + t("programs.minutes")}</dd>}
                                        </dl>
                                        <dl className="info">
                                            {card?.fields?.title &&
                                                <dt>{card?.fields?.title}</dt>}
                                            {card.fields.summary &&
                                                <dd>{card.fields.summary}</dd>}

                                        </dl>

                                        <a href="#" key={index} onClick={() => { play ? pauseSong(card?.fields?.episode?.directURL, card?.fields?.audio?.id, card?.fields?.title, card?.description, card?.fields?.cover_image?.directURL) : playSong(card?.fields?.episode?.directURL, card?.fields?.audio?.id, card?.fields?.title, card?.description, card?.fields?.cover_image?.directURL); setDisabled(index) }} className="align-self-center stretched-link ml-auto">
                                            <div key={index} className={disabled === index ? (play ? "audio_pause" : "audio_icon") : "audio_icon"}>
                                            </div>
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>


                <div class="audio-container" style={{ display: firstPlay ? "none" : "flex" }}>


                    <ul className="social_share my-2 justify-content-start text-dark d-flex">
                        <div className="podcast_thumbnail">
                            <a href="#" title="">
                                <img width="50" height="50" src={podcast && podcast[0]?.fields?.cover_image?.directURL} alt="Podcast cover" />
                            </a>
                        </div>
                        <div className="podcast_title">
                            {title &&
                                <h6 className={"font-weight-bold mr-5"}>{title}</h6>}
                        </div>
                        <div className="share_icons">
                            <Share Id={id} Title={title} Summary={summary} image={podcast && podcast[0]?.fields?.cover_image?.directURL} type={"Audio-Podcast"} />
                        </div>


                    </ul>



                    <AudioPlayer style={{ width: "55%", boxShadow: "none" }}
                        ref={audioRef}
                        src={song} //aqui meter o song
                        onPlay={() => setPlay(true)}
                        onPause={() => setPlay(false)}
                        showSkipControls={true}
                        customAdditionalControls={[]}
                        onClickPrevious={changeMusicNext}
                        onClickNext={changeMusicPrev}
                    // other props here
                    //autoPlay = {play}
                    />
                </div>
            </div>
        </div>

    )

}
export default withTranslation()(AudioPodcastDetailPage);