import React, { useState, useEffect, useCallback} from 'react';
import { useSelector } from "react-redux";
import { withTranslation } from 'react-i18next';

import ListAllPrograms from '../components/Programs/ListAllPrograms';
import ListAllProgramEpisodes from '../components/Programs/ListAllProgramEpisodes';
import ShareMetaData from '../components/Utils/SocialMediaShare/ShareMetaData';
import { fetchEPG } from '../store/actions/programs';
import SpinnerLoader from '../components/Utils/Loaders/SpinnerLoader';
import { Modal } from 'react-responsive-modal';
import ReactPlayer from "react-player";
import EmptyResultsPage from './EmptyResultsPage';
import sendAnalyticsEvent from "../scripts/analyticsEvent";


const ProgramListPage = (props) => {

	/**
	 * TODO:
	 * ver qual foi o ultimo programa e alterar os campos
	 * faltam labels
	 */

	const { t } = props;

	const cookieConsent = useSelector((state) => state.info.cookieConsent.consent);


	const programs = useSelector((state) => state.programs.programsList);
	const loading = useSelector((state) => state.programs.loading);
	const [epg, setEPG] = useState([]);

	const [pastEpisodes, setPastEpisodes] = useState([]);
	const [liveEpisodes, setLiveEpisodes] = useState([]);
	const [futureEpisodes, setFutureEpisodes] = useState([]);
	const [episodeLoading, setEpisodeLoading] = useState(false);
	const [error, setError] = useState(false);
	const [isClicked, setIsClicked] = useState(false);

	const [open, setOpen] = useState(false);
	const [play, setPlay] = useState(false);
	const [windowSize, setWindowSize] = useState(window.innerWidth);

	useEffect(() => {

		setEpisodeLoading(true);

		async function fetchEPGData() {
			await fetchEPG()
				.then(data => {
					Object.keys(data).map((function (s) {
						if (data[s].isLive === "PAST") {
							setPastEpisodes(pastEpisodes => [...pastEpisodes, data[s]])
						}
						else if (data[s].isLive === "LIVE") { setLiveEpisodes(liveEpisodes => [...liveEpisodes, data[s]]) }
						else setFutureEpisodes(futureEpisodes => [...futureEpisodes, data[s]])
					}))
					setError(false);
				})
				.catch(error => {
					console.log(error);
				})
			setEpisodeLoading(false);
		}
		fetchEPGData()

	}, [])

	const handleWindowResize = useCallback(event => {

		setWindowSize(window.innerWidth);

	}, []);


	useEffect(() => {
		sendAnalyticsEvent({type:"viewed",page:"program list", cookieConsent: cookieConsent});

	}, [])

	useEffect(() => {
	  window.addEventListener('resize', handleWindowResize);

	  return () => {
		window.removeEventListener('resize', handleWindowResize);
	  };
	}, [handleWindowResize]);

	const onOpenModal = () => {
		setOpen(true);
		setPlay(true);
	};

	const onCloseModal = () => {
		setOpen(false);
		setPlay(false);
	};

	useEffect(() => {
	}, [isClicked])



	return (

		<div>
			<ShareMetaData title={"Programs"}></ShareMetaData>

			<div class="container-fluid m-0 p-0">
				<div class="hero program">
					<div class="row h-100 mr-0 ml-0">
						<div class="col-12 col-lg-6 h-100 p-0 live_now">
							<div class="hero_50 d-flex">
								<div class="title">
									<h1>{t("header.programs")}</h1>
								</div>
								<div  class={`w-100 d-lg-flex justify-content-lg-between align-items-end hero_program`}>
									<div class="w-60">
										<h3>{t("programs.liveNow")}</h3>
										<div class="w-100 d-lg-flex align-items-end">
											<h2 class=" float-left">{liveEpisodes.length > 0 && liveEpisodes[0].programName}</h2>
										</div>
									</div>
									{!open && <div class="live_button d-flex">
										<a href="#" onClick={onOpenModal} class="align-self-center stretched-link">{windowSize >= 991 ? t("programs.watchLive") : t("home.live")}</a>
									</div>}
									<>
            {open == true ?
              <Modal open={open} onClose={onCloseModal}
                showCloseIcon={false} closeOnOverlayClick={true}
                styles={{
                  content: {
                    overflow: "visible",
                  },
                  modal: {
                    maxWidth: "unset",
                    width: "80%",
                    padding: "unset",
                    overflow: "visible",
                    background: "transparent",
                  },
                  overlay: {
                    background: "rgba(0 0 0 / 97%)",

                  },
                }}
                center>
                <button onClick={onCloseModal} className="close">
                </button>
                <ReactPlayer
                  url='https://youtu.be/HofpNu6lDSU' height="100%"
                  controls={true}
                  autoplay={true}
                  playing={true}
                  width="100%"
                />

              </Modal> : null}
          </>
								</div>
								<img src="https://images.unsplash.com/photo-1612461313144-fc1676a1bf17?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1291&q=80" alt="news image" />
							</div>
						</div>
						<div class="col-12 col-md-6 epg p-0 h-100">
							<div className={isClicked ? "epg_wrapper scroll" : "epg_wrapper"}>
								<button onClick={() => setIsClicked(true)} type="" class={isClicked ? "show_more d-none" : "show_more"}>{t("programs.showMore")}</button>
								<div class="title w-100">
									<h4>{t("programs.earlierToday")}</h4>
								</div>
								{!episodeLoading && !error && <ListAllProgramEpisodes episodes={pastEpisodes} props={props} />}
								{!episodeLoading && !error && <ListAllProgramEpisodes episodes={liveEpisodes} props={props} />}
								<div class="title w-100">
									<h4>coming up next</h4>
								</div>
								{!episodeLoading && !error && <ListAllProgramEpisodes episodes={futureEpisodes} props={props} />}
								{episodeLoading && <SpinnerLoader backgroundColor="black" />}
								{/* {!episodeLoading && error && <h3 className="d-flex justify-content-center align-items-center w-100 h-100">{t("errors.noEpisodes")}</h3>} */}
								<EmptyResultsPage></EmptyResultsPage>
							</div>
						</div>
					</div>
				</div>
			</div>

			<ListAllPrograms programs={programs} isLoading={loading} props={props} />



		</div>

	)

}

export default withTranslation()(ProgramListPage);