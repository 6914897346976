import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { fetchVideoLibraryById } from "../store/actions/videolibrary";
import { useParams } from "react-router-dom";
import { withTranslation } from "react-i18next";

import Share from "../components/Utils/SocialMediaShare/Share";
import { fetchVideoLibrayById } from "../store/actions/videolibrary";
import { fetchVideoPodcastById } from "../store/actions/podcast";
import { fetchEpisodeDetail } from '../store/actions/programs';
import { getDateFromNow } from "../scripts/utils";

import ReactPlayer from "react-player";
import OurContent from "../components/Utils/OurContent";
import { KalturaPlayerProvider } from "../components/kaltura-player";
import { PlayerContainer } from "./player-container";
import { EntriesConfig } from "./player-container";
import { PlayerBundleConfig } from "../components/kaltura-player";
import sendAnalyticsEvent from "../scripts/analyticsEvent";

import env from "../config/env";


const VideoLandingPage = (props) => {

    const { t } = props;

    const { videoId } = useParams();

    const locale = useSelector((state) => state.info.locale);
    const cookieConsent = useSelector((state) => state.info.cookieConsent.consent);
    const [video, setVideo] = useState(null);

    /* KALTURA */
    const [playerConfig, setPlayerConfig] = useState(null);
    const [entriesConfig, setEntriesConfig] = useState(null);

    useEffect(() => {

        async function fetchVideoLibraryData() {
            await fetchVideoLibraryById(locale, videoId)
                .then((searchList) => {
                    setVideo(searchList);
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        async function fetchVideoPodcastData() {
            await fetchVideoPodcastById(locale, videoId)
                .then((searchList) => {
                    setVideo(searchList);
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        async function fetchEpisodeDetailData() {
            await fetchEpisodeDetail(videoId)
                .then(data => {
                    setVideo(data);
                    console.log(data)
                })
                .catch(error => {
                    console.log(error);
                })
        }

        if (props.location.pathname.includes("Video-Podcast")) fetchVideoPodcastData();
        else if (props.location.pathname.includes("programVideo")) fetchEpisodeDetailData();
        else fetchVideoLibraryData();
    }, []);


    useEffect(() => {

        setPlayerConfig({
            bundlerUrl: "https://cdnapisec.kaltura.com",
            partnerId: env.partnerId,
            ks: "",
            uiConfId: env.uiConfId
        });
        setEntriesConfig({
            entryId: video?.items[0]?.fields?.video?.entryId,
            alternateEntryId: "1_p1ekn50i"
        });
    }, [video])

    useEffect(() => {
		sendAnalyticsEvent({type:"viewed",page:"video landing page", cookieConsent: cookieConsent});
	}, [])



    return (video ?

        <div>
            <div class="container featured light_theme inner_content">
                <div className="row">
                    <div className="col-12">
                            {playerConfig && entriesConfig && entriesConfig.entryId ? (
                                <>
                                    <KalturaPlayerProvider playerBundleConfig={playerConfig}>
                                        <PlayerContainer entriesConfig={entriesConfig} />
                                    </KalturaPlayerProvider>
                                </>
                            ) :
                            <div class="d-flex justify-content-center">
                                <ReactPlayer
                                    controls={true}
                                    width="560"
                                    height="315"
                                    url={props.location.pathname.includes("programVideo") ? video.source : props.location.pathname.includes("Video-Podcast") ? video.items[0].fields?.episode?.directURL : video.items[0].fields?.video?.directURL}
                                ></ReactPlayer>
                                </div>
                            }
                    </div>
                </div>

                <div class="row border-bottom">
                    <div class="col-12 col-lg-8 mb-3 mb-md-4 d-block d-md-flex justify-content-start pb-2 pt-4">
                        <div class="d-md-flex justify-content-start">
                            {!props.location.pathname.includes("programVideo") &&
                                <p class="dot_nine align-self-end mr-2 mr-rtl-2">
                                    <strong>{t("newsDetailPage.published")}</strong> {"  " + getDateFromNow(video.items[0].fields?.authoring_date?.value, locale)}
                                </p>}
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 d-flex justify-content-lg-end justify-content-start align-items-end h-100 pb-2 pt-0 pt-lg-4">
                        <ul class="social_share">
                            <Share
                                Id={props.location.pathname.includes("programVideo") ? videoId : video.items[0].id}
                                Title={props.location.pathname.includes("programVideo") ? video.title : video.items[0].fields?.title}
                                Summary={props.location.pathname.includes("programVideo") ? video.summary : video.items[0].fields?.summary}
                                Tag={''}
                                type={props.location.pathname.includes("programVideo") ? "Program-Video" : (props.location.pathname.includes("Video-Podcast")) ? "Video-Podcast" : "Video-Library"}
                            />
                        </ul>
                    </div>
                </div>
                <div class="row pb-4 mb-3">
                    <div class="col-12">
                        <h1 class="no_cat" style={{ height: "auto" }}>{props.location.pathname.includes("programVideo") ? video.programName : video.items[0].fields?.title}</h1>
                        <p class="no_cat" style={{ color: "black", marginTop: "auto", display: "flex" }}>{props.location.pathname.includes("programVideo") ? video.title + " - " + video.summary : video.items[0].fields?.summary}</p>
                    </div>
                </div>

            </div>

            <div class="container featured light_theme">
                <div class="row mt-5">
                    <div class="col-12">
                        <h2 class="our_content" style={{ paddingRight: locale === "arSA" ? "0px" : "68px" }}>{t("generic.moreContent")}</h2>
                    </div>
                </div>
            </div>

            <OurContent />
        </div>

        : null)



}


export default withTranslation()(VideoLandingPage);