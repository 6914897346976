import { combineReducers } from "redux";

import { info } from "./infoReducer";
import { listCategories } from "./categoriesReducer";
import { listHighlightsNews, listNewstickerNews, listHomepage } from "./homepageReducer";
import { listPrograms } from "./programsReducer"

export default combineReducers({
  info,
  homepage: listHomepage,
  categories: listCategories,
  highlightsNews: listHighlightsNews,
  newstickerNews: listNewstickerNews,
  programs: listPrograms
});
