import React, {useEffect} from 'react';
import { useSelector } from "react-redux";
import { withTranslation } from 'react-i18next';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import { Link } from "react-router-dom";
import sendAnalyticsEvent from "../scripts/analyticsEvent";


const HelpPage = (props) => {

  // return (
  //   <>
  //     {isMobile ?
  //       (<MobileView>
  //         <h1 style={{ color: 'black' }}> This is on mobile </h1>
  //       </MobileView>)
  //       :
  //       (<BrowserView>
  //         <h1 style={{ color: 'black' }}> This isn't on mobile </h1>
  //       </BrowserView>)
  //     }
  //   </>
  // )
  const { t } = props;
  const cookieConsent = useSelector((state) => state.info.cookieConsent.consent);

  useEffect(() => {
		sendAnalyticsEvent({type:"viewed",page:"help", cookieConsent: cookieConsent});
	}, [])
  
  const ButtonMailto = ({email, subject, description}) => {
    return (
        <Link
            to='#'
            onClick={(e) => {
                window.location = `mailto:${email}?subject=${subject}&body=${description}`
                e.preventDefault();
            }}
        >
            <button type="submit" class="btn btn-primary btn-lg">{t('help.send')}</button>
        </Link>
    );
};

  return (
    <div>
      <div class="container featured light_theme">
        <div class="row mb-4">
          <div class="col-12">
            <h1 class="align-self-end">{t('home.help')}</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p>Use the form below to report any problems with our website. Our office hours are Sunday through Thursday, 9 am to 5 pm Eastern, and we attempt to respond to support requests within 1 business day.</p>
          </div>
        </div>

 {/*        <form>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="inputName">{t('help.name')}</label>
              <input type="text" class="form-control" id="inputName" placeholder={t('help.yname')} />
            </div>
            <div class="form-group col-md-6">
              <label for="inputEmail4">{t('help.email')}</label>
              <input type="email" class="form-control" id="inputEmail4" placeholder={t('help.yemail')} />
              <small class="form-text text-muted">We'll never share your email with anyone else.</small>
            </div>
          </div>
          <div class="form-group">
            <label for="inputAddress">{t('help.ysystem')}</label>
            <input type="text" class="form-control" id="inputAddress" placeholder={isMobile ? t('help.mobile') : t('help.browser')} />
            <small id="emailHelp" class="form-text text-muted">We've auto detected the system you're using. Please change it if it doesn't refer to the system where the problem is ocurring.</small>
          </div>
          <div class="form-group">
            <label for="exampleFormControlTextarea1">{t('help.describe')}</label>
            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
          </div>
          <div class="form-group"> */}
            {/* <label for="attachments">Attachments (optional)</label> */}
            {/* <p style={{"min-height: 100px line-height: 100px, border: 2px dashed #ccc, width: 100%, text-align: center, margin-top: 0.5rem"}}>colocar aqui o componente de multi upload com drag and drop. Depois eu estilizo-o</p> */}
         {/*  </div>
          <div class="form-group submit_button">
            <button type="submit" class="btn btn-primary btn-lg">{t('help.send')}</button>
          </div>
        </form> */}
          <div class="form-group submit_button">
            <ButtonMailto email ="support@example.com" subject ="SendMail" description = "Description" type="submit" class="btn btn-primary btn-lg">{t('help.send')}</ButtonMailto>
          </div>
      </div>

    </div>
  )
}

export default withTranslation()(HelpPage);