import React from 'react';
import page_not_found from "../assets/img/page_not_found.svg"
import page_not_found_dark from "../assets/img/page_not_found_dark.svg";
import { useSelector } from "react-redux";
import {isMobile} from "react-device-detect";

/**
 * Functional Component for the Not Found Screen
 */
const NotFound = () => {

  const theme = useSelector((state) => state.info.theme);

  return(
  <div class="imgbox" style={{minHeight: "calc(100vh - 139px)"}}>
    <img class="center-fit" src={theme==="light" ? page_not_found : page_not_found_dark} alt="Page not found"></img>
  </div>
)

};

export default NotFound;
