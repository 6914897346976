import i18n from 'i18next';
import { en, ar } from './locales';

const options = {
  interpolation: {
    escapeValue: false, // not needed for react!!
  },

  debug: true,

  lng: 'en',

  resources: {
    ar: {
      common: ar['ar-SA'],
    },
    en: {
      common: en.en,
    },
  },

  fallbackLng: 'en',

  ns: ['common'],

  defaultNS: 'common',

  react: {
    wait: true,
  },
};

i18n.init(options);

export default i18n;