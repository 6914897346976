import { SET_THEME, IS_PREVIEW, CHANGE_LANGUAGE, STREAM_REFRESH, COOKIE_CONSENT } from "../constants/infoConstants"

export const setTheme = (theme) => async (dispatch) => {
    dispatch({ type: SET_THEME, payload: theme })
}

export const setPreview = (boolean) => async (dispatch) => {
    dispatch({ type: IS_PREVIEW, isPreview: boolean })
}

export const setLanguage = (language) => async (dispatch) => {

    dispatch({ type: CHANGE_LANGUAGE, locale: language });
    localStorage.setItem("locale", JSON.stringify({ "language": language }));
}

export const setStreamRefresh = (boolean) => async (dispatch) => {
    dispatch({ type: STREAM_REFRESH, streamRefresh: boolean })
}

export const setCookieConsent = (boolean) => async (dispatch) => {
    dispatch({type: COOKIE_CONSENT, consent: boolean})
    console.log("storage", boolean);
    
}