import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';

import ProgramEpisodeCard from './ProgramEpisodeCard';


const ListAllEpisodes = ({ episodes, props }) => {

  /**
   * TODO:
   * fazer corresponder os parametros da API com os do ProgramEpisodeCard
   */

  const { t } = props;

  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
  }, [isClicked])

  const handleClick = () => {
    setIsClicked(true)
  }

  return (
    <div style={isClicked ? { overflowY: 'auto', paddingBottom: 0 } : null} class="epg_wrapper detail">
      {episodes && episodes.length > 4 ?
       <button onClick={handleClick} type="" class={isClicked ? "show_more d-none" : "show_more"}>{t("programs.showMore")}</button>
      : null}
      {episodes &&
        episodes.map((episode, index) => {
          return (
            < ProgramEpisodeCard
              key={index}
              id={episode.id}
              startTime={episode.startTime}
              programName={episode.programName}
              episodeName={episode.episodeName}
              isAlive={episode.isAlive}
              isProgram={episode.isProgram}
              description={episode.description}
              props={props}
            />
          )
        })}
    </div>
  )
}

export default withTranslation()(ListAllEpisodes);