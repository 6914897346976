import {
  CATEGORIES_LIST_REQUEST,
  CATEGORIES_LIST_SUCESS,
  CATEGORIES_LIST_FAIL
} from "../constants/newsConstants";

export function listCategories(state = { categoriesList: [] }, action) {
  switch (action.type) {
    case CATEGORIES_LIST_REQUEST:
      return {
        ...state,
        categoriesList: [],
        loading: true
      };
    case CATEGORIES_LIST_SUCESS:
      return {
        ...state,
        categoriesList: action.payload,
        loading: false
      };
    case CATEGORIES_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    default:
      return state;
  }
}
