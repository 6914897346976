import React, { useState, useEffect, useRef, useCallback, forwardRef } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { withTranslation } from "react-i18next";
import axios from "axios";
import queryString from "query-string";

import NewsCategoryTitle from "../components/NewsCategory/NewsCategoryTitle";
import SmallNewsCard from "../components/NewsCategory/SmallNewsCard";
import SmallNewsCardLoader from "../components/Utils/Loaders/SmallNewsCardLoader";
import { sendPageView, sendSearchView } from "../components/Utils/GATracker";
import EmptyResultsPage from "../pages/EmptyResultsPage"
import ScrollButton from "../components/Utils/ScrollToTop";

import { globalSearch } from "../store/actions/globalsearch";
import env from "../config/env";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import sendAnalyticsEvent from "../scripts/analyticsEvent";




const SearchListPage = (props) => {

  const { t } = props;

  const { search } = useLocation();

  const values = queryString.parse(search);

  

  const locale = useSelector((state) => state.info.locale);
  const cookieConsent = useSelector((state) => state.info.cookieConsent.consent);

  const theme = useSelector((state) => state.info.theme);

  const { word } = useParams();
  const history = useHistory();

  const filterCSS = "social_links mr-4 float-left";
   
  const filterList = env.showPrograms ? [
    {
      name: t('generic.viewAll'),
      //type: "News,Audio-Podcast,Video-Podcast,Video-Library,Program",
      type: "News,Video-Library,Programs",
      css: filterCSS
    },
    { name: t('home.news'), type: "News", css: filterCSS },
    { name: t('programs.programs'), type: "Program", css: filterCSS },
    //{ name: t('podcasts.podcasts'), type: "Audio-Podcast,Video-Podcast", css: filterCSS },
    { name: t('podcasts.videos'), type: "Video-Library", css: filterCSS }
  ] :
  [
    {
      name: t('generic.viewAll'),
      //type: "News,Audio-Podcast,Video-Podcast,Video-Library,Program",
      type: "News,Video-Library",
      css: filterCSS
    },
    { name: t('home.news'), type: "News", css: filterCSS },
    //{ name: t('programs.programs'), type: "Program", css: filterCSS },
    //{ name: t('podcasts.podcasts'), type: "Audio-Podcast,Video-Podcast", css: filterCSS },
    { name: t('podcasts.videos'), type: "Video-Library", css: filterCSS }
  ]


  const [isFiltered, setIsFiltered] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [tempList, setTempList] = useState(filterList);
  const [offset, setOffset] = useState(0);
  const [searchResults, setSearchResults] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState("");
  const [tag, setTag] = useState(null);
  // let counter = 0;

  // const [dateRange, setDateRange] = useState([null, null]);
  // const [startDate, endDate] = dateRange;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
  }, [startDate, endDate])





  /**
   * TODO:
   * se tivermos com 4 quadrados e depois encolhermos para 3 quadrados ele nao atualiza o limit
   */

  const getNumberOfCards = useCallback(() => {

    let limit = 0;

    if (window.innerWidth >= 1200) {
      limit = 8;
    }
    else if (window.innerWidth < 1200 && window.innerWidth >= 991) {

      limit = 6;
      // counter = counter + 1;

      // if (counter === 1) {
      //   limit = 3 - (searchResults.length) % 3 // falta mudar o valor que o loading vai receber laa em baixo
      // }
    }
    else if (window.innerWidth < 991 && window.innerWidth >= 768) {
      limit = 4;
    }
    else if (window.innerWidth < 768) {
      limit = 2;
    }

    return limit;
  }, [window.innerWidth]);

  useEffect(() => {

    if (search.startsWith("?viewall")) {
      filterList[0].css = "social_links active mr-4 float-left";
      setTempList(filterList);
      setQuery(values.viewall);
      /* RESET */
      setOffset(0);
      setSearchResults([]);
      setCurrentIndex(0);
    }
    if (search.startsWith("?tag")) {

      setTag(values.tag);
      setQuery("#"+values.tag);
      setOffset(0);
      setSearchResults([]);
    }
    else if(!search.startsWith("?tag")){
      setTag("");
    }

  }, [search])

  // useEffect(() => {
  //   console.log("values", values);
  //   if(values.tag != null){
      
  //     setTag(values.tag);
  //   }
  // }, [values])



  useEffect(() => {
    filterList[0].css = "social_links active mr-4 float-left";
    sendAnalyticsEvent({type:"viewed",page:"Search", locale: locale, cookieConsent: cookieConsent});
  }, []);

  useEffect(() => {
    /* RESET */
    setOffset(0);
    setSearchResults([]);
  }, [locale]);

  useEffect(() => {
    /* RESET */
    setOffset(0);
  }, [query, tag, startDate, endDate]);

 

  useEffect(() => {
    sendPageView("Search");
    sendSearchView("Search", word);
  }, [word]);

  useEffect(() => {
    setSearchResults([]);

    if (isFiltered) {
      filterList[currentIndex].css = "social_links active mr-4 float-left";
      setTempList(filterList);
    }
  }, [query, tag, currentIndex, isFiltered, startDate, endDate]);

  useEffect(() => {

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    setLoading(true);

    async function fetchAllContent(input) {
      await globalSearch(
        input,
        tempList[currentIndex].type,
        offset,
        getNumberOfCards(),
        locale,
        source.token,
        startDate,
        endDate,
        tag
      )
        .then((searchList) => {
          if (searchResults !== null && searchResults.length > 0) {
            searchList.items.map((item) => {
              setSearchResults((prevResults) => [
                ...new Set([...prevResults, item])
              ]);
            });
          } else {
            setSearchResults(searchList.items);
          }
          setHasMore(searchList.hasMore);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    fetchAllContent(query);


    return () => {
      source.cancel("Cancelled due to another request")
    }
  }, [query, tag, offset, currentIndex, locale, tempList, startDate, endDate]);

  const handleFilter = (key) => {
    setIsFiltered(true);
    setCurrentIndex(key);
    setOffset(0);
  };

  const handleSearch = (e) => {
    history.push(`/${locale === "arSA" ? "ar" : "en"}/ekhsearch?${e.target.value.charAt(0) === '#' ? 'tag='+e.target.value.substring(1) : 'viewall='+e.target.value}`);
    if(e.target.value.charAt(0) === '#'){
      
      setTag(e.target.value.substring(1));

    }
      
      setQuery(e.target.value);

    
    
    // em cada pesquisa queremos recomeccar do 0
    setSearchResults([]);
    setOffset(0);
    sendAnalyticsEvent({page:"Search", cookieConsent: cookieConsent,type:"input", label: "search", value:e.target.value.charAt(0) === '#' ? '#'+e.target.value.substring(1) : +e.target.value}); 

  };

  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      // o node ee o ultimo elem se nao e o node queremos dar disconnect
      if (observer.current) observer.current.disconnect();

      // entries vai corresponder a tudo que se vai tornar visivel depois do scroll
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setOffset((prevValue) => prevValue + getNumberOfCards());
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const StartDateCustomInput = forwardRef(({ value, onClick }, ref) => (
    <>
      <input type="text" className="" id="start-date" placeholder="انقر للضبط" onClick={onClick} ref={ref} value={value}></input>
      <label for="start-date">تاريخ البدأ</label>
    </>
  ));

  const EndDateCustomInput = forwardRef(({ value, onClick }, ref) => (
    <>
      <input type="text" className="" id="end-date" placeholder="انقر للضبط" onClick={onClick} ref={ref} value={value}></input>
      <label for="end-date">تاريخ الإنتهاء</label>
    </>
  ));

  return (
    <div className="container-fluid featured">
      {/* <NewsCategoryTitle props={props} isFixedLayout={false} name={t('search.searchResults')} /> */}
      <div className="row mb-5">
        <div className="col-12 col-lg-3 d-md-flex justify-content-md-start">
          <h1 className="align-self-end no_cat">
            {t('search.searchResults')}
          </h1>
        </div>
        <div className="col-12 col-lg-6 d-flex justify-content-center align-items-end">
          <form className="body_search">
            <input
              value={query}
              onChange={handleSearch}
              className="body_search_input"
              type="search"
              aria-label="Search"
            />
            <button onClick={(event) => { event.preventDefault(); history.push(`/${locale === "arSA" ? "ar" : "en"}/ekhsearch?${query.charAt(0) === '#' ? 'tag='+query.substring(1) : 'viewall='+query}`); }} className="search_button" type="submit">
              <i className="fal fa-search"></i>
            </button>
          </form>
        </div>
        <div className="col-12 col-lg-3 filters">
          <div className="picker_wrapper" style={{textAlign: "right"}}>
            <DatePicker
              selected={startDate}
              onChange={(update) => {
                setStartDate(update);
              }}
              // isClearable={true}
              customInput={<StartDateCustomInput/>}
              withPortal
            />
            
          </div>
          <div className="picker_wrapper" >
            
            <DatePicker
              selected={endDate}
              onChange={(update) => {
                setEndDate(update);
              }}
              customInput={<EndDateCustomInput/>}
              withPortal
            />
          </div>
        </div>
      </div>

      <div className="row mt-5 mb-5">
        <div className="col-12 mt-3 social_feed">
          <h2 className="cat_11 float-left mr-5 mb-2 mb-md-0">
            {t("search.filter")}
          </h2>
          <div className="float-left" style={{ display: locale === "arSA" ? "flex" : "" }}>
            {/* <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={(update) => {
                setDateRange(update);
              }}
              isClearable={true}
              withPortal
            /> */}
            
            {filterList.map((filter, index) => (
              <a
                onClick={() => handleFilter(index)}
                key={index}
                href="#"
                className={tempList[index].css}
              >
                {filter.name}
              </a>
            ))}
          </div>
        </div>
        {/* <div className="col-12 filters">
          <div className="picker_wrapper" style={{textAlign: "right"}}>
            <DatePicker
              selected={startDate}
              onChange={(update) => {
                setStartDate(update);
              }}
              // isClearable={true}
              customInput={<StartDateCustomInput/>}
              withPortal
            />
            
          </div>
          <div className="picker_wrapper" >
            
            <DatePicker
              selected={endDate}
              onChange={(update) => {
                setEndDate(update);
              }}
              customInput={<EndDateCustomInput/>}
              withPortal
            />
          </div>
        </div> */}
      </div>

      <div className="row mt-4 pl-3" style={{ paddingRight: locale === "arSA" ? "1rem" : "" }}>
        {searchResults &&
          searchResults.map((card, index) => {
            if (searchResults.length === index + 1) {
              return (
                <div
                  ref={lastElementRef}
                  className="col-12 col-md-6 col-lg-4 col-xl-3 p-0 mb-4"
                >
                  <SmallNewsCard
                    key={index}
                    id={card.id}
                    type={card.type}
                    title={card.title}
                    summary={card.summary}
                    cover_image={card.cover_image?.directURLArray?.small}
                    imageClass={card?.image_alignment}
                    category={card?.category}
                    newsticker={false}
                    counter={null}
                    viewCounts={false}
                    analyticsInfo={{page:"Search", section: "news list"}}
                  />
                </div>
              );
            } else {
              return (
                <div className="col-12 col-md-6 col-lg-4 col-xl-3 p-0 mb-4">
                  <SmallNewsCard
                    key={index}
                    id={card.id}
                    type={card.type}
                    title={card.title}
                    summary={card.summary}
                    cover_image={card.cover_image?.directURLArray?.small}
                    imageClass={card?.image_alignment}
                    category={card?.category}
                    newsticker={false}
                    counter={null}
                    analyticsInfo={{page:"Search", section: "news list"}}
                  />
                </div>
              );
            }
          })}
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {loading && getNumberOfCards() === 8 ?
          <div>
            <SmallNewsCardLoader className="col-12 col-md-6 col-lg-4 col-xl-3 p-0 mb-4" analyticsInfo={{page:"Search", section: "news list"}}/>
            <SmallNewsCardLoader className="col-12 col-md-6 col-lg-4 col-xl-3 p-0 mb-4" analyticsInfo={{page:"Search", section: "news list"}}/>
            <SmallNewsCardLoader className="col-12 col-md-6 col-lg-4 col-xl-3 p-0 mb-4" analyticsInfo={{page:"Search", section: "news list"}}/>
            <SmallNewsCardLoader className="col-12 col-md-6 col-lg-4 col-xl-3 p-0 mb-4" analyticsInfo={{page:"Search", section: "news list"}}/>
          </div>
          : loading && getNumberOfCards() === 6 ?
            <div>
              <SmallNewsCardLoader className="col-12 col-md-6 col-lg-4 col-xl-3 p-0 mb-4" analyticsInfo={{page:"Search", section: "news list"}}/>
              <SmallNewsCardLoader className="col-12 col-md-6 col-lg-4 col-xl-3 p-0 mb-4" analyticsInfo={{page:"Search", section: "news list"}}/>
              <SmallNewsCardLoader className="col-12 col-md-6 col-lg-4 col-xl-3 p-0 mb-4" analyticsInfo={{page:"Search", section: "news list"}}/>
            </div>
            : loading && getNumberOfCards() === 4 ?
              <div>
                <SmallNewsCardLoader className="col-12 col-md-6 col-lg-4 col-xl-3 p-0 mb-4" analyticsInfo={{page:"Search", section: "news list"}}/>
                <SmallNewsCardLoader className="col-12 col-md-6 col-lg-4 col-xl-3 p-0 mb-4" analyticsInfo={{page:"Search", section: "news list"}}/>
              </div>
              : loading && getNumberOfCards() === 2 ?
                <div>
                  <SmallNewsCardLoader className="col-12 col-md-6 col-lg-4 col-xl-3 p-0 mb-4" analyticsInfo={{page:"Search", section: "news list"}}/>
                </div>
                : null

        }
      </div>
      {!loading && searchResults.length === 0 &&
          <EmptyResultsPage></EmptyResultsPage>
        }
        <ScrollButton></ScrollButton>
    </div>
  );
};

export default withTranslation()(SearchListPage);
