import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { withTranslation } from 'react-i18next';

import VideoPlayer from '../Utils/VideoPlayer';
import { fetchEpisodeDetail } from '../../store/actions/programs';
import { getDateFromEpisode, getTotalMinutes } from '../../scripts/utils';

const ProgramEpisodeCard = ({ id, startTime, programName, episodeName, isAlive, isProgram, description, props }) => {

  /**
   * TODO:
   * fazer a correpondencia do isAlive com os valores que veem da api
   */

  const { t } = props;

  const locale = useSelector((state) => state.info.locale);

  const [open, setOpen] = useState(false);
  const [duration, setDuration] = useState();
  const [videoURL, setVideoURL] = useState();

  const onOpenModal = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  let currentProgramCSS = "";

  if (isAlive === "active") {
    currentProgramCSS = "epg_card active d-flex align-items-center";
  }
  else if (isAlive === "disabled") {
    currentProgramCSS = "epg_card disabled d-flex align-items-center";
  }
  else {
    currentProgramCSS = "epg_card d-flex align-items-center";
  }


  useEffect(() => {

    async function fetchEpisodeDetailData(id) {
      await fetchEpisodeDetail(id)
        .then(data => {
          setVideoURL(data.source);
          setDuration(data.duration);
        })
        .catch(error => {
          console.log(error);
        })
    }

    fetchEpisodeDetailData(id)
  }, [])

  return (
    <div class={currentProgramCSS}>
      <dl
        /*  style={{ paddingRight: locale === "en-US" ? 15 : "", paddingLeft:locale === "en-US" ? "" : 15}}  */
        class="time">
        <dt style={{ textTransform: "uppercase" }}>{isProgram ? startTime : getDateFromEpisode(startTime, locale)}</dt>
        {duration !== "Duration undefined" && <dd style={{ color: "#808080" }}>{getTotalMinutes(duration, locale) + " " + t("programs.minutes")}</dd>}
      </dl>
      <dl class="info">
        <dt>{isProgram ? programName : episodeName}</dt>
        <dd style={{ color: "#808080" }}>{isProgram ? episodeName : description}</dd>
      </dl>
      {isProgram ?
        <a title="" class="ml-auto stretched-link">{t("programs.previousEpisodes")}</a>
        :
        <>
          <a onClick={onOpenModal} title="" class="align-self-center stretched-link ml-auto">
            <div class="vid_icon ml-auto"></div>
          </a>
          {open &&
            <VideoPlayer
              onClose={onCloseModal}
              open={open}
              id={id}
              episode={videoURL}
              title={episodeName}
              summary={description}
              duration={duration}
              date={getDateFromEpisode(startTime, locale)}
              type="Program-Video"
            />
          }
        </>
      }
    </div>
  )
}

export default withTranslation()(ProgramEpisodeCard);
