import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import "../assets/css/alekhbariya_link.css";

import Streaming from "../components/Utils/Streaming";
import NewsTicker from "../components/News/NewsTicker";
import NewsHighlights from "../components/News/NewsHighlights";
import BreakingNews from "../components/News/BreakingNews";
import DynamicLayout from "../components/NewsCategory/DynamicLayout";
import FixedLayout from "../components/NewsCategory/FixedLayout";
import SocialMedia from "../components/SocialMedia/SocialMedia";
import ShareMetaData from '../components/Utils/SocialMediaShare/ShareMetaData';
import { sendPageView } from "../components/Utils/GATracker";
import sendAnalyticsEvent from "../scripts/analyticsEvent";
import {
  fetchAllHighlightsThunk,
  fetchAllNewstickerThunk,
  fetchHomepagePreviewContent,
  fetchHomepageContent,
  fetchBreakingNews
} from "../store/actions/news";

import { setPreview } from "../store/actions/info";
import useMobileDetect from "use-mobile-detect-hook";
//import { setDevice } from "./store/actions/infoActions";
import { deviceDetect } from "react-device-detect";
import VideoLibraryLayout from "../components/NewsCategory/VideoLibraryLayout";


export const setDevice = (device) => async (dispatch) => {
  dispatch({ type: "CHANGE_DEVICE", device: device });
};

const HomePage = (props) => {

 
  const { t } = props;
  const { homepageContentId } = useParams();

  const locale = useSelector((state) => state.info.locale);
  const cookieConsent = useSelector((state) => state.info.cookieConsent.consent);
  const highlightsState = useSelector((state) => state.highlightsNews);
  const newstickersState = useSelector((state) => state.newstickerNews);
  const isPreview = useSelector((state) => state.info.isPreview);

  const [categoriesGroups, setCategoriesGroups] = useState([]);
  const [breakingNews, setBreakingNews] = useState([]);
  const [showBreakingNews, setShowBreakingNews] = useState(false);
  const [homepageData, setHomePageData] = useState([]);
  const [homepagePreviewData, setHomePagePreviewData] = useState([]);

  const dispatch = useDispatch();


  

  const detectMobile = useMobileDetect();
  const [isMobile, setIsMobile] = useState(false);
  
  useEffect(() => {
    if (detectMobile.isMobile()) setIsMobile(true);
  }, [detectMobile]);


  const showBreakingNewsClick = () => {
    setShowBreakingNews(false);
  };

  const validateBreakingNews = (br) => {
    
    setShowBreakingNews(false);
    br.map((elem) => {

      if(window.sessionStorage.getItem("breakingNews" + elem.id) === null || window.sessionStorage.getItem("breakingNews" + elem.id) === "null") {
        setShowBreakingNews(true);
      }
    });

  };

  useEffect(() => {
    
    sendPageView("Home");
    sendAnalyticsEvent({type:"viewed",page:"home", category: "home", locale: locale, cookieConsent: cookieConsent});
    if (homepageContentId) {
      dispatch(setPreview(true));
    } else {
      dispatch(setPreview(false));
    }

  }, []);

  const MINUTE_MS = 60000;


  useEffect(() => {
    // FETCH BREAKING NEWS CONTENT
    async function fetchBreakingNewsData() {
      await fetchBreakingNews(locale)
        .then((newsList) => {
          setBreakingNews(newsList.items);

          validateBreakingNews(newsList.items);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    fetchBreakingNewsData();

    const interval = setInterval(() => {

      async function fetchBreakingNewsData() {
        await fetchBreakingNews(locale)
          .then((newsList) => {
            setBreakingNews(newsList.items);

            validateBreakingNews(newsList.items);
            //setShowBreakingNews(newsList.items.length > 0 ? true: false);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      fetchBreakingNewsData();
    }, MINUTE_MS);

    return () => clearInterval(interval);

  }, [homepageContentId, locale]);

  useEffect(() => {
    // FETCH HOMEPAGE CONTENT

    async function fetchHomepageData() {
      await fetchHomepageContent(locale)
        .then((newsList) => {
          setHomePageData(newsList.items[0].fields || homepageData);
          setCategoriesGroups(Object.values(newsList.items[0].fields.groups));
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // FETCH HOMEPAGE PREVIEW CONTENT

    async function fetchHomepagePreviewData(id) {
      await fetchHomepagePreviewContent(id, locale)
        .then((newsList) => {
          setHomePagePreviewData(newsList.fields || homepagePreviewData);
          setCategoriesGroups(Object.values(newsList.fields.groups));
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (!homepageContentId) {
      fetchHomepageData();
    } else {
      fetchHomepagePreviewData(homepageContentId);
    }

  }, [homepageContentId, locale]);

  useEffect(() => {

    if (isPreview) {
      homepagePreviewData.number_of_highlights && highlightsState.newsList.length === 0 &&
        dispatch(fetchAllHighlightsThunk(homepagePreviewData.number_of_highlights, locale));
      homepagePreviewData.number_of_news_ticker && newstickersState.newsList.length === 0 &&
        dispatch(fetchAllNewstickerThunk(homepagePreviewData.number_of_news_ticker, locale));

    } else {

      homepageData.number_of_news_ticker &&
        dispatch(fetchAllNewstickerThunk(isMobile ? 3 : homepageData.number_of_news_ticker, locale));
      homepageData.number_of_highlights &&
        dispatch(fetchAllHighlightsThunk(homepageData.number_of_highlights, locale));

    }

  }, [homepageData, homepagePreviewData, locale]);

  /*let bnews = window.sessionStorage.getItem("breakingNews");
  console.log("Breaking: " + bnews);*/

  return (
    <div className={breakingNews && showBreakingNews ? "show_breaking_news" : "hide_breaking_news"}>
      <ShareMetaData title={"الإخبارية نت"}></ShareMetaData>
      {breakingNews ? (
        <BreakingNews props={props} data={breakingNews} onClose={showBreakingNewsClick} showbr={showBreakingNews}/>
      ) : null}
      <div className="container-fluid m-0 p-0">
        <div className="hero">
          <Streaming />
          <div className="hero_news">
            <div className="container-fluid">
              <div className="row">
                {<NewsTicker props={props} analyticsInfo={{page:"home", section: "News Ticker" }}/>}
                {<NewsHighlights props={props} analyticsInfo={{page:"home", section: "News Highlihts"}}/>}
              </div>
            </div>
          </div>
        </div>

        {/* CATEGORY NEWS */}

        <div className="container-fluid featured">

          <VideoLibraryLayout props={props} analyticsInfo={{page:"home", section: "Video Library"}}/>

          <div className="row mb-5">
            <div className="col-12 d-md-flex justify-content-md-start">
              <h1 className="align-self-end no_cat">{t("home.featuredNews")}</h1>
              <span className="align-self-end">{t("home.byCategory")}</span>
            </div>
          </div>

          {(homepageData.groups && homepageData.list_of_menu_categories) ||
            (homepagePreviewData.groups &&
              homepagePreviewData.list_of_menu_categories) ? (
            <div>
              <FixedLayout
                props={props}
                firstGroup={categoriesGroups[0]}
                categoriesNames={
                  isPreview
                    ? homepagePreviewData.list_of_menu_categories
                    : homepageData.list_of_menu_categories
                }
                viewCounts={
                  isPreview
                    ? homepagePreviewData.show_the_view_counts_of_the_most_read_news
                    : homepageData.show_the_view_counts_of_the_most_read_news
                }
              />
              
              <DynamicLayout
                props={props}
                catList={categoriesGroups}
              />
            </div>
          ) : null}
          <SocialMedia props={props} />
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(HomePage);
