import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import SmallNewsCard from "./SmallNewsCard";
import { fetchMostReadNews } from "../../store/actions/news";
import axios from "axios";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import sendAnalyticsEvent from "../../scripts/analyticsEvent";


const MostReadNews = (props) => {

  const { t } = props;

  const dispatch = useDispatch();

  const locale = useSelector((state) => state.info.locale);
  const cookieConsent = useSelector((state) => state.info.cookieConsent.consent);

  const viewCounts = useSelector((state) => state.homepage.showMostReadViews);

  const [news, setNews] = useState(null);
  const [lastDayNews, setLastDayNews] = useState(null);
  const [lastWeekNews, setLastWeekNews] = useState(null)

  const newsticker = props.newsticker;
  const limit = newsticker ? 3 : 6;


  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    async function fetchMostRead() {
      await fetchMostReadNews(locale, limit, "", source.token, "")
        .then((newsList) => {
          setNews(newsList);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    async function fetchLastDayMostRead() {
      await fetchMostReadNews(locale, limit, "", source.token, "today")
        .then((newsList) => {
          setLastDayNews(newsList);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    async function fetchLastWeekMostRead() {
      await fetchMostReadNews(locale, limit, "", source.token, "lastWeek")
        .then((newsList) => {
          setLastWeekNews(newsList);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    fetchMostRead();
    fetchLastDayMostRead();
    fetchLastWeekMostRead();

    return () => {
      source.cancel("Cancelled due to another request")
    }

  }, [locale]);




  return (
    <>
      {!newsticker ?
        <div class="col-12 col-xl-3 p-0 pl-3 mt-5 mt-xl-0 pr-0 pr-md-3 pr-xl-0">
          <div className={"row pl-3 pl-xl-0 mr-0"}>
            <div className="col-12 d-flex justify-content-start pl-3 pr-3 pr-md-0">
              <div style={{ display: "flex" }}>
                <h2
                  className={"align-self-end cat_2"
                  }
                >
                  {t("newsDetailPage.mostRead")}
                </h2>
                <Link className="view_all align-self-end cat_2"
                  onClick={() =>{
                    dispatch({
                      type: "CHANGED_PATH",
                      path: `/${locale === "arSA" ? "ar" : "en"}/MostReadNews`
                    })
                    sendAnalyticsEvent({cookieConsent: cookieConsent ,type:"click", label:`view all`, section:props.analyticsInfo?.section, page:props.analyticsInfo?.page})

                  }
                    
                  }
                  to={{
                    pathname: `/${locale === "arSA" ? "ar" : "en"}/MostReadNews`,
                    state: viewCounts
                  }}
                >
                  {t('generic.viewAll')}
                </Link>
              </div>
            </div>
          </div>
          <div class="row tabs pl-0 pl-md-3 pr-3 pr-md-0 pl-xl-0 mr-0">
            <Tabs defaultActiveKey="last day" id="uncontrolled-tab-example">
              <Tab eventKey="last day" title={t("newsDetailPage.mostReadLastDay")} className="nav-item">
                <div class={"most_read pl-0 pr-0 m-0"}>
                  <div className={"row pl-0 m-0"}>
                    {lastDayNews
                      ? lastDayNews.map((card, index) => (
                        <div
                          className={"col-12 col-md-4 col-xl-12 pr-0 pl-xl-0 pr-0"
                          }
                        >
                          <SmallNewsCard
                            key={index}
                            id={card.newsId}
                            type="News"
                            title={card.title}
                            summary={card.summary}
                            cover_image={card.cover_image?.directURLArray.small}
                            imageClass={card?.image_alignment}
                            category={
                              card.taxonomies && Object.keys(card.taxonomies).length > 0
                                ? Object.keys(card.taxonomies)[0]
                                : "No Category"
                            }
                            newsticker={newsticker}
                            counter={card.counter}
                            viewCounts={viewCounts}
                            analyticsInfo={props.analyticsInfo}
                          />

                        </div>
                      ))
                      : null}
                  </div>
                </div>
              </Tab>
              <Tab eventKey="last week" title={t("newsDetailPage.mostReadLastWeek")} className="nav-item">
                <div class={"most_read pl-0 pr-0 m-0"}>
                  <div className={"row pl-0 m-0"}>
                    {lastWeekNews
                      ? lastWeekNews.map((card, index) => (
                        <div
                          className={
                            "col-12 col-md-4 col-xl-12 pr-0 pl-xl-0 pr-0"
                          }
                        >
                          <SmallNewsCard
                            key={index}
                            id={card.newsId}
                            type="News"
                            title={card.title}
                            summary={card.summary}
                            cover_image={card.cover_image?.directURLArray.small}
                            imageClass={card?.image_alignment}
                            category={
                              card.taxonomies && Object.keys(card.taxonomies).length > 0
                                ? Object.keys(card.taxonomies)[0]
                                : "No Category"
                            }
                            newsticker={newsticker}
                            counter={card.counter}
                            viewCounts={viewCounts}
                            analyticsInfo={props.analyticsInfo}
                          />

                        </div>
                      ))
                      : null}
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div> :
        <>
          <div className={"row"}>
            <div className="col-12 d-flex justify-content-start pl-3 pr-3 pr-md-0">
              <div style={{ display: "flex" }}>
                <h2
                  className={"align-self-end cat_2 category"
                  }
                >
                  {t("newsDetailPage.mostRead")}
                </h2>
                <Link className="view_all align-self-end cat_2"
                  onClick={() =>{
                    dispatch({
                      type: "CHANGED_PATH",
                      path: `/${locale === "arSA" ? "ar" : "en"}/MostReadNews`
                    })
                    sendAnalyticsEvent({cookieConsent: cookieConsent ,type:"click", label:`view all`, section:props.analyticsInfo?.section, page:props.analyticsInfo?.page})
                  }
                    
                  }
                  to={{
                    pathname: `/${locale === "arSA" ? "ar" : "en"}/MostReadNews`,
                    state: viewCounts
                  }}
                >
                  {t('generic.viewAll')}
                </Link>
              </div>
            </div>
          </div>
          <div>
            <div className={"row"}>
              {news
                ? news.map((card, index) => (
                  <div
                    className={"col-12 pl-3 pr-0 mt-4"  
                    }
                  >
                    <SmallNewsCard
                      key={index}
                      id={card.newsId}
                      type="News"
                      title={card.title}
                      summary={card.summary}
                      cover_image={card.cover_image?.directURLArray.small}
                      imageClass={card?.image_alignment}
                      category={
                        card.taxonomies && Object.keys(card.taxonomies).length > 0
                          ? Object.keys(card.taxonomies)[0]
                          : "No Category"
                      }
                      newsticker={newsticker}
                      counter={card.counter}
                      viewCounts={viewCounts}
                      analyticsInfo={props.analyticsInfo}
                    />

                  </div>
                ))
                : null}
            </div>
          </div>
        </>
      }
    </>
  );
};

export default withTranslation()(MostReadNews);
