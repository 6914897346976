import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from "axios";

import Layout1 from '../Layouts/Layout1';
import Layout2 from '../Layouts/Layout2';
import Layout3 from '../Layouts/Layout3';
import Layout4 from '../Layouts/Layout4';
import Layout5 from '../Layouts/Layout5';
import Layout6 from '../Layouts/Layout6';
import Layout7 from '../Layouts/Layout7';
import Layout8 from '../Layouts/Layout8';
import Layout9 from '../Layouts/Layout9';
import Layout10 from '../Layouts/Layout10';
import Layout11 from '../Layouts/Layout11';
import Layout12 from '../Layouts/Layout12';
import { fetchNewsByCategory } from '../../store/actions/news';


const LayoutSwitch = ({ color, layout, name, hasChildren, childrenList, logo, props, analyticsInfo }) => {

  const locale = useSelector(state => state.info.locale)

  const [news, setNews] = useState([]);
  const childrenArray = [];

  useEffect(() => {
    setNews([]);
  }, [locale]);

  useEffect(() => {

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    async function fetchCategoryNewsData(cat, childrenArray, limit) {

      await fetchNewsByCategory(cat, childrenArray, limit, 0, '', locale, source.token)
        .then(newsList => {
          setNews(newsList.items.length === 0 ? news : newsList.items);
        })
        .catch(error => {
          console.log(error);
        })
    }

    if (hasChildren && childrenList) {
      childrenList.map((childCatName) => {
        childrenArray.push(childCatName);
      })
      fetchCategoryNewsData(name, childrenArray, layout[0])
    }
    else {
      fetchCategoryNewsData(name, [], layout[0]);
    }

    return () => {
      source.cancel("Cancelled due to another request")
    }

  }, [name]);

  switch (layout) {
    case "1 - image":
      return <Layout1 props={props} titleColor={color} data={news} name={name} logo={logo} analyticsInfo={analyticsInfo}/>
      break;
    case "2 - eq images":
      return <Layout2 props={props} titleColor={color} data={news} name={name} logo={logo} analyticsInfo={analyticsInfo}/>
      break;
    case "2 - neq images":
      return <Layout3 props={props} titleColor={color} data={news} name={name} logo={logo} analyticsInfo={analyticsInfo}/>
      break;
    case "3 - images - 2 onr":
      return <Layout4 props={props} titleColor={color} data={news} name={name} logo={logo} analyticsInfo={analyticsInfo}/>
      break;
    case "3 - images - 1 onr":
      return <Layout5 props={props} titleColor={color} data={news} name={name} logo={logo} analyticsInfo={analyticsInfo}/>
      break;
    case "4 - images - tp":
      return <Layout6 props={props} titleColor={color} data={news} name={name} logo={logo} analyticsInfo={analyticsInfo}/>
      break;
    case "4 - images - p":
      return <Layout7 props={props} titleColor={color} data={news} name={name} logo={logo} analyticsInfo={analyticsInfo}/>
      break;
    case "4 - images - 2 onl":
      return <Layout8 props={props} titleColor={color} data={news} name={name} logo={logo} analyticsInfo={analyticsInfo}/>
      break;
    case "5 - images - 1 onr":
      return <Layout9 props={props} titleColor={color} data={news} name={name} logo={logo} analyticsInfo={analyticsInfo}/>
      break;
    case "5 - images - 3 onr":
      return <Layout10 props={props} titleColor={color} data={news} name={name} logo={logo} analyticsInfo={analyticsInfo}/>
      break;
    case "7 - images - t":
      return <Layout11 props={props} titleColor={color} data={news} name={name} logo={logo} analyticsInfo={analyticsInfo}/>
      break;
    case "7 - images":
      return <Layout12 props={props} titleColor={color} data={news} name={name} logo={logo} analyticsInfo={analyticsInfo}/>
      break;
    default:
      return null;
  }

}

export default LayoutSwitch;