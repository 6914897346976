import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { animated, useTransition } from 'react-spring';
import { withTranslation } from 'react-i18next';
import {
	BrowserView,
	MobileView,
	isBrowser,
	isMobile
  } from "react-device-detect";

const AppIcons = React.memo(({ props, onClose, showApp }) => {

	/**
	 * TODO:
	 * fazer o scroll parar onMouseIn e continuar onMouseOut
	 */

	const { t } = props;

	const locale = useSelector((state) => state.info.locale);
	const [isVisible, setIsVisible] = useState(false);

	const [scrolled, setScrolled] = useState(false);

	const transitions = useTransition(showApp, null, {
		from: { opacity: 1 },
		leave: { opacity: 0 },
	})

	const handleClose = () => {
		setIsVisible(false);
		//window.sessionStorage.setItem("appIcons", "false"); 
		localStorage.setItem("appIcons", "false"); 
	}

	useEffect(() => {
		/*if (window.sessionStorage.getItem("appIcons") === null) {
			setIsVisible(true);
		}*/
		if (localStorage && localStorage.getItem("appIcons") === null) {
			setIsVisible(true);
		}
	}, [])

	return (isVisible && 
		<>
			<div class="popup_apps">
				<button type="" class="close_apps" onClick={() => handleClose()}></button>
				<div class="buttons_apps">
					<a target="_blank" href="https://apps.apple.com/sa/app/%D8%A7%D9%84%D8%A5%D8%AE%D8%A8%D8%A7%D8%B1%D9%8A%D8%A9/id1593447272" title="">
						<img src="/assets/images/app_store.svg" alt="" className="mr-2 ml-2"/>
					</a>
					<a target="_blank" href="https://play.google.com/store/apps/details?id=smm.ekh.mobile" title="">
						<img src="/assets/images/google_play.svg" alt="play store" className="mr-2 ml-2"/>
					</a>
				</div>
			</div>	
		</>
	)
});

export default withTranslation()(AppIcons);