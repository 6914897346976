import env from "../../config/env";
import axios from "axios";

export function globalSearch(name, type, offset, limit, locale, token, startDate, endDate, tag) {
  
  if (type === "News" || type === "Program" || type === "Video-Library") {
    return singleTypeSearch(name, type, offset, limit, locale, token, startDate, endDate, tag);
  }
  else {
    return multiTypeSearch(name, type, offset, limit, locale, token, startDate, endDate, tag);
  }
}

function singleTypeSearch(name, type, offset, limit, locale, token, startDate, endDate, tag) {
  let endpoint, title, summary, defaultValue;

  if(name.charAt(0) === '#'){
    name=null
  }
  if (name) {
    title = name;
    summary = name;
    defaultValue = name;
    /*if (type === "Program") {
      console.log("Search Request Program");
      endpoint = `&programName=${name}&type=${type}${startDate? "&startDate="+startDate : ""}${endDate? "&endDate="+endDate : ""}`;
    }
    else */
    if (type === "Video-Library") {
      endpoint = `&offset=${offset}&resultLimit=${limit}&defaultValue=${defaultValue}&summary=${summary}&title=${title}&type=${type}&subtype=video_library${startDate? "&startDate="+startDate : ""}${endDate? "&endDate="+endDate : ""}`;
    }
    else endpoint = `&offset=${offset}&resultLimit=${limit}&defaultValue=${defaultValue}&summary=${summary}&title=${title}&type=${type}${startDate? "&startDate="+startDate : ""}${endDate? "&endDate="+endDate : ""}`;
  } else {
    if (type === "Video-Library") {
      endpoint = `&offset=${offset}&resultLimit=${limit}&type=${type}&subtype=video_library${startDate? "&startDate="+startDate : ""}${endDate? "&endDate="+endDate : ""}`;
    }
    else endpoint = `&offset=${offset}&resultLimit=${limit}&type=${type}${tag? "&tags="+tag : ""}${startDate? "&startDate="+startDate : ""}${endDate? "&endDate="+endDate : ""}`;
  }

  console.log(
    encodeURI(
      `${env.serverUrl}/ekh-rest-api/global/search/simple?contentLanguage=${locale}${endpoint}`
    )
  );

  return new Promise((resolve, reject) => {
    axios({
      url: encodeURI(
        `${env.serverUrl}/ekh-rest-api/global/search/simple?contentLanguage=${locale}${endpoint}`
      ),
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
      cancelToken: token
    })
      .then((response) => {
        resolve(treatItems(response.data, type, "single"));
      })
      .catch((error) => {
        console.log("Search Request Error");
        console.log(error);
        reject();
      });
  });
}

function multiTypeSearch(name, type, offset, limit, locale, token, startDate, endDate, tag) {
  let endpoint, title, summary;

  console.log("multi tag", tag);
  if(name.charAt(0) === '#'){
    name=null
  }
  if (name) {
    title = name;
    summary = name;
    /*if (type.includes("Program")) {
      console.log("name2222 - Program");
      endpoint = `&offset=${offset}&resultLimit=${limit}&summary=${summary}&title=${title}${tag? "&tags="+tag : ""}&programName=${name}&type=${type}${startDate? "&startDate="+startDate : ""}${endDate? "&endDate="+endDate : ""}`
    } */
    if (type.includes("Video-Library")) {
      endpoint = `&offset=${offset}&resultLimit=${limit}&summary=${summary}&title=${title}${tag? "&tags="+tag : ""}&type=${type}&subtype=video_library${startDate? "&startDate="+startDate : ""}${endDate? "&endDate="+endDate : ""}`;
    } 
    else {
      endpoint = `&offset=${offset}&resultLimit=${limit}&summary=${summary}&title=${title}${tag? "&tags="+tag : ""}&type=${type}${startDate? "&startDate="+startDate : ""}${endDate? "&endDate="+endDate : ""}`;
    }
  } else {
    
    if (type.includes("Video-Library")) {
      endpoint = `&offset=${offset}&resultLimit=${limit}${tag? "&tags="+tag : ""}&type=${type}&subtype=video_library${startDate? "&startDate="+startDate : ""}${endDate? "&endDate="+endDate : ""}`;
      
    } 
    else endpoint = `&offset=${offset}&resultLimit=${limit}${tag? "&tags="+tag : ""}&type=${type}${startDate? "&startDate="+startDate : ""}${endDate? "&endDate="+endDate : ""}`;
  }

  console.log(
    encodeURI(
      `${env.serverUrl}/ekh-rest-api/global/search?contentLanguage=${locale}${endpoint}`
    )
  );

  return new Promise((resolve, reject) => {
    axios({
      url: encodeURI(
        `${env.serverUrl}/ekh-rest-api/global/search?contentLanguage=${locale}${endpoint}`
      ),
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
      cancelToken: token
    })
      .then((response) => {
        resolve(treatItems(response.data, type, "multi"));
      })
      .catch((error) => {

        console.log("Search Request Error");
        console.log(error);
        reject();
      });
  });
}

function treatItems(data, itemType, searchType) {

  let items = [];
  let new_item = {};

  data.items.forEach((item) => {
    new_item = item.type === "*" ? buildProgramObject(item, searchType) : buildObject(item);
    items.push(new_item);
  });

  if (itemType === "Program") return { items: items }
  else return { hasMore: data.hasMore, items: items }
}

function buildObject(contentItem) {

  return {
    id: contentItem.id ? contentItem.id : 0,
    type: contentItem.type ? contentItem.type : "No Type",
    category:
      contentItem.taxonomies && Object.keys(contentItem.taxonomies).length > 0
        ? Object.keys(contentItem.taxonomies)[0]
        : "No Category",
    title:
      contentItem.fields && contentItem.fields.title
        ? contentItem.fields.title
        : "",
    summary:
      contentItem.fields && contentItem.fields.summary
        ? contentItem.fields.summary
        : "",
    image_alignment:
      contentItem.fields && contentItem.fields.image_alignment
        ? contentItem.fields.image_alignment
        : "",
    cover_image:
      contentItem.fields &&
        contentItem.fields.cover_image
        ? contentItem.fields.cover_image
        : null
  };
}

function buildProgramObject(item, searchType) {

  if (searchType === "single") {
    return {
      id: item && item.Id ? item.Id : 0,
      type: "Program",
      category: "No Category",
      title: item.Title ? item.Title : "No title",
      summary: item.Description ? item.Description : "",
      cover_image: item.Picture1 ? item.Picture1 : "https://images.unsplash.com/photo-1612461313144-fc1676a1bf17?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1291&q=80"
    }
  }
  else {
    return {
      id: item && item.additionalProperties && item.additionalProperties.Id ? item.additionalProperties.Id : 0,
      type: "Program",
      category: "No Category",
      title: item.additionalProperties && item.additionalProperties.Title ? item.additionalProperties.Title : "No title",
      summary: item.additionalProperties && item.additionalProperties.Description ? item.additionalProperties.Description : "",
      cover_image: item.additionalProperties && item.additionalProperties.Picture1 ? item.additionalProperties.Picture1 : "https://images.unsplash.com/photo-1612461313144-fc1676a1bf17?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1291&q=80"
    }
  }

}

/**
 * Get the Breaking News.
 *
 * @returns {*} - Breaking News
 */
 export function fetchAbout(locale) {
  return new Promise((resolve, reject) => {
    axios({
      url: `${env.serverUrl}/ekh-rest-api/generic/about/About-EKH?contentLanguage=${locale}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log("About Request Error");
        console.log(error);
        reject();
      });
  });
}