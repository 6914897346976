import React, { useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { setCookieConsent } from "../../store/actions/info";
import {sendcookieConsent} from "../../scripts/analyticsEvent";
import Modal from "react-bootstrap/Modal";

const CookieConsent = () => {
    const dispatch = useDispatch();
    const cookieConsent = useSelector((state) => state.info.cookieConsent.consent);
    const [show, setShow] = useState(true);
    const [hiddenManagedCookies, setHiddenManagedCookies] = useState(false);
    const [analyticsConsent, setAnalyticsConsent] = useState(true);

    const handleManageCookies = () => {
        setHiddenManagedCookies(!hiddenManagedCookies);
    }
    const handleConsent = () => {
        //dispatch();
        setCookieConsent(analyticsConsent);
        localStorage.setItem("cookieConsent", JSON.stringify({ consent:analyticsConsent }));

        sendcookieConsent();
        handleClose();
    }

    const handleAnalyticsConsent = () => {
		setAnalyticsConsent(!analyticsConsent);
	};

    const handleClose = () => setShow(false);

    return (
        <Modal show={show} className={hiddenManagedCookies ? "cookieModal active" : "cookieModal"} ariaLabelledby="exampleModalLabel" > 
        	    <Modal.Body>
                    <p>نحن نستخدم ملفات تعريف الارتباط لتحسين تجربتك ولتحليل استخدام التطبيق لدينا.  أنت توافق على ملفات تعريف الارتباط الخاصة بنا إذا نقرت على "موافق"</p>
                </Modal.Body>
                <Modal.Footer className="justify-content-between">
                    <div className={hiddenManagedCookies ? "form-check mr-4 d-flex align-items-start " : "form-check mr-4 d-flex align-items-start hiddenManagedCookies"} >
                        <div className="analytics_info mr-5">
                            <input className="form-check-input disabled" type="checkbox" value="" id="flexCheckChecked" checked/>
                            <p className="d-inline-block mr-5 mb-0">ضروري</p>
                            <p className="mb-0 small">إلزامي.  تمكن الوظائف الأساسية مثل تتبع التنقل بين الصفحات والموافقة على ملفات تعريف الارتباط.</p>
                        </div>
                        
                        <div className="analytics_info mt-3 mt-md-0">
                            <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked2" onChange={handleAnalyticsConsent} checked={analyticsConsent === true}/>
                            <label className="form-check-label" for="flexCheckChecked2" checked>
                            تحليلات
                            </label>
                            <p className="mb-0 small">يساعدنا ملف تعريف الارتباط هذا على فهم مستخدمينا بشكل أفضل وتوفير تجربة مستخدم أفضل عند استخدام تطبيقنا.</p>
                        </div>
					</div>
                    <div className="d-block d-md-flex">
                        {!hiddenManagedCookies ?
                        <button type="button" className="btn btn-secondary align-self-end ml-4 mb-0 mt-0" onClick={handleManageCookies}>إدارة ملفات تعريف الارتباط</button>
                        : null}
                    
                        <button type="button" className="btn btn-primary mb-0 align-self-end" onClick={handleConsent}>{hiddenManagedCookies ? "حفظ" : "موافق"}</button>
                    </div>
                    
                </Modal.Footer>
        </Modal>
    )
}

export default CookieConsent
