import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { withTranslation } from 'react-i18next';

import VideoPlayer from '../Utils/VideoPlayer';
import { fetchEpisodeDetail } from '../../store/actions/programs';
import { getDateFromEpisode, getTotalMinutes } from '../../scripts/utils';

const AllProgramEpisodeCard = ({ startTime, programName, isLive, duration, props }) => {

  /**
   * TODO:
   * fazer a correpondencia do isAlive com os valores que veem da api
   */

  const { t } = props;

  const locale = useSelector((state) => state.info.locale);
  const [videoURL, setVideoURL] = useState();

  let currentProgramCSS = "";

  if (isLive === "UPCOMING") {
    currentProgramCSS = "epg_card d-flex align-items-center";
  }
  else if (isLive === "PAST") {
    currentProgramCSS = "epg_card disabled d-flex align-items-center";
  }

  else {currentProgramCSS = "epg_card active d-flex align-items-center";
}


  return (
    <div class={currentProgramCSS}>
      {isLive ==="LIVE" ? <h4 >{t("programs.liveNow")}</h4> : null}
      <dl
        style={{ paddingRight: locale === "en-US" ? 15 : "", paddingLeft:locale === "en-US" ? "" : 15}}
        class="time">
        <dt style={{ textTransform: "uppercase" }}>{startTime.slice(0,-3)}</dt>
        <dd style={{ color: "#808080" }}>{duration.slice(0,-2) + " " + t("programs.minutes")}</dd>
      </dl>
      <dl class="info">
        <dt>{programName}</dt>
      </dl>
      {/* <a href="" title="" class="ml-auto stretched-link">view previous episodes</a> */}
    </div>
  )
}

export default withTranslation()(AllProgramEpisodeCard);
