import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import no_image from '../../../assets/no-image.jpg';
import env from "../../../config/env";
import { useDispatch, useSelector } from "react-redux";

const ShareMetaData = (props) => {

  let title = props.title;
  let description = props.summary;
  let currentUrl = props.url;
  let hashtag = props.hashtag;
  let image = props.image;
  const locale = useSelector((state) => state.info.locale);
  /*  let location = useLocation();
   let currentUrl = `${env.clientSide}/news/${newsId}`;
   let quote = "";
   let image = no_image;
   let description = summary;
   let hashtag = tag; */

  return (

    <Helmet htmlAttributes={{
      lang: locale === "arSA" ? 'ar' : 'en',
    }}
    >
      <title>{title}</title>
      <meta charset="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="GC=chrome" />
      <meta name="csrf_token" content="" />
      <meta property="url" content={currentUrl} />
      <meta property="og:image:alt" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:hashtag" content={hashtag} />
      <meta property="og:image:secure_url" content={image} />
      <meta content="image/*" property="og:image:type" />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:site_name" content="Al Ekhbariya" />
      <meta property="og:description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image:src" content={image} />

      {/* <meta charset="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="GC=chrome" />
      <meta name="csrf_token" content="" />
      <meta property="type" content="website" />
      <meta property="url" content={currentUrl} />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="_token" content="" />
      <meta name="robots" content="noodp" />
      <meta property="title" content={title} />
      <meta property="quote" content={quote} />
      <meta name="description" content={description} />
      <meta property="image" content={image} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:quote" content={quote} />
      <meta property="og:hashtag" content={hashtag} />
      <meta property="og:image" content={image} />
      <meta content="image/*" property="og:image:type" />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:site_name" content="CampersTribe" />
      <meta property="og:description" content={description} /> 
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:card" content={description} /> */}

    </Helmet>
  );
}

export default ShareMetaData;