import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { TwitterTweetEmbed } from "react-twitter-embed";

import RelatedNews from "../components/News/RelatedNews";
import Share from "../components/Utils/SocialMediaShare/Share";
import MostReadNews from "../components/NewsCategory/MostReadNews";
import { sendPageView, sendNewsView } from "../components/Utils/GATracker";
import sendAnalyticsEvent from "../scripts/analyticsEvent";

import { fetchNewsDetail, newsView } from "../store/actions/news";
import { getDateFromNow } from "../scripts/utils";
import colorToCSSClassMapper from "../config/categoryColor";
import OurContent from "../components/Utils/OurContent";
/* import { Modal, ModalGateway } from "react-images"; */
import { Modal } from 'react-responsive-modal';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel as ReactCarousel } from 'react-responsive-carousel';
import { Modal as ReactModal, CarouselItem } from "react-bootstrap";


const NewsDetailPage = (props) => {
  const { t } = props;

  const { newsId } = useParams();

  const locale = useSelector((state) => state.info.locale);
  const cookieConsent = useSelector((state) => state.info.cookieConsent.consent);
  const theme = useSelector((state) => state.info.theme);
  const viewCounts = useSelector((state) => state.homepage.showMostReadViews);

  const dispatch = useDispatch();
  const history = useHistory();

  const [newsData, setNewsData] = useState(null);
  const [category, setCategory] = useState("");
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [publishedDate, setPublishedDate] = useState("");
  const [author, setAutor] = useState("");
  const [newsContentGroups, setNewsContentGroups] = useState([]);
  const [tag, setTag] = useState("");
  const [image, setImage] = useState("");

  const [categoryCssClass, setCategoryCssClass] = useState(null);
  const [categoryColor, setCategoryColor] = useState(null);

  const categoryInfo = useSelector((state) => state.categories.categoriesList);
  const [categoryIndex, setCategoryIndex] = useState();

  const [modalOpen, setModalOpen] = useState(false);
  const [currentGroup, setCurrentGroup] = useState(false);

  useEffect(() => {
    sendPageView("News Detail");
    sendNewsView("News Detail", newsId);
  }, [newsId]);

  useEffect(() => {
    newsView(newsId, locale);
  }, [newsId, locale]);

  useEffect(() => {
    async function fetchAllContent() {
      await fetchNewsDetail(newsId, locale)
        .then((searchList) => {
          sendAnalyticsEvent({cookieConsent: cookieConsent,type:"viewed",section:"news detail",page:`news detail ${searchList.items[0]?.fields ? searchList.items[0]?.fields?.title : ""}`, locale: locale, value:newsId});
          setNewsData(searchList.items[0] || newsData);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    fetchAllContent();
  }, [newsId]);

  useEffect(() => {
    setCategory(
      newsData && newsData.taxonomies
        ? Object.keys(newsData.taxonomies)[0]
        : null
    );
    setTitle(newsData && newsData.fields ? newsData.fields.title : null);
    setSummary(newsData && newsData.fields ? newsData.fields.summary : null);
    setPublishedDate(
      newsData &&
        newsData.fields &&
        newsData.fields.authoring_date &&
        newsData.fields.authoring_date.value
        ? newsData.fields.authoring_date.value
        : newsData?.createdDate?.value
    );
    setAutor(newsData && newsData.fields ? newsData.fields.author : null);
    setNewsContentGroups(
      newsData && newsData.fields ? Object.values(newsData.fields.groups) : null
    );
    setTag(
      newsData && newsData.fields && newsData.fields.tags_list
        ? newsData.fields.tags_list
        : null
    );
    setImage(
      newsData && newsData.fields && newsData.fields.cover_image
        ? newsData.fields.cover_image.directURL
        : null
    );
  }, [newsData]);

  useEffect(() => {
    let currentColor;
    //category && console.log("category", category[0]);

    for (let key in categoryInfo) {
      let obj = categoryInfo[key];
      if (category && obj.category_name === category) {
        currentColor = obj.color;
        setCategoryIndex(key);
        setCategoryColor(currentColor);
        setCategoryCssClass(colorToCSSClassMapper[currentColor]);
        document.body.classList.add(colorToCSSClassMapper[currentColor]);
        //document.body.classList.add("inner_content");
        break;
      }
    }
    document.body.classList.add("inner_content");

    return function cleanUp() {
      document.body.classList.remove(colorToCSSClassMapper[currentColor]);
      document.body.classList.remove("inner_content");
    };
  }, [category, categoryInfo]);

  function useIsMount() {
    const isMountRef = useRef(locale);
    useEffect(() => {
      isMountRef.current = locale;
    }, []);
    return isMountRef.current;
  }

  const isMount = useIsMount();

  useEffect(() => {
    if (isMount === locale) {
    } else if (categoryInfo) {
      history.push(`/`);
    }
  }, [categoryInfo]);

  const sliderRef = useRef();
  const settings = {
    dots: false,
    speed: 500,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <button className="slick-prev" onClick={previous}></button>,
    prevArrow: <button className="slick-next" onClick={next}></button>
  };

  function next() {
    sliderRef.current.slickNext();
  }
  function previous() {
    sliderRef.current.slickPrev();
  }

  const handleModal = (idx) => {
    setModalOpen(!modalOpen);
    setCurrentGroup(idx);
  };

  const ContentSwitch = React.memo(({ group, groupId }) => {
    // !groupId it's the first content
    // groupId = 0 it's the second content

    if (group && group.field_type) {
      switch (group.field_type) {
        case "Text":
          let htmlText = "";

          if (group.text.includes("&nbsp;")) {
            htmlText = group.text.replace(/\&nbsp;/g, " ");
          } else {
            htmlText = group.text;
          }
          return (
            <div
              style={{ color: theme === "light" ? "black" : "white" }}
              className={groupId === 0 ? "mb-5" : !groupId ? "" : " mb-5 mt-5"}
              dangerouslySetInnerHTML={{ __html: htmlText }}
            />
          );

        case "Video":
          return (
            <video
              style={{
                marginTop: groupId === 0 || !groupId ? "0" : "30px",
                marginBottom: !groupId ? "0" : "30px"
              }}
              controls
              class="content_video"
              poster={group.cover_image}
            >
              <source src={group.video} type="video/mp4" />
            </video>
          );
        case "Image":
          return (
            <div
              className={
                groupId === 0
                  ? "container p-0 images_carousel mb-5"
                  : !groupId
                  ? "container p-0 images_carousel"
                  : "container p-0 images_carousel mb-5 mt-5"
              }
            >
              <div id="hero-carousel">
                <Slider ref={sliderRef} {...settings}>
                  {group.image && group.image.map((image, index) => (
                    <figure>
                    <div className={"card_news mb-0 detail_pics"}>
                      {/*                       <a title="">
                        <img key={index} src={image} alt={t('home.news')} />
                      </a> */}
                      {/* Open Modal Image */}
                      {/* <a title="">  */}
                      <img style={{ objectFit: "contain", transform: "none" }} onClick={() => handleModal(group)} key={index} src={image} alt={t('home.news')} />
                      {/* </a> */}
                    </div>
                    <figcaption className="figure-caption">{group.caption && group.caption[index]}</figcaption>
                    </figure>
                  ))
                  }
                </Slider>
              </div>
            </div>
          );
        case "Twitter link":
          let tweetID = group.twitterURL.split("/").slice(-1).toString();
          let re = new RegExp("\r\n|\r|\n");

          return (
            <div
              className={groupId === 0 ? "mb-5" : !groupId ? "" : " mb-5 mt-5"}
            >
              <TwitterTweetEmbed
                tweetId={
                  re.test(group.twitterURL)
                    ? tweetID.slice(0, tweetID.length - 1)
                    : tweetID
                }
              />
            </div>
          );

        case "Twitter embed":
          let embededTwitter = group.twitterURL.replace("async", "");

          return (
            <div
              className={
                groupId === 0
                  ? "d-flex justify-content-center mb-5"
                  : !groupId
                  ? "d-flex justify-content-center"
                  : "d-flex justify-content-center mb-5 mt-5"
              }
              dangerouslySetInnerHTML={{ __html: embededTwitter }}
            />
          );

        case "Youtube":
          let videoPath = "";

          if (group.youtubeURL.startsWith("https://youtu.be")) {
            let sharedStr = group.youtubeURL.split("https://youtu.be")[1];

            if (sharedStr.includes("?")) {
              videoPath = sharedStr.split("?")[0];
            } else {
              videoPath = sharedStr;
            }
            console.log(videoPath);
          } else {
            let sharedStr = group.youtubeURL.split("watch?v=")[1];

            if (sharedStr.includes("&")) {
              videoPath = "/" + sharedStr.split("&")[0];
            } else {
              videoPath = "/" + sharedStr;
            }
          }

          let link = "https://www.youtube.com/" + "embed" + videoPath;

          return (
            <div
              className={
                groupId === 0
                  ? "d-flex justify-content-center mb-5"
                  : !groupId
                  ? "videoWrapper"
                  : "d-flex justify-content-center mb-5 mt-5"
              }
            >
              <iframe
                width="560"
                height="315"
                src={link}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          );
        default:
          return null;
      }
    } else {
      return null;
    }
  });

  return (
    <div>
      <div class="container featured light_theme inner_content">
        <div class="row">
          <div class="col-12 col-md-8 d-flex justify-content-start mb-4">
            <h2
              class={`align-self-end ${
                categoryCssClass ? categoryCssClass : "cat_11"
              } category`}
            >
              {category ? category : t("newsDetailPage.noCategory")}
            </h2>
            <Link
              onClick={() => {
                category
                  ? dispatch({
                      type: "CHANGED_PATH",
                      path: `/${
                        locale === "arSA" ? "ar" : "en"
                      }/category/${category}`
                    })
                  : dispatch({
                      type: "CHANGED_PATH",
                      path: `/`
                    });

                    sendAnalyticsEvent({cookieConsent: cookieConsent ,type:"click", label:`more ${category} news`, page:"news detail"})

              }}
              to={{
                pathname: category
                  ? `/${locale === "arSA" ? "ar" : "en"}/category/${category}`
                  : `/`
              }}
            >
              <a
                href="#"
                class={`view_all align-self-end ${
                  categoryCssClass ? categoryCssClass : ""
                }`}
              >
                {category
                  ? `${
                      locale === "arSA"
                        ? `${t("newsDetailPage.moreNews")} ${category}`
                        : `more ${category} news`
                    }`
                  : t("newsDetailPage.moreNews")}
              </a>
            </Link>
          </div>
        </div>
        <div class="row border-bottom pb-4 mb-3">
          <div class="col-12">
            <h1 class="no_cat" style={{ height: "auto" }}>
              {title}
            </h1>
            {summary && (
              <h4
                class="no_cat"
                style={{
                  color: theme === "light" ? "black" : "white",
                  marginTop: "auto"
                }}
              >
                {summary}
              </h4>
            )}
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-12 col-md-6 d-flex justify-content-start">
            <p class="dot_nine">
              <strong>{t("newsDetailPage.published")}</strong>
              {"  " + getDateFromNow(publishedDate, locale)}
            </p>
            {author && (
              <p class="dot_nine ml-2">
                <strong
                  style={{
                    paddingRight: 8,
                    paddingLeft: locale === "arSA" ? 8 : ""
                  }}
                >
                  {t("newsDetailPage.by")}
                </strong>
                {author}
              </p>
            )}
          </div>
           {/*<div class="col-12 col-md-6">
            <ul class="social_share">
              <div class="d-flex justify-content-end align-items-end h-100">
                <Share
                  Id={newsId}
                  Title={title}
                  Summary={summary}
                  Tag={tag}
                  type={"News"}
                  image={image}
                />
              </div>
            </ul>
                </div>*/}
        </div>

        <div className="row">
          <div className="col-12">
            <ContentSwitch
              group={newsContentGroups ? newsContentGroups[0] : null}
            />
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-12 col-md-8 news_content">
            {newsContentGroups &&
              newsContentGroups
                .slice(1, newsContentGroups.length)
                .map((group, index) => (
                  <ContentSwitch key={index} groupId={index} group={group} />
                ))}
          </div>
          <div className="col-4 d-none d-md-block col_most_read">
            <MostReadNews isViewCountsEnabled={viewCounts} newsticker={true} analyticsInfo={{page:"news detail", section: "most read news"}}/>
            {tag && <RelatedNews tags={tag} newsId={newsId} analyticsInfo={{page:"news detail", section: "most read news"}}/>}
          </div>
        </div>
        <div class="container featured light_theme">
          <div class="row mt-5">
            <div class="col-12">
              <h2
                class="our_content"
                style={{ paddingRight: locale === "arSA" ? "0px" : "68px" }}
              >
                {t("generic.moreContent")}
              </h2>
            </div>
          </div>
        </div>
      </div>
      <OurContent />
      <Share
                  Id={newsId}
                  Title={title}
                  Summary={summary}
                  Tag={tag}
                  type={"News"}
                  image={image}
                  analyticsInfo={{page:"news detail", section: "share"}}
      />
      {modalOpen ?
        <ReactModal
          id="photosModal"
          dialogClassName="content_modal"
          show={modalOpen}
          scrollable={false}
        >
          <div className="content_modal">
           <div className="close_content_modal">
                        <button
                          type="button"
                          className="close"
                          onClick={()=>setModalOpen(false)}
                          data-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
            <div style={{ direction: "initial" }}>
                <div className="modal-content carousel slide" style={{ overflowY: "hidden" }}>
          <div className="carousel_content_wrapper d-flex justify-content-center carousel_single">
                  <ReactCarousel showThumbs={false} showIndicators={false} showStatus={false} >
                    {currentGroup && currentGroup.image.map((image) => (
                      <div>
                        <img src={image} alt="" />
                      </div>))}
                  </ReactCarousel>
                  </div>
                  </div>
                </div>
            </div>
        </ReactModal>
        : null}
    </div>
  );
};

export default withTranslation()(NewsDetailPage);
