import React from "react"
import ContentLoader from "react-content-loader"
import { useSelector } from "react-redux";

const MyLoader = (props) => {

  const locale = useSelector((state) => state.info.locale);

  return (

    <ContentLoader
      rtl={locale === "arSA" ? true : false}
      speed={2}
      width={450}
      height={160}
      viewBox="0 0 450 160"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="23" y="90" rx="3" ry="3" width="214" height="11" />
      <rect x="23" y="111" rx="3" ry="3" width="212" height="12" />
      <rect x="23" y="19" rx="3" ry="3" width="85" height="11" />
      <rect x="269" y="11" rx="0" ry="0" width="133" height="133" />
    </ContentLoader>
  )
}

export default MyLoader

