import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import { withTranslation } from "react-i18next";

import RelatedNews from "../components/News/RelatedNews";
import MostReadNews from "../components/NewsCategory/MostReadNews";
import Share from "../components/Utils/SocialMediaShare/Share";
import NewsTickerCard from "../components/News/NewsTickerCard";
import ShareMetaData from '../components/Utils/SocialMediaShare/ShareMetaData';
import { sendPageView } from "../components/Utils/GATracker";
import NewsTickerLoader from "../components/Utils/Loaders/NewsTickerLoader";
import { fetchAllNewsTickers } from "../store/actions/news";
import sendAnalyticsEvent from "../scripts/analyticsEvent";


const NewsTickerListPage = (props) => {

  const { t } = props;

  const locale = useSelector((state) => state.info.locale);
  const cookieConsent = useSelector((state) => state.info.cookieConsent.consent);
  const [newsTickers, setNewsTickers] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(true);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    sendPageView("News Ticker");
    sendAnalyticsEvent({type:"viewed",page:"News Tickers", locale: locale, cookieConsent: cookieConsent});

  },[]);

  useEffect(() => {

    setLoading(true);
    
    async function fetchNewsTickersData() {
      await fetchAllNewsTickers(8, offset, locale)
        .then((newsList) => {
        if (newsTickers !== null && newsTickers.length > 0) {
          newsList.items.map((item) => {
            setNewsTickers((prevResults) => [
              ...new Set([...prevResults, item])
            ]);
          });
        } else {
          setNewsTickers(newsList.items);
        }
        setHasMore(newsList.hasMore);
        setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    fetchNewsTickersData();
  }, [locale, offset]);

  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      // o node ee o ultimo elem se nao e o node queremos dar disconnect
      if (observer.current) observer.current.disconnect();

      // entries vai corresponder a tudo que se vai tornar visivel depois do scroll
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setOffset((prevValue) => prevValue + 8);
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  return (
    <div class="container featured light_theme inner_content">
      <ShareMetaData title={t('newsTickerComp.newsTicker')}></ShareMetaData>
      <div class="row border-bottom pb-4 mb-3">
        <div class="col-12 col-md-6 d-flex justify-content-start">
          <h1 class="text-uppercase no_cat">{t('newsTickerComp.newsTicker')}</h1>
        </div>
        <div class="col-12 col-md-6">
          <div class="d-flex justify-content-end align-items-end h-100">
            <ul class="social_share">
              <Share Title={t('newsTickerComp.newsTicker')} type={"News-Ticker"} />
            </ul>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-12 col-md-8 tickers">
          {newsTickers &&
            newsTickers.map((card, index) => {
              if (newsTickers.length === index + 1) {
              return (
                <div
                  ref={lastElementRef}
                >
              <NewsTickerCard key={index} type="ticker" cardinfo={card} />
              </div>
              );
            } else {
              return (
              <div>
              <NewsTickerCard key={index} type="ticker" cardinfo={card} />
              </div>
            );
            }
          })}
        </div>
        
        <div className="col-4 d-none d-md-block col_most_read">
          <MostReadNews isViewCountsEnabled={false} newsticker={true} />
        </div>
        {loading && <NewsTickerLoader class="col-12 col-md-8 tickers" />}
      </div>
    </div>
  );
};

export default withTranslation()(NewsTickerListPage);
