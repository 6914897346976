import env from '../../config/env';
import axios from 'axios';

export function fetchSocialMedia(type, numberOfTweets = 5, language) {

  if (type === 'Twitter') return fetchAllSocialMedia('&socialMedia=Twitter', numberOfTweets, language);

  else if (type === 'Instagram') return fetchAllSocialMedia('&socialMedia=Instagram', '', language);

  else return fetchAllSocialMedia('&socialMedia=Twitter&socialMedia=Instagram', numberOfTweets, language);

}


/**
 * Get a limit number of tweets
 * 
 * @param {Integer} endpoint - social media filter type
 * @param {Integer} numberOfTweets - The limit
 * @returns {*} - a max of tweets given by the limit
 */

function fetchAllSocialMedia(endpoint, numberOfTweets, language) {
  return new Promise((resolve, reject) => {
    axios({
      url: `${env.serverUrl}/ekh-rest-api/social_media/get?accountLanguage=${language}&numberOfTweets=${numberOfTweets}${endpoint}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log('Social Media Request Error');
        console.log(error);
        reject();
      });
  });
}




