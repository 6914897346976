import React from 'react';
import { useSelector } from "react-redux";

import { dateToMDY, getTime } from '../../scripts/utils';
import sendAnalyticsEvent from "../../scripts/analyticsEvent";


const SocialMediaCard = (props) => {

  const { created_date, description, mediaPreviews, social_media, user_icon_url, username, url } = props.cardInfo;

  const cookieConsent = useSelector((state) => state.info.cookieConsent.consent);


  let date = dateToMDY(created_date);
  let time = getTime(created_date);
  let cardTime = time + ' · ' + date;

  return (
    <a href={url ? url : null} style={{ textDecoration: 'none' }} 
    onClick={() => sendAnalyticsEvent({cookieConsent: cookieConsent ,type:"click", label:`share`, section:props.analyticsInfo?.section, page:props.analyticsInfo?.page, value:url ? url : null})  }>
      <div className="card card_twitter">
        {mediaPreviews[0] &&
          <div className="img_video">
            <img src={mediaPreviews[0]} alt="instagram photo" />
          </div>
        }
        {social_media === 'Twitter' ? <p>{description}</p> : null}
        <div className="info d-flex">
          <div className="avatar">
            <img src={user_icon_url} alt="Twitter avatar" />
          </div>
          <dl className="flex-grow-1 ml-2">
            <dt>{username}</dt>
            <dd>{cardTime}</dd>
          </dl>
        </div>
      </div>
    </a>
  )

}

export default SocialMediaCard;