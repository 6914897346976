import React from "react";

const SpinnerLoader = ({ backgroundColor }) => {

  return (
    <div className="d-flex justify-content-center align-items-center w-100 h-100">
      <div
        class="spinner-border d-flex justify-content-center align-items-center"
        role="status"
        style={{color: backgroundColor === 'white' ? "black" : "white"}}
      >
      </div>
    </div>
  );
};

export default SpinnerLoader;
