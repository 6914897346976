import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { withTranslation } from 'react-i18next';
import CopyToClipboard from "react-copy-to-clipboard";
import { FacebookShareButton, FacebookIcon, WhatsappShareButton, WhatsappIcon, TwitterShareButton, TwitterIcon } from "react-share";
import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

import env from "../../../config/env";
import ShareMetaData from './ShareMetaData';
import { InlineReactionButtons } from 'sharethis-reactjs';
import { InlineShareButtons } from 'sharethis-reactjs';
import { StickyShareButtons } from 'sharethis-reactjs';
import { InlineFollowButtons } from 'sharethis-reactjs';
import useMobileDetect from "use-mobile-detect-hook";
import sendAnalyticsEvent from "../../../scripts/analyticsEvent";


import axios from "axios";


const Share = (props) => {

  const { t } = props;

  const locale = useSelector((state) => state.info.locale);
  const cookieConsent = useSelector((state) => state.info.cookieConsent.consent);

  const [expanded, setExpanded] = useState(false);
  const detectMobile = useMobileDetect();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (detectMobile.isMobile()) setIsMobile(true);
    
  }, [detectMobile]);


  let { Id, Title, Summary, Tag, type, image } = props;
  let url
  let className

  if (type === "Video-Podcast") {
    Tag = "";
    url = `${env.clientSide}/${locale === "arSA" ? "ar" : "en"}/Video-Podcast/Episode/${Id}`
  }
  else if (type === "Audio-Podcast") {
    url = `${env.clientSide}/${locale === "arSA" ? "ar" : "en"}/podcasts/Audio-Podcast/${Id}`
  }
  else if (type === "Video-Library") {
    //url = `${env.clientSide}/${locale === "arSA" ? "ar" : "en"}/Video-Library/${Id}`
    url = `${env.clientSide}/videolink/${Id}`
  }
  else if (type === "News-Ticker") {
    url = `${env.clientSide}/${locale === "arSA" ? "ar" : "en"}/newsticker`
  }
  else if (type === "Program-Video") {
    url = `${env.clientSide}/${locale === "arSA" ? "ar" : "en"}/programVideo/${Id}`
  }
  else {
    url = `${env.clientSide}/newslink/${Id}`;
    className = "icon-bar"
  }

  const downloadFile = () => {
    window.location.href = props.videoUrl;
    //sendAnalyticsEvent({cookieConsent: cookieConsent ,type:"click", label:`download`, section:props.analyticsInfo?.section, page:props.analyticsInfo?.page, value: Title})
  }

  return (
    <div class={className}>
    
      {isMobile && <button
        className="button_open"
        role="button"
        aria-expanded={expanded ? "true" : "false"}
        aria-controls="collapseExample"
        onClick={(e) => {
          setExpanded(!expanded);
        }}
        >
      </button>}

    <div className={expanded ? 'social_media_expanded' : ''}>
    {/* <ShareMetaData title ={Title} summary={Summary} tag = {Tag}></ShareMetaData> */}
    <ShareMetaData hashtag={Tag} url={url} title={Title} summary={Summary} image={image}></ShareMetaData>
    <ul>
      <CopyToClipboard text={url}>
        <li class="link"
          onClick={() => {
            store.addNotification({
              message: t('socialMediaComp.copiedLink'),
              type: 'success',                         // 'default', 'success', 'info', 'warning'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 1000
              }
            });
            setExpanded(!expanded);
            sendAnalyticsEvent({cookieConsent: cookieConsent ,type:"click", label:`share`, section:props.analyticsInfo?.section, page:props.analyticsInfo?.page, value: Title})
          }}
        ><a href="#" title="">{t('socialMediaComp.copyLink')}</a>
        </li></CopyToClipboard>

      <li class="whatsapp">
        <WhatsappShareButton class="whatsapp"
          url={url} onShareWindowClose={() => {
            setExpanded(!expanded);
          }} onClick={(e) =>sendAnalyticsEvent({cookieConsent: cookieConsent ,type:"click", label:`whatsapp share`, section:props.analyticsInfo?.section, page:props.analyticsInfo?.page, value: Title})
        }>
          <a href="#" title="">whatsapp</a>
        </WhatsappShareButton>
      </li>
      <li class="twitter">
        <TwitterShareButton class="twitter"
          url={url} onShareWindowClose={() => {
            setExpanded(!expanded);
          }} onClick={(e) =>sendAnalyticsEvent({cookieConsent: cookieConsent ,type:"click", label:`twitter share`, section:props.analyticsInfo?.section, page:props.analyticsInfo?.page, value: Title})
        }>
          <a href="#" title="">twitter</a>
        </TwitterShareButton>
      </li>
      <li class="facebook">
        <FacebookShareButton
          url={url} onShareWindowClose={() => {
            setExpanded(!expanded);
          }} onClick={(e) =>sendAnalyticsEvent({cookieConsent: cookieConsent ,type:"click", label:`facebook share`, section:props.analyticsInfo?.section, page:props.analyticsInfo?.page, value: Title})
        }>
            
          <a href="#" title="">facebook</a>
        </FacebookShareButton>
      </li>
      {type === "Video-Library" ?
        <li class="download">
          <a href="#" onClick={downloadFile}>Download</a>
        </li>
        : null}
        </ul>
    </div>
  </div>
  );
}

export default withTranslation()(Share);