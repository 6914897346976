import React, { useState } from 'react';
import { useSelector } from "react-redux";

import { getDateFromNow } from '../../scripts/utils';


const NewsTickerCard = (props) => {

  const locale = useSelector((state) => state.info.locale);

  const [createdDate, setCreatedDate] = useState(props.cardinfo &&
    props.cardinfo.fields &&
    props.cardinfo.fields.authoring_date &&
    props.cardinfo.fields.authoring_date.value ? props.cardinfo.fields.authoring_date.value : props.cardinfo?.createdDate?.value);

  const [summary, setSummary] = useState(props.cardinfo &&
    props.cardinfo.fields &&
    props.cardinfo.fields.summary ? props.cardinfo.fields.summary : null)


  return (
    createdDate && summary ?
      <dl>
        <dt>{props?.cardinfo?.fields?.summary}</dt>
        <dd>{getDateFromNow(props?.cardinfo?.fields?.authoring_date?.value ? props?.cardinfo?.fields?.authoring_date?.value : props?.cardinfo?.createdDate?.value, locale)}</dd>
      </dl>
      : null
  )

}

export default NewsTickerCard;
