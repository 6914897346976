import React from 'react';
import no_results from "../assets/img/no_results.svg"
import no_results_dark from "../assets/img/no_results_dark.svg";
import { useSelector } from "react-redux";

/**
 * Functional Component for the Not Found Screen
 */
const EmptyResultsPage = () => {

  const theme = useSelector((state) => state.info.theme);

  return (
  <div class="imgbox">
    <img class="center-fit" src={theme==="light" ? no_results : no_results_dark} alt="No results"></img>
  </div>
)

};

export default EmptyResultsPage;