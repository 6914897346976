import React, { useEffect, useState, useRef } from "react";
import { withTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProgressBar from 'react-bootstrap/ProgressBar';

import VideoCard from "../Podcasts/VideoCard";

/**
 * TODO:
 * adaptar para o resto dos sliders do ekh
 */

const CustomSlider = ({ settings, data, loading, type }) => {

  const sliderRef = useRef();

  const [indexSlide, setIndexSlide] = useState(0);
  const [loaded, setLoaded] = useState(0);

  useEffect(() => {
    setLoaded(100 / (data && data.length - 1) * (indexSlide));
  }, [indexSlide, data]);

  const next = () => {
    sliderRef.current.slickNext();
  }
  const previous = () => {
    sliderRef.current.slickPrev();
  }

  const sliderSettings = {
    ...settings,
    nextArrow: <button className="slick-prev" onClick={previous}></button>,
    prevArrow: <button className="slick-next" onClick={next}></button>
  }

  const afterChangeHandler = (currentSlide) => {
    setIndexSlide(currentSlide);
  }

  // console.log(sliderRef.current)
  // console.log("loaded", loaded)
  // console.log("indexSlide", indexSlide)

  return (
    <>
      <Slider ref={sliderRef} {...sliderSettings} afterChange={afterChangeHandler}>
        {data.map((cardnew, index) => {
          return (
            <VideoCard
              key={index}
              type="card"
              icon="video_icon"
              cardSize="card_news three_row_news"
              cardinfo={cardnew}
              video={cardnew?.fields?.video?.directURL}
              image={cardnew?.fields?.cover_image?.directURL}
              imageClass={cardnew?.fields?.image_alignment}
              createdDate={
                cardnew?.fields?.authoring_date?.value
              }
              summary={cardnew?.description}
            />
          );
        })}
      </Slider>
      <ProgressBar now={loaded} />
    </>
  )
}

export default withTranslation()(CustomSlider)