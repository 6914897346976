import React from 'react';
import LayoutSwitch from './LayoutSwitch';

const DynamicLayout = React.memo(({ props, catList }) => {

  return (
    <div>
      {catList ? catList.slice(1, catList.length).map((category, index) => (
        <LayoutSwitch
          props={props}
          key={index}
          color={category.color}
          name={category.category_name}
          layout={category.display_layout}
          hasChildren={category.hasChildren}
          childrenList={category.childCategories.length === 0 ? null : category.childCategories}
          analyticsInfo={{page:"home", section: "news category"}}
          logo={category.logo}
        />
      ))
        : null}
    </div>
  )
});

export default DynamicLayout;