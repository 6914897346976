import React from "react";
import { useSelector } from "react-redux";
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import sendAnalyticsEvent from "../../scripts/analyticsEvent";



import { getDateFromNow } from "../../scripts/utils";


const NewsCard = (props) => {

  const { t } = props;

  const locale = useSelector((state) => state.info.locale);
  const cookieConsent = useSelector((state) => state.info.cookieConsent.consent);


  const widthVar = "1rem";
  const cardStyle = {
    "--width": widthVar,
    "color": "white"
  }

  return props.id ? (
    props.type === "ticker" ?
      <div className={props.cardSize}>
        <dl  className={props.icon} style={cardStyle}>
          {props.summary ? <dt style={{fontWeight: "600"}}>{props.summary}</dt> : null}
          {props.createdDate ? <dd>{getDateFromNow(props.createdDate, locale)}</dd> : null}
        </dl>
        {props.image && !props.loadingHighlights && (
          <a href="#" title="">
            <img className={props.imageClass} src={props.image} alt={t('home.news')} />
          </a>
        )}
        {props.loadingHighlights && (
          <div className="d-flex justify-content-center align-items-center w-100 h-100">
            <div
              class="spinner-border d-flex justify-content-center align-items-center"
              role="status"
            >
              <span class="sr-only">{t('search.loading')}</span>
            </div>
          </div>
        )}
      </div>
      :
      <div className={props.cardSize}>

        <dl className={props.icon} style={cardStyle}>
        <dd className="tags">
          {props.tagList && 
          props.tagList.map((tag) => (
            <Link
              to={{ pathname: `/${locale === "arSA" ? "ar" : "en"}/ekhsearch`,search: `${"?tag="+tag}`}}
              title=""
              className=""
              onClick={(e) => {
                sendAnalyticsEvent({cookieConsent: cookieConsent ,type:"click", label:`tag`, section:props.analyticsInfo?.section, page:props.analyticsInfo?.page, value:tag})
              }}
            >{tag}
              </Link>
              
            
          ))}
          </dd>
          {props.createdDate ? <dd>{getDateFromNow(props.createdDate, locale)}</dd> : null}
          {props.summary ? <dt>{props.summary}</dt> : null}
        </dl>

        {props.image && !props.loadingHighlights && (
          <Link
            to={`/${locale === "arSA" ? "ar" : "en"}/news/${props.id}`} title="" 
            onClick={(e) =>sendAnalyticsEvent({cookieConsent: cookieConsent ,type:"click", label:`image`, section: props.analyticsInfo?.section, page: props.analyticsInfo?.page, value:props.id})
          }>
            <img className={props.imageClass} src={props.image} alt={t('home.news')} />
          </Link>
        )}
        {props.loadingHighlights && (
          <div className="d-flex justify-content-center align-items-center w-100 h-100">
            <div
              class="spinner-border d-flex justify-content-center align-items-center"
              role="status"
            >
              <span class="sr-only">{t('search.loading')}</span>
            </div>
          </div>
        )}
      </div>
  ) : null;
};

export default withTranslation()(NewsCard);
