import env from "../../config/env";
import axios from "axios";

import {
  CATEGORIES_LIST_REQUEST,
  CATEGORIES_LIST_SUCESS,
  CATEGORIES_LIST_FAIL,
  HIGHLIGHTS_LIST_REQUEST,
  HIGHLIGHTS_LIST_SUCESS,
  HIGHLIGHTS_LIST_FAIL,
  NEWSTICKER_LIST_REQUEST,
  NEWSTICKER_LIST_SUCESS,
  NEWSTICKER_LIST_FAIL,
  HOMEPAGE_REQUEST,
  HOMEPAGE_SUCESS,
  HOMEPAGE_FAIL
} from "../constants/newsConstants";

/**
 * Get details of the specified news.
 *
 * @param {String} newsId - The id of the news
 * @returns {*} - the news detail
 */
export function fetchNewsDetail(newsId, locale) {
  return new Promise((resolve, reject) => {
    axios({ 
      url: `${env.serverUrl}/ekh-rest-api/news/${newsId}?contentLanguage=${locale}`, 
      method: "GET", 
      headers: { "Content-Type": "application/json" } 
    })
    .then((response) => { 
      resolve(response.data); 
    })
    .then(
      axios.spread(function (response1, response2) { 
        resolve(response1.data); console.log("news", response1.data); 
      }))
      .catch((error) => { 
        console.log("News Detail Request Error"); 
        console.log(error); reject(); 
      });
  });
}



export function newsView(newsId, locale) {
  return new Promise((resolve, reject) => {
    axios({
      url: `${env.serverUrl}/ekh-rest-api/news/views/${newsId ? newsId : ""
        }?contentLanguage=${locale}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((response) => {
        resolve(response.data);
      })

      .then(
        axios.spread(function (response1, response2) {
          resolve(response1.data);
          console.log("news", response1.data);
        })
      )
      .catch((error) => {
        console.log("News View Request Error");
        console.log(error);
        reject();
      });
  });
}

function objToQueryString(obj) {
  const keyValuePairs = [];
  for (let i = 0; i < Object.keys(obj).length; i += 1) {
    keyValuePairs.push(`category=${Object.values(obj)[i]}`);
  }
  return keyValuePairs.join("&");
}

/**
 * Get all the news from a category.
 *
 * @param {String} category - The category of the news
 * @returns {*} - all the news from category
 */
export function fetchNewsByCategory(
  category,
  children,
  limit,
  offset,
  name,
  locale,
  token,
  startDate, 
  endDate
) {
  let endpoint = "";

  if (
    category === "Custom Block 1" ||
    category === "Custom Block 2" ||
    category === "Custom Block 3" ||
    category === "Custom Block 4" ||
    category === "Custom Block 5" ||
    category === "Custom Block 6"
  ) {
    endpoint = `category=World&contentLanguage=${locale}&offset=${offset}${startDate? "&startDate="+startDate : ""}${endDate? "&endDate="+endDate : ""}&resultLimit=${limit}${name !== "" ? `&summary=${name}&title=${name}` : ""
      }`;
  } else {
    let categories = children !== null ? "&" + objToQueryString(children) : [];
    endpoint = `category=${category}${children !== null && children.length > 0 ? categories : ""
      }&contentLanguage=${locale}&offset=${offset}${startDate? "&startDate="+startDate : ""}${endDate? "&endDate="+endDate : ""}&resultLimit=${limit}${name !== "" ? `&summary=${name}&title=${name}` : ""
      }`;
  }

  return new Promise((resolve, reject) => {
    if (category.length === 0) {
      reject();
    } else {
      axios({
        url: encodeURI(
          `${env.serverUrl}/ekh-rest-api/news/categories?${endpoint}`
        ),
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        },
        cancelToken: token
      })
        .then((response) => {
          resolve(response.data.items.length === 0 ? null : response.data);
        })
        .catch((error) => {
          console.log("Category News Request Error");
          console.log(error);
          reject();
        });
    }
  });
}

/**
 * Get all the news from a tag.
 *
 * @param {String} tag - The tag of the news
 * @returns {*} - all the news from tag
 */
export function fetchNewsByTag(tag, limit, locale) {
  let endpoint = "";

  if (limit === "") {
    endpoint = `&tags=${tag}`;
  } else {
    endpoint = `&resultLimit=${limit}&tags=${tag}`;
  }
  return new Promise((resolve, reject) => {
    axios({
      url: encodeURI(
        `${env.serverUrl}/ekh-rest-api/news/search?contentLanguage=${locale}${endpoint}`
      ),
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((response) => {
        resolve(response.data.items.length === 0 ? null : response.data);
      })
      .catch((error) => {
        console.log("Tag Request Error");
        console.log(error);
        reject();
      });
  });
}

/**
 * Get all the news tickers with a limit number of results.
 *
 * @returns {*} - all the news tickers
 */

export function fetchAllNewsTickers(limit, offset, locale) {
  let endpoint = "";

  if (limit !== "" && offset !== "") {
    endpoint = `&offset=${offset}&resultLimit=${limit}`;
  }

  return new Promise((resolve, reject) => {
    axios({
      url: `${env.serverUrl}/ekh-rest-api/news/getNewsTickerResults?contentLanguage=${locale}${endpoint}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log("News Ticker Request Error");
        console.log(error);
        reject();
      });
  });
}

export const fetchAllNewstickerThunk = (limit, locale) => async (dispatch) => {
  try {
    dispatch({ type: NEWSTICKER_LIST_REQUEST });
    const { data } = await axios.get(
      `${env.serverUrl}/ekh-rest-api/news/getNewsTickerResults?contentLanguage=${locale}&resultLimit=${limit}`,
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    dispatch({
      type: NEWSTICKER_LIST_SUCESS,
      payload: data.items
    });
  } catch (error) {
    dispatch({
      type: NEWSTICKER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

/**
 * Get all the news from a category.
 *
 * @returns {*} - homepage content
 */

export function fetchHomepageContent(locale) {
  return new Promise((resolve, reject) => {
    axios({
      url: encodeURI(
        `${env.serverUrl}/ekh-rest-api/homepage/search?contentLanguage=${locale}`
      ),
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log("Homepage Request Error");
        console.log(error);
        reject();
      });
  });
}

/**
 * Get all the news from a category.
 *
 * @returns {*} - homepage preview content
 */

export function fetchHomepagePreviewContent(id, locale) {
  return new Promise((resolve, reject) => {
    axios({
      url: encodeURI(
        `${env.serverUrl}/ekh-rest-api/security/homepage/preview/${id}`
      ),
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((response) => {
        response.data.items.map((elem, index) => {
          if (elem.id === id) {
            resolve(response.data.items[index]);
          }
        });
      })
      .catch((error) => {
        console.log("Homepage Preview Request Error");
        console.log(error);
        reject();
      });
  });
}

export const fetchAllCategoriesThunk =
  (locale, isPreview, id) => async (dispatch) => {
    let endpoint = "";

    if (isPreview) {
      endpoint = `security/homepage/preview/${id}`;
    } else {
      endpoint = `homepage/search?contentLanguage=${locale}`;
    }

    try {
      dispatch({ type: CATEGORIES_LIST_REQUEST });
      dispatch({ type: HOMEPAGE_REQUEST });
      const { data } = await axios.get(
        `${env.serverUrl}/ekh-rest-api/${endpoint}`,
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      dispatch({
        type: CATEGORIES_LIST_SUCESS,
        payload: data.items[0].fields.groups
      });
      dispatch({
        type: HOMEPAGE_SUCESS,
        payload: data.items[0].fields.show_the_view_counts_of_the_most_read_news
      });
    } catch (error) {
      dispatch({
        type: CATEGORIES_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      });
      dispatch({
        type: HOMEPAGE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      });
    }
  };

/**
 * Get the Breaking News.
 *
 * @returns {*} - Breaking News
 */
export function fetchBreakingNews(locale) {
  return new Promise((resolve, reject) => {
    axios({
      url: `${env.serverUrl}/ekh-rest-api/homepage/breakingnews?contentLanguage=${locale}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log("Breaking News Request Error");
        console.log(error);
        reject();
      });
  });
}

/**
 * Get all the highlights with a limit number of results
 *
 * @returns {*} - all the highlights news
 */

export const fetchAllHighlightsThunk = (limit, locale) => async (dispatch) => {
  try {
    dispatch({ type: HIGHLIGHTS_LIST_REQUEST });
    const { data } = await axios.get(
      `${env.serverUrl}/ekh-rest-api/news/highlights?contentLanguage=${locale}&resultLimit=${limit}`,
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    dispatch({
      type: HIGHLIGHTS_LIST_SUCESS,
      payload: data.items
    });
  } catch (error) {
    dispatch({
      type: HIGHLIGHTS_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

/**
 * Get the most read news.
 *
 * @returns {*} - all the news
 */
export function fetchMostReadNews(locale, limit, offset, token, timeframe) {
  let endpoint = "";

  if (offset !== "" && limit !== "") {
    endpoint = `&offset=${offset}&resultLimit=${limit}`;
  } else if (limit !== "" && offset === "") {
    endpoint = `&resultLimit=${limit}`;
  }

  return new Promise((resolve, reject) => {
    axios({
      url: `${env.serverUrl}/ekh-rest-api/news/top?contentLanguage=${locale}${endpoint}&timeframe=${timeframe ? timeframe : ""}`,
      method: "GET",

      headers: {
        "Content-Type": "application/json"
      },
      cancelToken: token
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject();
      });
  });
}

export function fetchSubcategories(name, limit = "8") {
  console.log(
    `${env.serverUrl}/ekh-rest-api/global/searchSubcategoryByID?categoryName=${name}&channelToken=${env.channelToken}&resultLimit=${limit}`
  );
  return new Promise((resolve, reject) => {
    axios({
      url: `${env.serverUrl}/ekh-rest-api/global/searchSubcategoryByID?categoryName=${name}&channelToken=${env.channelToken}&resultLimit=${limit}`,
      method: "GET",

      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject();
      });
  });
}
