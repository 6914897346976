import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import SocialMediaCard from './SocialMediaCard';
import SpinnerLoader from "../Utils/Loaders/SpinnerLoader";
import { fetchSocialMedia } from '../../store/actions/socialmedia';


import { withTranslation } from 'react-i18next';

const SocialMedia = React.memo((props) => {

  const { t } = props;
  

  const locale = useSelector(state => state.info.locale);

  const filterCSS = "social_links ml-4";

  /*COMENTADO POR EXISTIR SÓ O TWITTER*/

  const filterList = [
    /*  { name: t('generic.viewAll'), type: "Twitter,Instagram", css: filterCSS }, */
    /*  { name: t('socialMediaComp.twitter'), type: "Twitter", css: filterCSS }, */
    /*  { name: t('socialMediaComp.instagram'), type: "Instagram", css: filterCSS } */
  ];

  const [socialMedia, setSocialMedia] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFiltered, setIsFiltered] = useState(false);
  const [tempList, setTempList] = useState(filterList);
  const [loading, setLoading] = useState(true);

  /*COMENTADO POR EXISTIR SÓ O TWITTER*/

  /*   useEffect(() => {
      filterList[0].css = "social_links active ml-4";
    }, [locale]) */

  /*  useEffect(() => {
     setSocialMedia([]);
 
     if (isFiltered) {
       filterList[currentIndex].css = "social_links active ml-4";
       setTempList(filterList);
     }
   }, [currentIndex, isFiltered]);
  */
  useEffect(() => {

    setLoading(true);

    async function fetchSocialMediaData(type) {

      await fetchSocialMedia(type, 8, locale)
        .then(socialMediaList => {
          if (socialMediaList.length !== 0) {
            setSocialMedia(socialMediaList || []);
            setLoading(false);
          }
        })
        .catch(error => {
          console.log(error);
        })
    }

    /*  fetchSocialMediaData(tempList[currentIndex].type); */
    fetchSocialMediaData("twitter"); /* NO CASO DE EXISTIR SÓ TWITTER */

  }, [currentIndex, locale]);

  const handleFilter = (key) => {
    setIsFiltered(true);
    setCurrentIndex(key);
    setLoading(true);
  };

  return (
    socialMedia ?
      <div>
        <div className="row mt-5">
          <div className="col-12 mt-3 social_feed">
            <h2 className="cat_11 float-left mr-5 mb-2 mb-md-0">{t('socialMediaComp.twitter')}</h2>
            <div className="float-left">
              {filterList.map((filter, index) => (
                <a style={{ cursor: "pointer" }}
                  key={index}
                  onClick={() => handleFilter(index)}
                  className={tempList[index].css}>
                  {filter.name}
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="row mt-3 h-100">
          {loading ? <SpinnerLoader backgroundColor="white" />
            :
            socialMedia.map((socialCardInfo, index) => (
              <div key={index} className="col-12 col-md-6 col-xl-3 mb-4">
                <SocialMediaCard cardInfo={socialCardInfo} analyticsInfo={{page:"home", section: "social media"}}/>
              </div>
            ))
          }
        </div>
      </div>
      : null
  )

});

export default withTranslation()(SocialMedia);