import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Nav, NavbarToggler, NavItem } from "reactstrap";
import Collapse from "react-bootstrap/Collapse";
import Navigation from "react-sticky-nav";
import env from "../../config/env";
import national_day_cat_icon_old from "../../assets/img/national_day_cat_icon.png";
import national_day_cat_icon from "../../assets/img/national_day_cat_icon_big_Medium.jpg";

import { setLanguage, setTheme } from "../../store/actions/info";
import sendAnalyticsEvent from "../../scripts/analyticsEvent";


const NavigationComponent = (props) => {

  const { t } = props;

  const dispatch = useDispatch();

  const locale = useSelector(state => state.info.locale);
  const cookieConsent = useSelector((state) => state.info.cookieConsent.consent);
  const categoryListRdx = useSelector((state) => state.categories.categoriesList);
  const programs = useSelector((state) => state.programs);
  const loading = useSelector((state) => state.categories.loading);
  const theme = useSelector((state) => state.info.theme);

  const [categoryList, setCategoryList] = useState(props.list);
  const [collapseMore, setCollapseMore] = useState(false);
  const [collpasePrograms, setCollapsePrograms] = useState(false);
  const [collapseLanguage, setCollapseLanguage] = useState(false);
  const [toggleOpen, setToggleOpen] = useState(false);
  const [active, setActive] = useState("");
  const [navigationCategory, setNavegationCategory] = useState(null);
  const [moreCategory, setMoreCategory] = useState(null);
  const [specialCategory, setSpecialCategory] = useState(null);
  const [specialCategoryLogo, setSpecialCategoryLogo] = useState(null);

  const programsList = [];

  let navCat = [];
  let moreCat = [];
  let specialCat = [];
  let specialCatLogo = [];

  useEffect(() => {
    Object.keys(categoryListRdx).map(function (s) {
      if (categoryListRdx[s].special_event_checkbox === "true") {
        
        specialCat.push(categoryListRdx[s].category_name)
        //specialCatLogo.push(categoryListRdx[s].logo)
        var obj = {};
        //obj[categoryListRdx[s].category_name] = categoryListRdx[s].logo;
        specialCatLogo.push(categoryListRdx[s].logo);
      }
      else if (
        categoryListRdx[s].navigation_menu_checkbox === "true") {
        navCat.push(categoryListRdx[s].category_name)
      }
      else moreCat.push(categoryListRdx[s].category_name)
    })
    setNavegationCategory(navCat.slice(0, 4 - specialCat.length));
    setMoreCategory(navCat.slice(4 - specialCat.length, navCat.length).concat(moreCat))
    setSpecialCategory(specialCat);
    setSpecialCategoryLogo(specialCatLogo);
  }, [categoryListRdx]);

  useEffect(() => {
    setCollapseMore(false);
    setCollapsePrograms(false);
    setActive(window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1))
  }, [window.location.pathname]);

  // console.log("active", active);


  const changeDark = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (value) {
      dispatch(setTheme("dark"));
      localStorage.setItem("theme", "dark");
      sendAnalyticsEvent({section: "header", cookieConsent: cookieConsent,type:"click",value:"dark", label: "theme"});

    } else {
      dispatch(setTheme("light"));
      localStorage.setItem("theme", "light");
      sendAnalyticsEvent({section: "header", cookieConsent: cookieConsent,type:"click",value:"light", label: "theme"});

    }
  };

  const handleLanguageChange = (language) => {
    if (language === "arSA") {
      dispatch(setLanguage("arSA"))
    }
    else {
      dispatch(setLanguage("enUS"))
    }
    setCollapseMore(!collapseMore);
    setCollapseLanguage(!collapseLanguage);
    setToggleOpen(!toggleOpen);
  }

  const handleBurguerButton = () => {
    setToggleOpen(!toggleOpen)
    setCollapseMore(false);
    setCollapsePrograms(false);
    setCollapseLanguage(false);
  }

  // console.log(active)
  return (
    <Navigation className="navbar navbar-expand-md navbar-sticky bg-fixed-white navbar-fixed-light navbar-dark bg-dark">
      <div className="container-fluid">
        <div className="w-100">
          <NavbarToggler
            onClick={() => handleBurguerButton()}
            className={toggleOpen ? "navbar-toggler" : "navbar-toggler collapsed"}>
            <i className="fal fa-bars"></i>
          </NavbarToggler>
          <Collapse in={toggleOpen} className={toggleOpen ? "navbar-toggler" : "navbar-toggler collapsed"}>
            <Nav className="navbar-nav me-auto mb-2 mb-md-0">
              {specialCategory && specialCategory.length > 0 ?
              specialCategory.map((specialCateg, i) => {
                return (
                <NavItem key={i} className="nav-item">
                  <Link className={encodeURI(specialCateg) === active ? "nav-link special icon active" : "nav-link icon special"}
                    onClick={() => {
                      dispatch({
                        type: "CHANGED_PATH",
                        path: `/${locale === "arSA" ? "ar" : "en"}/category/${specialCateg}`
                      })
                      setToggleOpen(false);
                      setActive(-1);
                    }}
                    to={{
                      pathname: `/${locale === "arSA" ? "ar" : "en"}/category/${specialCateg}`
                    }}
                  ><img src={national_day_cat_icon} alt="National day icon"/>
                    {specialCateg}
                  </Link>
                </NavItem>
                  );
                }) : ""}
              {navigationCategory
                ? navigationCategory.map((category, index) => {
                  return (
                    <NavItem key={index} className="nav-item">
                      <Link  className={encodeURI(category) === active ? "nav-link active" : "nav-link"}
                        onClick={() => {
                          dispatch({
                            type: "CHANGED_PATH",
                            path: `/${locale === "arSA" ? "ar" : "en"}/category/${category}`
                          })
                          //setActive(category)
                          setToggleOpen(false);
                        }}
                        to={{
                          pathname: `/${locale === "arSA" ? "ar" : "en"}/category/${category}`
                        }}
                      >
                        {category}
                      </Link>
                    </NavItem>
                  );
                })
                : ""}
              {!loading && <NavItem className="nav-item">
                <Link className={active === "Video-Library" ? "nav-link active" : "nav-link"}
                  onClick={() => {
                    dispatch({
                      type: "CHANGED_PATH",
                      path: `/${locale === "arSA" ? "ar" : "en"}/Video-Library`
                    })
                    //setActive(category)
                    setToggleOpen(false);
                  }}
                  to={{
                    pathname: `/${locale === "arSA" ? "ar" : "en"}/Video-Library`
                  }}
                >
                  {t("videoLibrary")}
                </Link>
              </NavItem>}
              {!loading && env.showPrograms && <NavItem
                onClick={() => dispatch({
                  type: "CHANGED_PATH",
                  path: `/programs`
                })
                }
                to={{ pathname: `/programs` }}
              >
                <a
                  className="nav-link"
                  data-toggle="collapse"
                  onClick={() => { setCollapsePrograms(!collpasePrograms); setCollapseMore(false); setActive(-1) }}
                  role="button"
                  aria-expanded={collpasePrograms}
                  aria-controls="collapsePrograms"
                >
                  {t("header.programs")}
                </a>
              </NavItem>}
              {!loading && <NavItem>
                <a
                  className="nav-link"
                  onClick={() => { setCollapseMore(!collapseMore); setCollapsePrograms(false); setActive(-1) }}
                  aria-expanded={collapseMore}
                  aria-controls="collapseMore"
                >
                  {t("header.more")}
                </a>
              </NavItem>}
            </Nav>
          </Collapse>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav me-auto mb-2 mb-md-0">
            {specialCategory && specialCategory.length > 0 ?
              specialCategory.map((specialCateg, i) => {
                return (
                <NavItem key={i} className="nav-item">
                  <Link className={encodeURI(specialCateg) === active ? "nav-link special icon active" : "nav-link icon special"}
                    onClick={() => {
                      dispatch({
                        type: "CHANGED_PATH",
                        path: `/${locale === "arSA" ? "ar" : "en"}/category/${specialCateg}`
                      })
                      setToggleOpen(false);
                      setActive(-1);
                      sendAnalyticsEvent({section: "header", cookieConsent: cookieConsent,type:"click", label: "navigation menu special", value: specialCateg})
                    }}
                    to={{
                      pathname: `/${locale === "arSA" ? "ar" : "en"}/category/${specialCateg}`
                    }}
                  ><img src={national_day_cat_icon} alt="National day icon"/>
                    {specialCateg}
                  </Link>
                </NavItem>
                  );
                }) : ""}
              {navigationCategory
                ? navigationCategory.map((category, index) => {
                  return (
                    <li key={index} className="nav-item">
                      <Link  className={encodeURI(category) === active ? "nav-link active" : "nav-link"}
                        onClick={() => {
                          setToggleOpen(false);
                          setCollapseMore(false);
                          //setActive(category)
                          dispatch({
                            type: "CHANGED_PATH",
                            path: `/${locale === "arSA" ? "ar" : "en"}/category/${category}`
                          })
                          sendAnalyticsEvent({section: "header", cookieConsent: cookieConsent,type:"click", label: "navigation menu", value: category})
                        }}
                        to={{
                          pathname: `/${locale === "arSA" ? "ar" : "en"}/category/${category}`
                        }
                        }
                      >
                        {category}
                      </Link>
                    </li>
                  );
                })
                : ""}
              {!loading && <li className="nav-item">
                <Link className={active === "Video-Library" ? "nav-link active" : "nav-link"}
                  onClick={() => {
                    dispatch({
                      type: "CHANGED_PATH",
                      path: `/${locale === "arSA" ? "ar" : "en"}/Video-Library`
                    })
                    //setActive(category)
                    setToggleOpen(false);
                    sendAnalyticsEvent({section: "header", cookieConsent: cookieConsent,type:"click", label: "navigation menu", value: "video library"})
                  }}
                  to={{
                    pathname: `/${locale === "arSA" ? "ar" : "en"}/Video-Library`
                  }}
                >
                  {t("videoLibrary")}
                </Link>
              </li>}
              {!loading && env.showPrograms && <NavItem
                onClick={() =>{
                  dispatch({
                    type: "CHANGED_PATH",
                    path: `/${locale === "arSA" ? "ar" : "en"}/programs`
                  })
                  sendAnalyticsEvent({section: "header", cookieConsent: cookieConsent,type:"click", label: "navigation menu", value: "programs"})

                } 
                }
                to={{ pathname: `/${locale === "arSA" ? "ar" : "en"}/programs` }}
              >
                <a
                  className="nav-link"
                  data-toggle="collapse"
                  onClick={() => { setCollapsePrograms(!collpasePrograms); setCollapseMore(false); setActive(-1) }}
                  role="button"
                  aria-expanded={collpasePrograms}
                  aria-controls="collapsePrograms"
                >
                  {t("header.programs")}
                </a>
              </NavItem>}
              {!loading && <NavItem>
                <a
                  className="nav-link"
                  onClick={() => { setCollapseMore(!collapseMore); setCollapsePrograms(false); setActive(-1); 
                                  sendAnalyticsEvent({section: "header", cookieConsent: cookieConsent,type:"click", label: "navigation menu", value: "more"})
                }}
                  aria-expanded={collapseMore}
                  aria-controls="collapseMore"
                >
                  {t("header.more")}
                </a>
              </NavItem>}
            </ul>
          </div>
        </div>

        {/* TOGGLER COLLAPSE ENDS*/}

        {/* PROGRAMS COLLAPSE */}

        <Collapse in={collpasePrograms}>
          <div
            className="w-70 mx-auto collapse border-top-sec-nav pt-4 pb-3"
            id="collapseMore"
          >
            <div className="row">
            {<Link className="col-6 col-md-3 mb-4 text-center"
                onClick={() => {
                  setToggleOpen(false);
                  setCollapsePrograms(!collpasePrograms);
                  dispatch({
                    type: "CHANGED_PATH",
                    path: `/${locale === "arSA" ? "ar" : "en"}/programs`
                  })
                  sendAnalyticsEvent({section: "header", cookieConsent: cookieConsent,type:"click", label: "navigation menu", value: "programs"})

                }
                }
                to={{ pathname: `/${locale === "arSA" ? "ar" : "en"}/programs` }}
              >
                <div>
                  <a href="#" title="" className="nav-link_secondary">
                    {t("programs.allProgramsWithP")}
                  </a>
                </div>
              </Link>}
              {!programs.loading ? programs.programsList.map((program) => {
                return (
                  <Link className="col-6 col-md-3 mb-4 text-center"
                    onClick={() => {
                      setToggleOpen(false);
                      setCollapsePrograms(!collpasePrograms);
                      dispatch({
                        type: "CHANGED_PATH",
                        path: `/${locale === "arSA" ? "ar" : "en"}/programs/${program.title}`
                      })
                      sendAnalyticsEvent({section: "header", cookieConsent: cookieConsent,type:"click", label: "navigation menu", value: program.title})

                    }
                    }
                    to={{ pathname: `/${locale === "arSA" ? "ar" : "en"}/programs/${program.title}` }}
                  >

                    <div>
                      <a href="#" title="" className="nav-link_secondary" >
                        {program.title}
                      </a>
                    </div>
                  </Link>
                )
              })
            : ""}
            </div>

          </div>
        </Collapse>

        {/* MORE COLLAPSE */}

        <Collapse in={collapseMore}>
          <div
            className="w-70 mx-auto collapse border-top-sec-nav pt-4 pb-3"
            id="collapseMore"
          >
            <div className="row">
              {/* CATEGORIES */}
              {moreCategory
                ? moreCategory.map((category) => {
                  return (
                    <Link className="col-6 col-md-3 mb-4 text-center"
                      onClick={() => {
                        setCollapseMore(false);
                        setToggleOpen(false);
                        dispatch({
                          type: "CHANGED_PATH",
                          path: `/${locale === "arSA" ? "ar" : "en"}/category/${category}`
                        })
                        sendAnalyticsEvent({section: "header", cookieConsent: cookieConsent,type:"click", label: "navigation menu", value: category})

                      }
                      }
                      to={{
                        pathname: `/${locale === "arSA" ? "ar" : "en"}/category/${category}`
                      }}
                    >

                      <div>
                        <a href="#" title="" className="nav-link_secondary" >
                          {category}
                        </a>
                      </div>
                    </Link>
                  );
                })
                : ""}
              {/* PODCASTS */}
              {/*<Link className="col-6 col-md-3 mb-4 text-center"
                onClick={() => {
                  setCollapseMore(false);
                  setToggleOpen(false);
                  dispatch({
                    type: "CHANGED_PATH",
                    path: `/${locale === "arSA" ? "ar" : "en"}/podcasts`
                  })
                }
                }
                to={{
                  pathname: `/${locale === "arSA" ? "ar" : "en"}/podcasts`
                }}
              >
                <div>
                  <a href="#" title="" className="nav-link_secondary" >
                    {t("podcasts.podcasts")}
                  </a>
                </div>
              </Link>*/}
              {/* VIDEO-LIBRARY */}
              {/* <div onClick={() => { setCollapseLanguage(!collapseLanguage) }} className="col-6 col-md-3 mb-4 text-center d-block d-md-none">
                <a className="nav-link_secondary"
                  data-toggle="collapse"
                  role="button"
                  aria-expanded={collapseLanguage}
                  aria-controls="collapseLanguage">{t("languageWord")}
                </a>
              </div> */}
              <div className="col-6 d-block d-md-none text-center">
                <div className="align-self-center toggle_wrapper">
                  <input
                    type="checkbox"
                    id="modeToggle"
                    checked={theme == "dark"}
                    onChange={changeDark}
                  />
                  <label htmlFor="modeToggle">
                    <div className="toggle">
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </Collapse>
        {/* <Collapse in={collapseLanguage}>

          <div className="w-70 mx-auto collapse border-top-sec-nav pt-4 pb-3" id="collapseLanguage">
            <div className="row">
              <div className="col-6 mb-4 text-center">
                <a onClick={() => handleLanguageChange("enUS")} href="#" title="" className="nav-link_secondary">English</a>
              </div>
              <div className="col-6 mb-4 text-center">
                <a onClick={() => handleLanguageChange("arSA")} href="#" title="" className="nav-link_secondary">عربي</a>
              </div>
            </div>
          </div>
        </Collapse> */}
      </div>
    </Navigation>
  );
};

export default withTranslation()(NavigationComponent);
