import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { withTranslation } from 'react-i18next';

import VideoPlayer from "../Utils/VideoPlayer"
import { getDateFromNow, convertDate } from "../../scripts/utils";
import sendAnalyticsEvent from "../../scripts/analyticsEvent";



const VideoCard = (props) => {

  const {
    image,
    createdDate,
    summary,
    icon,
    cardSize,
    // loadingVideos,
    video,
    cardinfo,
    imageClass,
    entryId
  } = props;

  const { t } = props;

  const locale = useSelector((state) => state.info.locale);
  const cookieConsent = useSelector((state) => state.info.cookieConsent.consent);


  const [open, setOpen] = useState(false);

  const widthVar = "3.5rem";
  const cardStyle = {
    "--width": widthVar,
    "color": "white"
  }

  const date = createdDate
    ? convertDate(createdDate)
    : "";

  const onOpenModal = () => {
    sendAnalyticsEvent({cookieConsent: cookieConsent ,type:"viewed", label:`open modal`, section:props.analyticsInfo?.section, page: "video detail " + cardinfo.fields.title, value:cardinfo.fields.title})

    setOpen(true);
  };

  const onCloseModal = () => {
    //sendAnalyticsEvent({cookieConsent: cookieConsent ,type:"click", label:`close modal`, section:props.analyticsInfo?.section, page:props.analyticsInfo?.page, value:cardinfo.fields.title})

    setOpen(false);
  };

  return (
    <>
      <div className={cardSize}>
        <dl className={icon} style={cardStyle}>
          {createdDate ? <dd>{getDateFromNow(createdDate, locale)}</dd> : null}
          {cardinfo.fields.title ? <dt>{cardinfo.fields.title}</dt> : null}
        </dl>
        <a style={{cursor: "pointer"}} onClick={onOpenModal} title="">
          <img className={imageClass} src={image ? image : `/assets/images/default.png`} alt={t('podcasts.video')} />
        </a>
        <React.Fragment>
          {open == true ?
            <VideoPlayer
              onClose={onCloseModal}
              open={open}
              episode={video}
              id={cardinfo.id}
              title={cardinfo.fields.title}
              summary={summary}
              date={date}
              type={"Video-Library"}
              props={props}
              entryId={entryId} /> : null}
        </React.Fragment>
      </div>
    </>
  )
};

export default withTranslation()(VideoCard);
