import {
  PROGRAMS_LIST_REQUEST,
  PROGRAMS_LIST_SUCESS,
  PROGRAMS_LIST_FAIL
} from "../constants/programsConstants";

export function listPrograms(state = { programsList: [] }, action) {
  switch (action.type) {
    case PROGRAMS_LIST_REQUEST:
      return {
        ...state,
        programsList: [],
        loading: true
      };
    case PROGRAMS_LIST_SUCESS:
      return {
        ...state,
        programsList: action.payload,
        loading: false
      };
    case PROGRAMS_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    default:
      return state;
  }
}
