import React from "react"
import ContentLoader from "react-content-loader"
import { useSelector } from "react-redux";

const MyLoader = (props) => {

    const locale = useSelector((state) => state.info.locale);

    return (

        <ContentLoader
            rtl={locale === "arSA" ? true : false}
            speed={2}
            width={730}
            height={160}
            viewBox="0 0 730 160"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
        >
            <rect x="0" y="11" rx="3" ry="3" width="513" height="8" />
            <rect x="0" y="32" rx="3" ry="3" width="468" height="8" />
            <rect x="0" y="55" rx="3" ry="3" width="125" height="8" />
        </ContentLoader>
    )
}

export default MyLoader