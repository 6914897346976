import React, { useEffect } from "react";
import GA4React from "ga-4-react";

export default function withTracker(MyComponent) {
  return (props) => {
    const { location, title, gaCode, categoryName, page, newsId } = props;

    useEffect(() => {
      switch (GA4React.isInitialized()) {
        case true:
          const ga4 = GA4React.getGA4React();
          if (ga4) {
            ga4.pageview(location, title);
            const obj = {};
            if (page) obj.page = page;
            if (newsId) obj.newsId = newsId;
            if (categoryName) obj.categoryName = categoryName;
            ga4.gtag("event", "custom_page_view", obj);
          }
          break;
        default:
        case false:
          const ga4react = new GA4React(gaCode);
          ga4react.initialize().then(
            (ga4) => {
              //ga4.pageview(path, location, title);
              const obj = {};
              if (page) obj.page = obj;
              if (newsId) obj.newsId = newsId;
              if (categoryName) obj.categoryName = categoryName;
              ga4.gtag("event", "page_view", obj);
            },
            (err) => {
              console.log("Error on ga4react.initialize() ;", err);
              console.error(err);
            }
          );
          break;
      }
    });
    return <MyComponent {...props} />;
  };
}

export const sendPageView = (path, location, title) => {
  if (GA4React.isInitialized()) {
    const ga4 = GA4React.getGA4React();
    if (ga4) {
      ga4.pageview(path, location, title);
    }
  }
};

export const sendCategoryView = (page, categoryName) => {
  if (GA4React.isInitialized()) {
    const ga4 = GA4React.getGA4React();
    if (ga4) {
      const obj = {};
      if (page) obj.page = page;
      if (categoryName) obj.categoryName = categoryName;

      ga4.gtag("event", "category_view", obj);
    }
  }
};

export const sendNewsView = (page, newsId) => {
  if (GA4React.isInitialized()) {
    const ga4 = GA4React.getGA4React();
    if (ga4) {
      const obj = {};
      if (page) obj.page = page;
      if (newsId) obj.newsId = newsId;

      ga4.gtag("event", "news_view", obj);
    }
  }
};

export const sendSearchView = (page, word) => {
  if (GA4React.isInitialized()) {
    const ga4 = GA4React.getGA4React();
    if (ga4) {
      const obj = {};
      if (page) obj.page = page;
      if (word) obj.word = word;

      ga4.gtag("event", "search_view", obj);
    }
  }
};
