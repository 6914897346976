export const CATEGORIES_LIST_REQUEST = "CATEGORIES_LIST_REQUEST";
export const CATEGORIES_LIST_SUCESS = "CATEGORIES_LIST_SUCESS";
export const CATEGORIES_LIST_FAIL = "CATEGORIES_LIST_FAIL";
export const HIGHLIGHTS_LIST_REQUEST = "HIGHLIGHTS_LIST_REQUEST";
export const HIGHLIGHTS_LIST_SUCESS = "HIGHLIGHTS_LIST_SUCESS";
export const HIGHLIGHTS_LIST_FAIL = "HIGHLIGHTS_LIST_FAIL";
export const NEWSTICKER_LIST_REQUEST = "NEWSTICKER_LIST_REQUEST";
export const NEWSTICKER_LIST_SUCESS = "NEWSTICKER_LIST_SUCESS";
export const NEWSTICKER_LIST_FAIL = "NEWSTICKER_LIST_FAIL";
export const HOMEPAGE_REQUEST = "HOMEPAGE_REQUEST";
export const HOMEPAGE_SUCESS = "HOMEPAGE_SUCESS";
export const HOMEPAGE_FAIL = "HOMEPAGE_LIST_FAIL";
