import React, { useState, useEffect } from 'react';
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useDispatch} from "react-redux";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import sendAnalyticsEvent from "../../scripts/analyticsEvent";

const Streaming = (props) => {
  const isMob = isMobile;
  const path = useSelector((state) => state.info.pathname);
  const cookieConsent = useSelector((state) => state.info.cookieConsent.consent);
  const streamRefr = useSelector((state) => state.info.streamRefresh);
  const [muted, setMuted] = useState("true");
  const [paused, setPaused] = useState("false");
  const history = useHistory();
  const dispatch = useDispatch();
  const [key, setKey] = useState(0)

  useEffect( () => {

  }, [muted, paused]);

  useEffect(() => {
    if(streamRefr) {
      setKey(key + 1);
      setPaused("false");
    }
    else if (!streamRefr) {
      setPaused("true")
    }
	}, [streamRefr])


  const toggleMuted = event => {
    var val = event.currentTarget.querySelector("input").value;
    setMuted(val);
  }

  const togglePaused = event => {
    var val = event.currentTarget.querySelector("input").value;

    setPaused(val);
  }

  const toggleMinimize = event => {
  dispatch({ type: "CHANGED_PATH", path: "/" });
  sendAnalyticsEvent({section: "stream", cookieConsent: cookieConsent,type:"click", label: "minimize"});

  history.push(`/`)
  }

  return (
    <div class="hero">
      <div className="video_controls">
        {paused === "true" ?
            <button
                className="playing"
                onClick={togglePaused}>
                  <input
                  type="radio"
                  style={{display: "none"}}
                  name="platform"
                  value="false"
                  autoComplete="off"
                  />
              </button>
              :
              <button
                className="paused"
                onClick={togglePaused}>
                  <input
                  type="radio"
                  style={{display: "none"}}
                  name="platform"
                  value="true"
                  autoComplete="off"
                  />
              </button>
        }
          {muted === "true" ?
            <button
                className="mute-video"
                onClick={toggleMuted}>
                  <input
                  type="radio"
                  style={{display: "none"}}
                  name="platform"
                  value="false"
                  autoComplete="off"
                  />
              </button>
              :
              <button
                className="sound_on"
                onClick={toggleMuted}>
                  <input
                  type="radio"
                  style={{display: "none"}}
                  name="platform"
                  value="true"
                  autoComplete="off"
                  />
              </button>
        }
        {window.location.pathname === "/streaming" ?
        <button className="minimize"
        onClick={toggleMinimize}
        >
        </button> : null}
        </div>

      <div className="overlay_video1" style = {{background: path ==="/streaming" ? "transparent" : ""}}>

          </div>
          <div className="overlay_video2" style = {{background: path ==="/streaming" ? "transparent" : ""}}>

          </div>
          <div className="overlay_video3" style = {{background: path ==="/streaming" ? "transparent" : ""}} >

          </div>
          <div className="video">
            {/*<div className=''>
                <ReactPlayer key={key} playsinline controls url='https://svs.itworkscdn.net/ekhbariyalive/ikhbariya.smil/playlist.m3u8' className='react-player' height="100%" autoPlay={true} playing={paused === "true" ? false: true} muted={muted === 'true' ? true : false} />
              </div>*/}
            <div className=''>
              <ReactPlayer url='https://youtu.be/9AK1Vu7E1_Q' className='react-player' height="100%" autoPlay={true} playing={paused === "true" ? false: true} muted={muted === 'true' ? true : false} />
            </div>
          </div>
    </div>
    );

}


export default Streaming;