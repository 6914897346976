import moment from "moment";
import 'moment/locale/ar';
import axios from "axios";

export function dateToMDY(date) {
  const dateObj = new Date(date);
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  const formattedDate = dateObj.toLocaleDateString('en-US', options);

  return formattedDate;
}

export function getTime(date) {

  const dateObj = new Date(date);

  let hours = dateObj.getHours();
  let minutes = dateObj.getMinutes();
  let ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? '0' + minutes : minutes;

  return hours + ':' + minutes + ' ' + ampm
}


export function setLocale(locale) {

  if (locale === 'arSA') {
    moment.locale('ar');
  }
  else moment.locale('en-US')
}

/* export function getDateFromNow(value, locale) {

  setLocale(locale)
  return moment(value).fromNow();
}; */

export function getDateFromNowPrograms(value, locale) {

  setLocale(locale)
  return moment.utc(value, 'DD/MM/YYYY').fromNow()
};

export function getDateFromEpisode(value, locale) {

  setLocale(locale);
  return moment.unix(value).utc().format('D MMMM YYYY');
}

export function getTotalMinutes(value, locale) {

  setLocale(locale);
  return moment.unix(value).utc().minutes();
}

export function convertDate(value, locale) {

  setLocale(locale);
  return moment(value).format('DD/MM/YYYY');
}

export function isFutureDate(value, locale) {

  setLocale(locale);
  return moment(value).isAfter();
  
}

export function getDateFromNow(value, locale) {
  setLocale(locale);
  let date;
  if (moment(value).isBefore()) {
    //console.info("moment:");
    //console.info(moment(value));
    //console.info(moment(value).hours());
    //moment(value).fromNow().isAfter(moment().add(1, 'days'));
    //if (moment(value).isAfter()) {
    const inputMoment = moment(value);
    const currentMoment = moment();

    const hoursDifference = currentMoment.diff(inputMoment, 'hours');
    if (hoursDifference > 24) {
      console.info("plus----");
      date = convertDate(value, locale)
    }
    else date = moment(value).fromNow();
  }
  else 
   date = moment().fromNow();

   return date;
}

export const guid = () => {
  let s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
  }
  //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4() + '-' + Date.now();
}

export const getBrowserName = () => {
  var nVer = navigator.appVersion;
var nAgt = navigator.userAgent;
var browserName  = navigator.appName;
var fullVersion  = ''+parseFloat(navigator.appVersion); 
var majorVersion = parseInt(navigator.appVersion,10);
var nameOffset,verOffset,ix;

// In Opera, the true version is after "Opera" or after "Version"
if ((verOffset=nAgt.indexOf("Opera"))!=-1) {
 browserName = "Opera";
 fullVersion = nAgt.substring(verOffset+6);
 if ((verOffset=nAgt.indexOf("Version"))!=-1) 
   fullVersion = nAgt.substring(verOffset+8);
}
// In MSIE, the true version is after "MSIE" in userAgent
else if ((verOffset=nAgt.indexOf("MSIE"))!=-1) {
 browserName = "Microsoft Internet Explorer";
 fullVersion = nAgt.substring(verOffset+5);
}
// In Chrome, the true version is after "Chrome" 
else if ((verOffset=nAgt.indexOf("Chrome"))!=-1) {
 browserName = "Chrome";
 fullVersion = nAgt.substring(verOffset+7);
}
// In Safari, the true version is after "Safari" or after "Version" 
else if ((verOffset=nAgt.indexOf("Safari"))!=-1) {
 browserName = "Safari";
 fullVersion = nAgt.substring(verOffset+7);
 if ((verOffset=nAgt.indexOf("Version"))!=-1) 
   fullVersion = nAgt.substring(verOffset+8);
}
// In Firefox, the true version is after "Firefox" 
else if ((verOffset=nAgt.indexOf("Firefox"))!=-1) {
 browserName = "Firefox";
 fullVersion = nAgt.substring(verOffset+8);
}
// In most other browsers, "name/version" is at the end of userAgent 
else if ( (nameOffset=nAgt.lastIndexOf(' ')+1) < 
          (verOffset=nAgt.lastIndexOf('/')) ) 
{
 browserName = nAgt.substring(nameOffset,verOffset);
 fullVersion = nAgt.substring(verOffset+1);
 if (browserName.toLowerCase()==browserName.toUpperCase()) {
  browserName = navigator.appName;
 }
}
// trim the fullVersion string at semicolon/space if present
if ((ix=fullVersion.indexOf(";"))!=-1)
   fullVersion=fullVersion.substring(0,ix);
if ((ix=fullVersion.indexOf(" "))!=-1)
   fullVersion=fullVersion.substring(0,ix);

majorVersion = parseInt(''+fullVersion,10);
if (isNaN(majorVersion)) {
 fullVersion  = ''+parseFloat(navigator.appVersion); 
 majorVersion = parseInt(navigator.appVersion,10);
}

return browserName;
}

export const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return "mobile";
    }
    return "desktop";
  
}

//regular expressions to extract IP and country values
const countryCodeExpression = /loc=([\w]{2})/;
const userIPExpression = /ip=([\w\.]+)/;

//automatic country determination.
export const initCountry = () => {
    return new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        xhr.timeout = 3000;
        xhr.onreadystatechange = function () {
            if (this.readyState == 4) {
                if (this.status == 200) {
                    let countryCode = countryCodeExpression.exec(this.responseText)
                    let ip = userIPExpression.exec(this.responseText)
                    if (countryCode === null || countryCode[1] === '' ||
                        ip === null || ip[1] === '') {
                        reject('IP/Country code detection failed');
                    }
                    let result = {
                        "countryCode": countryCode[1],
                        "IP": ip[1]
                    };
                    resolve(result)
                } else {
                    reject(xhr.status)
                }
            }
        }
        xhr.ontimeout = function () {
            reject('timeout')
        }
        xhr.open('GET', 'https://www.cloudflare.com/cdn-cgi/trace', true);
        xhr.send();
    });
}

export const getBigDataReverseGeocode = async (latitude, longitude) => {
  await axios({
    method: 'get',
    url: `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`,
    responseType: 'stream'
  })
}

export const getSourceMedium = (referrer) => {
  console.log("referrer", referrer)
  if(referrer.includes("google") || referrer.includes("baidu") || referrer.includes("search.yahoo") || referrer.includes("bing")){
    return "organic";
  }
  // else if(referrer.includes("facebook") || referrer.includes("t.co") || referrer.includes("whatsapp") || referrer.includes("instagram")){
  //   return "social"
  // }
  else if(referrer !== "" && !referrer.includes("alekhbariya.net") ){
    return "referral";
  }
  else{
    return "direct";
  }
}