import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import national_day_cat_icon_old from "../../assets/img/national_day_cat_icon_big.png";
import national_day_cat_icon from "../../assets/img/national_day_cat_icon_big_Medium.jpg";
import sendAnalyticsEvent from "../../scripts/analyticsEvent";



const NewsCategoryTitle = ({ isFixedLayout, titleColor, name, logo, props, analyticsInfo }) => {

  const { t } = props;

  const dispatch = useDispatch();

  const locale = useSelector((state) => state.info.locale);
  const cookieConsent = useSelector((state) => state.info.cookieConsent.consent);


  let outerDiv = '';
  let innerDiv = '';
  let size = 'align-self-end ';
  let color = '';
  let isSearchPage = name === t("search.searchResults") ? true : false;


  if (isFixedLayout) {
    outerDiv = 'row beside_tabs';
    innerDiv = 'col-12 d-flex justify-content-start align-items-start'
  }
  else if (isSearchPage) {
    outerDiv = 'row mb-5';
    innerDiv = 'col-12 d-md-flex justify-content-md-start';
  }
  else {
    outerDiv = 'row mt-5 mb-4';
    innerDiv = 'col-12 d-flex justify-content-start mt-3'
  }

  switch (titleColor) {
    case '#007CE6':
      color = 'cat_1';
      break;
    case '#D30800':
      color = 'cat_2'
      break;
    case '#AC9F0A':
      color = 'cat_3'
      break;
    case '#A83A3A':
      color = 'cat_4'
      break;
    case '#739D4F':
      color = 'cat_5'
      break;
    case '#00C8D0':
      color = 'cat_6'
      break;
    case '#747474':
      color = 'cat_7'
      break;
    case '#E66A00':
      color = 'cat_8'
      break;
    case '#3D9598':
      color = 'cat_9'
      break;
    case '#873D98':
      color = 'cat_10'
      break;
    case '#001627':
      color = 'cat_11'
      break;
    default:
      break;
  }

  let hSize = size + color;
  let aCSS = 'view_all ' + hSize;

  return (
    <div className={outerDiv}>
      <div className={innerDiv}>

        {!isSearchPage ?
          <div style={{ display: 'flex' }}>
            <h2 className={`${hSize} ${name==="هي لنا دار" ? "icon" : ""}`}>{name}
            {name==="هي لنا دار" && <img src={national_day_cat_icon} style={{borderRadius: 0, left:-5}} alt="National day icon"></img>}
            </h2>
            <Link
              onClick={() =>{
                dispatch({
                  type: "CHANGED_PATH",
                  path: `/${locale === "arSA" ? "ar" : "en"}/category/${name}`
                })
                sendAnalyticsEvent({cookieConsent: cookieConsent ,type:"click", label:`view all`, section:analyticsInfo?.section, page:analyticsInfo?.page, value:analyticsInfo?.value})

              }
            }
              to={{
                pathname: `/${locale === "arSA" ? "ar" : "en"}/category/${name}`
              }} className={aCSS}>{t('generic.viewAll')}</Link>
          </div>
          :
          <h1 className="align-self-end no_cat">
            {name}
          </h1>
        }

      </div>
    </div>
  )
}

export default withTranslation()(NewsCategoryTitle);