import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from "react-redux";
import { useParams } from 'react-router';
import { useHistory } from "react-router-dom";
import axios from "axios";

import ListAllEpisodes from '../components/Programs/ListAllEpisodes';
import ShareMetaData from '../components/Utils/SocialMediaShare/ShareMetaData';
import VideoPlayer from '../components/Utils/VideoPlayer';

import ListAllPrograms from '../components/Programs/ListAllPrograms';
import { withTranslation } from 'react-i18next';

import { fetchAllProgramEpisodes, fetchEpisodeDetail } from '../store/actions/programs';
import SpinnerLoader from '../components/Utils/Loaders/SpinnerLoader';
import { getDateFromEpisode } from '../scripts/utils';
import EmptyResultsPage from './EmptyResultsPage';
import sendAnalyticsEvent from "../scripts/analyticsEvent";



const ProgramDetailPage = (props) => {

	const { t } = props;

	const programs = useSelector((state) => state.programs.programsList);
	const loading = useSelector((state) => state.programs.loading);
	const locale = useSelector((state) => state.info.locale);
	const cookieConsent = useSelector((state) => state.info.cookieConsent.consent);
	const history = useHistory();

	const { programName } = useParams();
	const [episodes, setEpisodes] = useState([]);

	const [lastEpisode, setLastEpisode] = useState({});
	const [open, setOpen] = useState(false);
	const [play, setPlay] = useState(false);
	const [videoURL, setVideoURL] = useState(); // for the highlighted episode
	const [duration, setDuration] = useState(); // for the highlighted episode

	const [episodesLoading, setEpisodesLoading] = useState(false);
	const [error, setError] = useState(false);
	const [lastEpisodeError, setLastEpisodeError] = useState(false);
	const [programId, setProgramId] = useState();
	const [programThumbnail, setProgramThumbnail] = useState("");
	const [isMounted, setIsMounted] = useState(false);
	const [isFiltered, setIsFiltered] = useState(false);

	const [input, setInput] = useState("");
	const [windowSize, setWindowSize] = useState(window.innerWidth);

	const onOpenModal = () => {
		setOpen(true);
		setPlay(true);
	};

	const onCloseModal = () => {
		setOpen(false);
		setPlay(false);
	};

	const getProgramInfo = () => {
		programs.map((program) => {
			if (program.title === programName) {
				setProgramThumbnail(program.image);
				setProgramId(program.id);
			}
		})
	}

	useEffect(() => {
		setIsMounted(true);
	})

	useEffect(() => {
		setInput("");

		async function fetchEpisodeDetailData(id) {
			await fetchEpisodeDetail(id)
				.then(data => {
					setVideoURL(data.source);
					setDuration(data.duration);
				})
				.catch(error => {
					console.log(error);
				})
		}
		lastEpisode !== "" && fetchEpisodeDetailData(lastEpisode.id)

	}, [history.location])

	useEffect(() => {
		if (isMounted) {
			setIsMounted(false);
			history.push("/");
		}
	}, [locale])

	useEffect(() => {
		getProgramInfo();
	}, [programs, programName])

	useEffect(() => {

		const CancelToken = axios.CancelToken;
		const source = CancelToken.source();

		setEpisodesLoading(true);

		async function fetchAllEpisodesData(id, episodeQuery) {
			await fetchAllProgramEpisodes(id, episodeQuery, source.token)
				.then((newsList) => {

					// para que o last episode nao mude com a pesquisa
					if (!isFiltered) setLastEpisode(newsList.lastEpisode);
					else setIsFiltered(false);

					setEpisodes(newsList.items);
					setError(false);
					setLastEpisodeError(false);
				})
				.catch((error) => {

					console.log(error);
					setError(true);

					if (isFiltered) setLastEpisodeError(false);
					else setLastEpisodeError(true);

				});
			setEpisodesLoading(false);
		}

		programId !== undefined && fetchAllEpisodesData(programId, input);

		return () => {
			source.cancel("Cancelled due to another request")
		}

	}, [programId, input])


	const handleSearch = (event) => {
		setInput(event.target.value);
		if (event.target.value !== "") setIsFiltered(true);
	}
	
	const handleWindowResize = useCallback(event => {

		setWindowSize(window.innerWidth);
  
	}, []); 
  
  
	useEffect(() => {
	  window.addEventListener('resize', handleWindowResize);
  
	  return () => {
		window.removeEventListener('resize', handleWindowResize);
	  };
	}, [handleWindowResize]);

	useEffect(() => {
		sendAnalyticsEvent({type:"viewed",page:`program detail ${programName}`, cookieConsent: cookieConsent});

	}, [])

	return (
		<main>
			<ShareMetaData title={episodes.programName}></ShareMetaData>
			<div class="container-fluid m-0 p-0">
				<div class="hero program">
					<div class="row h-100 mr-0 ml-0">

						<div class="col-12 col-lg-6 h-100 p-0 live_now">
							<div class="hero_50 d-flex">
								<div class="title">
									<h1>{programName}</h1>
								</div>
								{!lastEpisodeError &&
									<div class={`w-100 justify-content-between align-items-end detail hero_program"`}>
										<div class="w-70">
											<h3>{t("programs.lastEpisode")}</h3>
											<h2 style={{ overflowX: "auto"}}>{lastEpisode.episodeName}</h2>
										</div>
										{episodesLoading ?
											<div
												class="spinner-border d-flex justify-content-center align-items-center"
												role="status"
											>

												<span className=" align-self-center stretched-link sr-only" style={{ fontSize: 48, color: "white" }}>
													<i className="fas fa-spinner-third"></i>
												</span>
											</div>
											:
											<div class="vid_icon">
												<a onClick={onOpenModal} href="#" class="align-self-center stretched-link"></a>
											</div>
										}
										{open &&
											<VideoPlayer
												onClose={onCloseModal}
												open={open}
												id={lastEpisode.id}
												episode={videoURL}
												title={lastEpisode.episodeName}
												summary={lastEpisode.description}
												duration={duration}
												date={getDateFromEpisode(lastEpisode.startTime, locale)}
												type="Program-Video"
											/>
										}
									</div>
								}
								<img src={programThumbnail} />
							</div>
						</div>
						<div class="col-12 col-md-6 epg p-0 h-100">

							<div class="title w-100 d-flex pt-3 pb-3 pr-3">
								<h4>{t("podcasts.episodes")}</h4>
								<form class="body_search w-50 ml-auto program">
									<input value={input} onChange={handleSearch} class="body_search_input" type="search" placeholder={t("programs.search")} aria-label="Search" />
									<button onClick={(event) => { event.preventDefault() }} class="search_button" type="submit"><i class="fal fa-search"></i></button>
								</form>
							</div>
							{!episodesLoading && !error && <ListAllEpisodes episodes={episodes} props={props} />}
							{episodesLoading && <SpinnerLoader backgroundColor="black" />}
							{/* {!episodesLoading && error && <h3 className="d-flex justify-content-center align-items-center w-100 h-100">{t("errors.noEpisodes")}</h3>} */}
						<EmptyResultsPage></EmptyResultsPage>
						</div>
					</div>
				</div>
			</div>

			<ListAllPrograms programs={programs} isLoading={loading} props={props} />

		</main>
	)

}

export default withTranslation()(ProgramDetailPage);