import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import NewsCard from "./NewsCard";
import SpinnerLoader from "../Utils/Loaders/SpinnerLoader";
import sendAnalyticsEvent from "../../scripts/analyticsEvent";

const NewsTicker = React.memo(({ props }) => {

  const { t } = props;
  const data = useSelector((state) => state.newstickerNews.newsList);
  const loadingNewsTicker = useSelector((state) => state.newstickerNews.loading);
  const newstickersState = useSelector((state) => state.newstickerNews);
  const locale = useSelector((state) => state.info.locale);
  const cookieConsent = useSelector((state) => state.info.cookieConsent.consent);


  const dispatch = useDispatch();

  return data ? (
    <div className="news_ticker_col col-12 col-md-4 d-block pl-0 pl-md-3 mt-1 mt-md-0">
      <div className="row mb-2">
        <div className="col-12">
          {newstickersState.newsList.length !== 0 ?
            <div>
              <h2 className="float-left">{t("newsTickerComp.newsTicker")}</h2>
              <Link
                onClick={() =>{
                  dispatch({ type: "CHANGED_PATH", path: `/${locale === "arSA" ? "ar" : "en"}/newsticker` })
                  sendAnalyticsEvent({section: "news ticker",page:"home", cookieConsent: cookieConsent,type:"click", label: "view all"});

                }
                }
                to={{
                  pathname: `/${locale === "arSA" ? "ar" : "en"}/newsticker`
                }}
                className="view_all">
                {t("generic.viewAll")}
              </Link></div> : null}
        </div>
      </div>
      <div className="row">
        <div className="col-12 pl-0">
          <div className="news_ticker w-100" style={{ backgroundColor: newstickersState.newsList.length !== 0 ? "" : "transparent" }}>
            {loadingNewsTicker && <SpinnerLoader />}
            {data.map((card, index) => (
              <NewsCard
                id={card?.id}
                key={index}
                type="ticker"
                cardinfo={card}
                image={card?.fields?.cover_image?.directURL}
                imageClass={card?.fields?.image_alignment}
                createdDate={
                  card?.fields?.authoring_date?.value
                }
                summary={card?.fields?.summary}
                analyticsInfo={t.analyticsInfo}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  ) : null;
});

export default withTranslation()(NewsTicker);
