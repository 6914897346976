import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import no_image from "../../assets/no-image.jpg";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from 'react-i18next';

import colorToCSSClassMapper from "../../config/categoryColor";
import { IoBook } from 'react-icons/io5';
import sendAnalyticsEvent from "../../scripts/analyticsEvent";


const SmallNewsCard = (props) => {

  const { t } = props;

  const dispatch = useDispatch();

  const categoryInfo = useSelector((state) => state.categories.categoriesList);
  const locale = useSelector(state => state.info.locale);
  const cookieConsent = useSelector((state) => state.info.cookieConsent.consent);


  const [categoryCssClass, setcategoryCssClass] = useState(null);

  useEffect(() => {
    let currentColor;
    for (let key in categoryInfo) {
      let obj = categoryInfo[key];
      if (obj.category_name === props.category) {
        currentColor = obj.color;
        setcategoryCssClass(colorToCSSClassMapper[currentColor]);
        //document.body.classList.add(colorToCSSClassMapper[currentColor]);
        break;
      }
      /*         else if (obj.hasChildren) {
                for (let sub in obj.childCategories) {
                  let subCat = obj.childCategories[sub];
                  if (subCat === props.category) {
                    currentColor = obj.color;
                    setcategoryCssClass(colorToCSSClassMapper[currentColor]);
                  }
                }
              } */
    }
    /* document.body.classList.add("inner_content"); */

    return function cleanUp() {
      //document.body.classList.remove(colorToCSSClassMapper[currentColor]);
      /*         document.body.classList.remove("inner_content"); */
    };

  }, [props.id, categoryInfo]);

  let smallCardPath = "";
  let typeName = "";

  if (props.type === "News") {
    smallCardPath = `/news/${props.id}`;
    typeName = props.category === "No Category" ? t('newsDetailPage.noCategory') : props.category;

  } else if (props.type === "Video-Library") {
    smallCardPath = `/Video-Library/${props.id}`;
    typeName = t('podcasts.video');
  }
  else if (props.type === "Video-Podcast") {
    smallCardPath = `/podcasts/Video-Podcast/${props.id}`;
    typeName = t('podcasts.podcast')
  }
  else if (props.type === "Audio-Podcast") {
    smallCardPath = `/podcasts/Audio-Podcast/${props.id}`;
    typeName = t('podcasts.podcast')
  }
  else if (props.type === "Program") {
    smallCardPath = `/Programs/${props.title}`;
    typeName = t('programs.program')
  }
  else {
    smallCardPath = `/error`;
  }

  return (
    <div>
      <Link to={`/${locale === "arSA" ? "ar" : "en"}${smallCardPath}`}
        onClick={() =>{
          dispatch({ type: "CHANGED_PATH", path: `/${locale === "arSA" ? "ar" : "en"}${smallCardPath}` })

          sendAnalyticsEvent({cookieConsent: cookieConsent ,type:"click", label:`image`, value: smallCardPath, section:props.analyticsInfo?.section, page:props.analyticsInfo?.page})
        }
        }
        title="" className="stretched-link"></Link>
      <div className="row card_most_read">
        <div className={props.newsticker ? "col-12 pr-0 mb-2" : "col-8"}>
          <h6 className={`${categoryCssClass ? categoryCssClass : "cat_11"}`}>{props.category === "No Category" || typeName !== "News" ? typeName : props.category}</h6>
          <dl>
            <dt>{props.title}</dt>
            <dd>{props.summary}</dd>
            {props.viewCounts ?
              <dd class="d-flex justify-content-start mt-2"><i class="fas fa-book-open pt-1 mr-1"></i><p> {props.counter} {t('newsDetailPage.reads')} </p></dd> : null}
          </dl>
        </div>
        <div className={props.newsticker ? "col-12 pl-3 pr-0" : "col-4 p-0"}>
          <div className="image_most_read">
            <img className={props.imageClass} src={!props.cover_image ? `/assets/images/default.png` : props.cover_image} alt={typeName} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(SmallNewsCard);
