import React, {useEffect} from 'react';
import { useSelector } from "react-redux";
import sendAnalyticsEvent from "../scripts/analyticsEvent";


const PrivacyPage = () => {

    const cookieConsent = useSelector((state) => state.info.cookieConsent.consent);

    useEffect(() => {
		sendAnalyticsEvent({type:"viewed",page:"privacy", cookieConsent: cookieConsent});

	}, [])

    return (
        <div>
            <div class="container featured light_theme">
                <div class="row mb-4">
                    <div class="col-12">
                        <h1 class="align-self-end no_cat">سياسة الخصوصية</h1>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
						<p>مبادئنا تلزم علينا حماية حقوق جميع زوار موقع قناة الإخبارية, إننا نلتزم بالحفاظ على سرية المعلومات الحساسة، وقد أعد هذه الاتفاقية الخاصة بالأمن والخصوصية للإفصاح عن النهج الذي نتبعه في جمع المعلومات ونشرها على موقعنا</p>
						<p><b>جمع واستخدام المعلومات</b></p>
						<p>تعمل مخدمات البوابة، على التقاط عنوان بروتوكول الإنترنت IP الخاص بك عند زيارتك الموقع. إن استخدامنا بروتوكول الإنترنت الخاص بك يساعدنا على تشخيص المشكلات التي تحدث في السيرفرات الخاصة بنا، وعلى إجراء الإحصاءات اللازم لقياس استخدام الموقع (عدد الزوار و لغة جهاز الكمبيوتر الذي تستخدمه)، حيث اننا لا نسمح لأي جهة (باستثناء مسئولي البوابة) بالاطلاع على بروتوكول الإنترنت الخاص بك</p>
						<p><b>(Cookies) </b><b>ملفات تعريف الارتباط</b></p>
						<p>أحياناُ يتم تخزين ما يسمى بملفات تعريف الارتباط "Cookies" على الكمبيوتر الخاص بك عندما تقوم بزيارة موقعنا. حيث أن ملفات تعريف الارتباط "Cookies" هي جزء من البيانات التي تحددك كمستخدم بشكل فريد. كما يمكن استخدامها لتطوير معرفتك بالبوابة ولفهم قاعدة مستخدم هذه البوابة على نحو أفضل. يتم إعداد معظم المستعرضات في البداية لقبول ملفات تعريف الارتباط. كما يمكنك إعادة إعداد المتصفح الخاص بك لرفض كافة ملفات تعريف الارتباط أو للتنبيه عندما يتم إرسال ملفات تعريف الارتباط. كما يرجى ملاحظة أن بعض الخدمات التي يتم عرضها على هذه البوابة قد لا تعمل بشكل صحيح إذا قمت برفض ملفات تعريف الارتباط. حيث أننا قد نقوم / لا نقوم بربط المعلومات التي نقوم بتخزينها في ملفات تعريف الارتباط بأي معلومات تعريف شخصية تقدمها على موقعنا.</p>
						<p><b>حماية المعلومات الشخصية</b></p>
						<p>لن تكون معلوماتك الشخصية متاحة إلا لموظفينا الذين يحتاجون إلى الاطلاع عليها. ولن تكون تلك المعلومات متاحة للجمهور من غير موافقتك. بالإضافة الي ذلك لن يتم تبادل، أو تداول، أي من تلك المعلومات لأي طرف آخر من غير موافقتك المسبقة.</p>
						<p><b>المواقع الأخرى</b></p>
						<p>ينطبق نهج الخصوصية هذا على جميع موقعنا .</p>
						<p><b>سياسة الاستخدام </b></p>
						<p>نرجو قراءة هذه الشروط والبنود بعناية، بدخولك إلى هذا الموقع أو أي صفحات منه فإن ذلك يعني بأنك ملزم بالتقيد بالبنود والشروط الموضحة أدناه، وفي حالة عدم موافقتك على هذه الشروط والبنود نرجو عدم الدخول إلى هذا الموقع أو الاطلاع على أي صفحات منه.</p>
						<p><b>جميع حقوق الطبع محفوظة لهيئة الإذاعة والتلفزيون</b></p>
						<p>تخضع الصفحات وشاشات عرض هذه الصفحات وكذلك المعلومات والمواد الواردة في هذه الصفحات والشاشات لقوانين حقوق الملكية الفكرية، وتحتفظ القناة بحقوقها القانونية تجاه أي مخالفة لذلك.</p>
						<p><b>استخدام المعلومات والمواد</b></p>
						<p>إن المعلومات والمواد الواردة في هذه الصفحات خاضعة للتغيير في أي وقت، ويحق للقناة تغيير هذه المعلومات وفقًا لما تقتضيه مصلحة العمل دون إبداء الأسباب.</p>
						<p><b>إخلاء مسؤولية</b></p>
						<p>إن المعلومات والمواد المنقولة من الأطراف الأخرى والواردة في هذا الموقع مثل النص والرسومات البيانية وخطوط الربط أو أي بنود أخرى - قد تم إعطائها "كما هي"، لذا فإن القناة لا تضمن دقة هذه المعلومات أو ملاءمتها للغرض المقصود أو إذا كانت هذه المعلومات والمواد وافية أم لا. كما تخلي القناة مسؤوليتها عن أي أخطاء أو محذوفات في هذه المعلومات والمواد، ولا تعطي القناة أي ضمان سواء كان ضمني أو صريح ومهما كان نوعه وطبيعته يتعلق بتلك المعلومات. ويشمل ذلك على سبيل المثال لا الحصر الضمانات المتعلقة بعدم انتهاك حقوق الأطراف الأخرى وحق الملكية وإمكانية تسويق المعلومات والمواد وملاءمتها للاستخدام في أي أغراض أخرى وخلوها من الفيروسات.</p>
						<p><b>حدود المسؤولية</b></p>
						<p>لا تعتبر القناة في أي حال من الأحوال مسؤولة عن أي أضرار ويشمل ذلك على سبيل المثال لا الحصر أي أضرار مباشرة أو خاصة أو طارئة أو لاحقة وأي خسائر أو مصاريف قد تنجم بسبب هذا الموقع أو بسبب استخدامه أو عدم القدرة على استخدامه بواسطة أي طرف.</p>
						<p><b>التقديمات</b></p>
						<p>تعتبر جميع المعلومات المقدمة من زوار الموقع ملكًا للقناة والتي يحق لها استخدام هذه المعلومات لأي غرض وكذلك استخدام أي أفكار أو مفاهيم أو أي خبرة أو تقنية تحتويها أي معلومات يوفرها أي زائر لهذا الموقع. وستتعامل القناة مع هذه المعلومات وفق سياسة القناة بخصوص سرية المعلومات أدناه.</p>
						<p><b>سياسة القناة العامة بخصوص سرية المعلومات</b></p>
						<p>إذا قمت بتزويدنا بمعلومات شخصية عن نفسك كعنوانك ورقم هاتفك أو عنوان البريد الإلكتروني الخاص بك وكذلك أية معلومات أساسية أو تعريفية تخصك كزائر مسجل في موقع القناة ، فإننا ملتزمون بعدم كشف تلك المعلومات (اطلاع أي شخص آخر عليها، أو بيعها أو إفشائها) لجهات خارجية ما لم يتم إبلاغك بذلك ونحصل على تفويض منك أو أن نفعل ذلك بحكم قانوني.</p>
						<p><b>رأي الزائر</b></p>
						<p>في حالة وجود أي آراء أو اقتراحات يرجى إرسالها مباشرة إلى البريد الالكتروني</p>
						<p><a href="mailto:info@ekh.sa">info@ekh.sa</a></p>

                    </div>
                </div>
            </div>
        </div>



    )

}

export default PrivacyPage;