import React, { useState, useEffect, useRef, useCallback, forwardRef } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import debounce from 'lodash.debounce';
import axios from "axios";

import CustomSlider from "../components/Utils/CustomSlider";
import NewsCardLoader from "../components/Utils/Loaders/NewsCardLoader";
import VideoCard from "../components/Podcasts/VideoCard";
import ShareMetaData from '../components/Utils/SocialMediaShare/ShareMetaData';
import { fetchVideos } from "../store/actions/videolibrary";
import EmptyResultsPage from "./EmptyResultsPage";
import ScrollButton from "../components/Utils/ScrollToTop";
import sendAnalyticsEvent from "../scripts/analyticsEvent";



import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";



const VideoLibraryListPage = (props) => {

  const { t } = props;

  const locale = useSelector((state) => state.info.locale);
  const cookieConsent = useSelector((state) => state.info.cookieConsent.consent);
  const theme = useSelector((state) => state.info.theme);
  const history = useHistory();

  const [videos, setVideos] = useState([]);
  const [loadingVideos, setLoadingVideos] = useState(true);
  const [enteredText, setEnteredText] = useState("");
  const [highlightedVideos, setHighlightedVideos] = useState([]);
  const [notHighlightedVideos, setNotHighlightedVideos] = useState([]);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [hasFixedLayout, setHasFixedLayout] = useState(false);
  const [hasHighlightedMore, setHasHighlightedMore] = useState(true);
  const [hasNoHighlightedMore, setHasNoHighlightedMore] = useState(true);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);



  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    //variableWidth: true,
  };

  useEffect(() => {


    // if (enteredText !== "") {
    //   setHasHighlightedMore(true);
    //   setHasNoHighlightedMore(true);
    // }
    setVideos([]);
    setHighlightedVideos([]);
    setNotHighlightedVideos([]);
  }, [enteredText])

  useEffect(() => {
    setVideos([]);
    setHighlightedVideos([]);
    setNotHighlightedVideos([]);
    setOffset(0);
  }, [startDate, endDate]);

  useEffect(() => {

    let source = axios.CancelToken.source();

    fetchAllHighlightedVideos(source.token);
    fetchAllNotHighlightedVideos(source.token);

    return () => {
      source.cancel("Request cancelled")
    }

  }, [enteredText, offset, locale, startDate, endDate]);


  useEffect(() => {
    setHasMore(hasHighlightedMore || hasNoHighlightedMore);
    setHasFixedLayout(!enteredText && highlightedVideos && highlightedVideos.length > 0 && notHighlightedVideos && notHighlightedVideos.length >= 4);
  }, [videos]);


  const fetchAllHighlightedVideos = async (token) => {

    setLoadingVideos(true);

    await fetchVideos(locale, 8, 0, enteredText, true, token, startDate, endDate)
      .then((videoList) => {
        // todos os sets sao usados para evitar duplicados
          setHighlightedVideos(videoList.items);
          setHasHighlightedMore(videoList.hasMore);
          setLoadingVideos(false);
          setVideos(videoList.items)
        }
        
      )
      .catch((error) => {
        console.log(error);
        setLoadingVideos(false);
        setHasHighlightedMore(false);
      });

  }



  const fetchAllNotHighlightedVideos = async (token) => {

    setLoadingVideos(true);

    await fetchVideos(locale, 8, offset, enteredText, false, token, startDate, endDate)
      .then((videoList) => {
        // todos os sets sao usados para evitar duplicados
        if (videos !== null && videos.length > 0 && videoList) {
          videoList.items.map((item) => {
            setNotHighlightedVideos((prevResults) => [
              ...new Set([...prevResults, item])
            ]);
          });
          setHasNoHighlightedMore(videoList.hasMore);
        }
        else {
          // no caso da primeira renderizacao
          setNotHighlightedVideos(videoList.items);
          setHasNoHighlightedMore(videoList.hasMore);
        }

        videoList.items.map((item) => {
          setVideos((prevResults) => [
            ...new Set([...prevResults, item])
          ]);
        });
        setLoadingVideos(false);
      })
      .catch((error) => {
        console.log(error);
        setLoadingVideos(false);
        setHasNoHighlightedMore(false);
      });

  }

  useEffect(() => {
		sendAnalyticsEvent({type:"viewed",page:"Video-Library", cookieConsent: cookieConsent});
	}, [])

  const handleSearch = (event) => {
    history.push(`/${locale === "arSA" ? "ar" : "en"}/Video-Library?q=${event.target.value}`);
    setEnteredText(event.target.value);
    setOffset(0);
  };

  const handleOffset = (node) => {
    if (loadingVideos) return;
    // o node ee o ultimo elem se nao e o node queremos dar disconnect
    if (observer.current) observer.current.disconnect();

    // entries vai corresponder a tudo que se vai tornar visivel depois do scroll
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setOffset((prevValue) => prevValue + 8);
      }
    });

    if (node) observer.current.observe(node);
  };


  const observer = useRef();
  const lastElementRef = useCallback(debounce(handleOffset, 1000), [loadingVideos, hasMore]);

  const StartDateCustomInput = forwardRef(({ value, onClick }, ref) => (
    <>
      <input type="text" className="" id="start-date" placeholder="انقر للضبط" onClick={onClick} ref={ref} value={value}></input>
      <label for="start-date">تاريخ البدأ</label>
    </>
  ));

  const EndDateCustomInput = forwardRef(({ value, onClick }, ref) => (
    <>
      <input type="text" className="" id="end-date" placeholder="انقر للضبط" onClick={onClick} ref={ref} value={value}></input>
      <label for="end-date">تاريخ الإنتهاء</label>
    </>
  ));

  

  const VideoLibraryFixedLayout = () => {

    return (
      <div className="row pl-3">
        <div className="col-12 col-md-6 pl-0">
          <CustomSlider settings={sliderSettings} data={highlightedVideos} loading={loadingVideos} props={props} />
        </div>

        <div className="col-12 col-md-3 pl-0">
          {notHighlightedVideos &&
            notHighlightedVideos.slice(0, 2).map((cardnew, index) => {
              return (
                <div key={index}>
                  <VideoCard
                    key={index}
                    loadingVideos={loadingVideos}
                    type="card"
                    icon="video_icon"
                    cardSize="card_news three_row_news"
                    cardinfo={cardnew}
                    video={cardnew?.fields?.video?.directURL}
                    image={cardnew?.fields?.cover_image?.directURL}
                    imageClass={cardnew?.fields?.image_alignment}
                    createdDate={
                      cardnew?.fields?.authoring_date?.value
                    }
                    summary={cardnew?.description}
                    props={props}
                    entryId={cardnew?.fields?.video?.entryId}
                  />
                </div>
              );
            })}
        </div>

        <div className="col-12 col-md-3 pl-0">

          {notHighlightedVideos &&
            notHighlightedVideos.slice(2, 4).map((cardnew, index) => {
              if (index === 1) {
                return (
                  <div key={index} ref={lastElementRef} >
                    <VideoCard
                      loadingVideos={loadingVideos}
                      type="card"
                      icon="video_icon"
                      cardSize="card_news three_row_news"
                      cardinfo={cardnew}
                      video={cardnew?.fields?.video?.directURL}
                      image={cardnew?.fields?.cover_image?.directURL}
                      imageClass={cardnew?.fields?.image_alignment}
                      createdDate={
                        cardnew?.fields?.authoring_date?.value
                      }
                      summary={cardnew?.description}
                      props={props}
                      entryId={cardnew?.fields?.video?.entryId}
                    />
                  </div>
                );
              }
              else {
                return (
                  <div key={index}>
                    <VideoCard
                      loadingVideos={loadingVideos}
                      key={index}
                      type="card"
                      icon="video_icon"
                      cardSize="card_news three_row_news"
                      cardinfo={cardnew}
                      video={cardnew?.fields?.video?.directURL}
                      image={cardnew?.fields?.cover_image?.directURL}
                      imageClass={cardnew?.fields?.image_alignment}
                      createdDate={
                        cardnew?.fields?.authoring_date?.value
                      }
                      summary={cardnew?.description}
                      props={props}
                      entryId={cardnew?.fields?.video?.entryId}
                    />
                  </div>
                );
              }
            })}
        </div>
        <ScrollButton></ScrollButton>
      </div>
    )
  };


  const VideoCardIteration = ({ index, videocard }) => {

    

  

    if (notHighlightedVideos.length === index + 1) {
      return (
        <div ref={lastElementRef} className="col-12 col-md-3 pl-0" key={index}>
          <VideoCard
            loadingVideos={loadingVideos}
            type="card"
            icon="video_icon"
            cardSize="card_news three_row_news"
            cardinfo={videocard}
            video={videocard?.fields?.video?.directURL}
            image={videocard?.fields?.cover_image?.directURL}
            imageclassName={videocard?.fields?.image_alignment}
            createdDate={
              videocard?.fields?.authoring_date?.value
            }
            summary={videocard?.description}
            props={props}
            entryId={videocard?.fields?.video?.entryId}
          />
        </div>
      );
    } else {
      return (
        <div className="col-12 col-md-3 pl-0" key={index}>
          <VideoCard
            loadingVideos={loadingVideos}
            type="card"
            icon="video_icon"
            cardSize="card_news three_row_news"
            cardinfo={videocard}
            video={videocard?.fields?.video?.directURL}
            image={videocard?.fields?.cover_image?.directURL}
            imageClass={videocard?.fields?.image_alignment}
            createdDate={
              videocard?.fields?.authoring_date?.value
            }
            summary={videocard?.description}
            props={props}
            entryId={videocard?.fields?.video?.entryId}
          />
        </div>
      );
    }
  }


  return (
    <div className="container-fluid featured">
      <ShareMetaData title={t('videoLibrary')}></ShareMetaData>
      <div className="row mb-5">
        <div className="col-12 col-lg-6 d-flex justify-content-start align-items-end">
          <h1 className="align-self-end no_cat">{t('podcasts.videos')}</h1>
        </div>

        <div className="col-12 col-lg-3 d-flex justify-content-end align-items-end pl-3 pl-lg-0 mt-5 mt-lg-0">
          <form className="body_search w-100">
            <input value={enteredText} onChange={(e) => handleSearch(e)} className="body_search_input" type="search" placeholder={t('podcasts.searchVideos')} aria-label="Search" />
            <button onClick={(event) => { event.preventDefault() }} className="search_button" type="submit"><i className="fal fa-search"></i></button>
          </form>
        </div>
        <div className="col-12 col-lg-3 filters">
          <div className="picker_wrapper" style={{textAlign: "right"}}>
            <DatePicker
              selected={startDate}
              onChange={(update) => {
                setStartDate(update);
              }}
              // isClearable={true}
              customInput={<StartDateCustomInput/>}
              withPortal
            />
            
          </div>
          <div className="picker_wrapper" >
            
            <DatePicker
              selected={endDate}
              onChange={(update) => {
                setEndDate(update);
              }}
              customInput={<EndDateCustomInput/>}
              withPortal
            />
          </div>
        </div>

      </div>
      {!enteredText && highlightedVideos && highlightedVideos.length > 0 && notHighlightedVideos && notHighlightedVideos.length >= 4 && <VideoLibraryFixedLayout />}

      <div className="row pl-3">

        {highlightedVideos && highlightedVideos.length > 0 && notHighlightedVideos && notHighlightedVideos.length >= 4 ? notHighlightedVideos.map((card, index) => (
          index > 4 && <VideoCardIteration index={index} videocard={card} />
        ))
          : null
        }
        {!hasFixedLayout && videos &&
          videos.map((cardnew, index) => {

            if (videos.length === index + 1) {
              return (
                <div ref={lastElementRef} className="col-12 col-md-3 pl-0" key={index}>
                  <VideoCard
                    loadingVideos={loadingVideos}
                    type="card"
                    icon="video_icon"
                    cardSize="card_news three_row_news two_row"
                    cardinfo={cardnew}
                    image={cardnew?.fields?.cover_image?.directURL}
                    imageClass={cardnew?.fields?.image_alignment}
                    createdDate={
                      cardnew?.fields?.authoring_date?.value
                    }
                    summary={cardnew?.description}
                    video={cardnew?.fields?.video?.directURL}
                    entryId={cardnew?.fields?.video?.entryId}
                  />
                </div>
              );
            }
            else {
              return (
                <div className="col-12 col-md-3 pl-0" key={index}>
                  <VideoCard
                    loadingVideos={loadingVideos}
                    type="card"
                    icon="video_icon"
                    cardSize="card_news three_row_news two_row"
                    cardinfo={cardnew}
                    image={cardnew?.fields?.cover_image?.directURL}
                    imageclassName={cardnew?.fields?.image_alignment}
                    createdDate={
                      cardnew?.fields?.authoring_date?.value
                    }
                    summary={cardnew?.description}
                    video={cardnew?.fields?.video?.directURL}
                    entryId={cardnew?.fields?.video?.entryId}
                  />
                </div>
              );
            }
          })
        }
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {hasNoHighlightedMore && window.innerWidth >= 767
        &&
          <div>
            <NewsCardLoader className="col-12 col-md-3 pl-0" />
            <NewsCardLoader className="col-12 col-md-3 pl-0" />
            <NewsCardLoader className="col-12 col-md-3 pl-0" />
            <NewsCardLoader className="col-12 col-md-3 pl-0" />
          </div>
        }
        {hasNoHighlightedMore && window.innerWidth < 767 && (
          <>
            <NewsCardLoader className="col-12 col-md-3 pl-0" />
          </>
        )
        }
      </div>
      {!loadingVideos && videos.length === 0 &&
          <EmptyResultsPage></EmptyResultsPage>
        }
    </div>


  );
};


export default withTranslation()(VideoLibraryListPage);

