import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import PodcastDetailCard from "../components/Podcasts/PodcastDetailCard";
import ShareMetaData from '../components/Utils/SocialMediaShare/ShareMetaData';
import { EpisodeCard, LatestEpisodeCard } from "../components/Podcasts/EpisodeCard";
import { fetchEpisodeSearch, fetchPodcastsByID } from "../store/actions/podcast";


const VideoPodcastDetailPage = (props) => {

    const { t } = props

    const { podcastID } = useParams()
    const { podcastType } = useParams()

    const history = useHistory();
    const locale = useSelector(state => state.info.locale);

    const [podcast, setPodcast] = useState(null)
    const [episodeSearch, setEpisodeSearch] = useState(null)
    const [latestEpisode, setLatestEpisode] = useState(null)
    const [searchLoading, setSearchLoading] = useState(false);
    const [input, setInput] = useState(null)
    const [loading, setLoading] = useState(false);
    const [numberEpisodes, setNumberEpisodes] = useState(null);

    //backend url takes video_podcast instead of video-podcast like front end
    let urlType = "video_podcast";

    /*useEffect (() => {
        {play ? audioRef.current.audio.current.play() : audioRef.current.audio.current.pause()}
      }
    ,[play])*/

    //Fetch audio and video podcast detail
    useEffect(() => {
        async function fetchPodcastData() {
            setLoading(true)
            await fetchPodcastsByID(urlType, podcastID, locale)
                .then(data => {
                    setLoading(false)
                    setPodcast(data.items);
                })
                .catch(error => {
                    console.log(error);
                })
        }
        fetchPodcastData()

    }, [locale])


    useEffect(() => {
        setPodcast(null);
        setInput('');
    }, [locale])

    //Search
    const handleInput = (event) => {
        event.preventDefault();
        setInput(event.target.value);
    };

    useEffect(() => {
        applySearch(true);
    }, [locale]);

    useEffect(() => {
        applySearch(false);
    }, [input]);

    const applySearch = async (firstTimeFlag) => {
        setSearchLoading(true);
        setEpisodeSearch(null);
        await fetchEpisodeSearch(input, urlType, locale, podcastID)
            .then((searchList) => {
                setSearchLoading(false);
                setEpisodeSearch(searchList.items);
                console.log(searchList.items)
                if (firstTimeFlag) { setNumberEpisodes(searchList.items.length); setLatestEpisode(searchList.items[0]) }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    function useIsMount() {
        const isMountRef = useRef(locale);
        useEffect(() => {
            isMountRef.current = locale;
        }, []);
        return isMountRef.current;
    };

    const isMount = useIsMount();

    useEffect(() => {
        if (isMount === locale) {
        } else {
            history.push(`/podcasts`);

        }
    });

    return (
        <div>
            <ShareMetaData title={podcast && podcast[0]?.fields?.title}></ShareMetaData>
            <div className="container-fluid m-0 p-0 text-dark">
                <div className="hero podcast">
                    <div className="row h-100 mr-0 ml-0">
                        <div className="col-12 col-lg-6 h-100 p-0 live_now pl-4 pr-4">
                            {!loading && podcast &&
                                <PodcastDetailCard
                                    props={props} image={podcast[0]?.fields?.cover_image?.directURL}
                                    imageClass={podcast[0]?.fields?.image_alignment}
                                    date={podcast[0]?.fields?.authoring_date?.value.substring(0, 10)}
                                    title={podcast[0]?.fields?.title}
                                    description={podcast[0]?.fields?.summary}
                                    type={"Video-Podcast"}
                                    numberOfEpisodes={numberEpisodes} />
                            }
                            <div className="row mt-5">
                                {!loading && latestEpisode && <LatestEpisodeCard
                                    id={latestEpisode?.fields?.video?.id}
                                    date={latestEpisode?.fields?.authoring_date?.value.substring(0, 10)}
                                    title={latestEpisode?.fields?.title}
                                    summary={latestEpisode.fields.summary}
                                    duration={latestEpisode?.fields?.duration}
                                    episode={latestEpisode?.fields?.episode?.directURL}
                                    props={props}
                                    image={podcast && podcast[0]?.fields?.cover_image?.directURL} />}
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 epg podcast p-0 h-100">
                            <div className="title w-100 d-flex pt-3 pb-3 pr-3 align-items-center">
                                <h4>{t('podcasts.episodes')}</h4>
                                <form className="body_search w-50 ml-auto">
                                    <input value={input}
                                        onChange={(e) =>
                                            handleInput(e)} className="body_search_input" type="search" placeholder={t('podcasts.searchEpisodes')}
                                        aria-label="Search" />
                                    <button className="search_button" type="submit"><i
                                        className="fal fa-search"></i></button>
                                </form>
                            </div>
                            <div className="epg_wrapper detail">
                                {!searchLoading && episodeSearch && episodeSearch.map((card, index) => (
                                    <EpisodeCard
                                        index={index}
                                        props={props}
                                        id={card?.id}
                                        date={card?.fields?.authoring_date?.value}
                                        title={card?.fields?.title}
                                        summary={card.fields.summary}
                                        duration={card?.fields?.duration + " " + t("programs.minutes")}
                                        episode={card?.fields?.episode?.directURL}
                                        type={"Video-Podcast"}
                                        image={podcast && podcast[0]?.fields?.cover_image?.directURL} />))}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )

}
export default withTranslation()(VideoPodcastDetailPage);