import React, { useState, useRef, useEffect, useCallback, forwardRef } from "react";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { useGA4React } from "ga-4-react";
import debounce from 'lodash.debounce';
import axios from "axios";
import Chip from "@material-ui/core/Chip";

import NewsCard from "../components/News/NewsCard";
import ShareMetaData from '../components/Utils/SocialMediaShare/ShareMetaData';
import NewsCardLoader from "../components/Utils/Loaders/NewsCardLoader";
import { sendCategoryView, sendPageView } from "../components/Utils/GATracker";
import colorToCSSClassMapper from "../config/categoryColor";
import { fetchNewsByCategory } from "../store/actions/news";
import national_day_cat_icon_old from "../assets/img/national_day_cat_icon_big.png";
import national_day_cat_icon from "../assets/img/national_day_cat_icon_big_Medium.jpg";
import EmptyResultsPage from "./EmptyResultsPage";
import {FaArrowCircleUp} from 'react-icons/fa';
import sendAnalyticsEvent from "../scripts/analyticsEvent";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const CategoryPage = (props) => {

  const { t } = props;

  const { categoryName } = useParams();

  const locale = useSelector((state) => state.info.locale);
  const cookieConsent = useSelector((state) => state.info.cookieConsent.consent);
  const pathname = useSelector((state) => state.info.pathname);
  const theme = useSelector((state) => state.info.theme);
  const catList = useSelector((state) => state.categories.categoriesList);
  const categoryInfo = useSelector((state) => state.categories.categoriesList);

  const history = useHistory();

  const [categoryCssClass, setCategoryCssClass] = useState(null);
  const [categoryColor, setCategoryColor] = useState(null);
  const [news, setNews] = useState([]);

  //chips
  const [subCat, setSubcat] = useState(null); //lista de subcategorias para category escolhido
  const [select, setSelect] = useState([]); //lista de subcategorias escolhidas pelo chip
  const [filtered, setFiltered] = useState(null); //lista de news feita por chips

  //infinite scroll

  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [loadingNews, setLoadingNews] = useState(true);
  const [enteredText, setEnteredText] = useState("");

  const ga = useGA4React();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  let allCat = categoryName; // mete numa lista categorias e subcategorias para dado categoryName
  let allSubcat = '';  //mete no array lista de subcategorias
  let childCategories = [];
  let catLogo ='';

  Object.keys(catList).map(function (s) {
    if (catList[s].category_name === categoryName) {
      catLogo = catList[s].logo;
      allSubcat = Object.keys(catList[s].childCategories).map(function (k) {
        allCat += "," + catList[s].childCategories[k];
        //listSubCat +=  (listSubCat.length > 0 ? ',' : '') + subCat2[s].childCategories[k];
        return <li key={k}>{catList[s].childCategories[k]}</li>;
      })
      childCategories = catList[s].childCategories;
    }
  })


  useEffect(() => {
    sendCategoryView("Category", categoryName);
    sendPageView("Category");
    sendAnalyticsEvent({cookieConsent: cookieConsent, type:"viewed",page:`category ${categoryName}`, category:categoryName, locale: locale});
    setNews([]);
    setOffset(0);
    setEnteredText('');
    setSelect([]);
    setFiltered(null);
  }, [allCat, categoryName, locale, pathname])


  useEffect(() => {
    let currentColor;

    for (let key in categoryInfo) {
      let obj = categoryInfo[key];
      if (obj.category_name === categoryName) {
        currentColor = obj.color;
        setCategoryColor(currentColor);
        setCategoryCssClass(colorToCSSClassMapper[currentColor]);
        document.body.classList.add(colorToCSSClassMapper[currentColor]);
        document.body.classList.add("inner_content");
        break;
      }
    }

    return function cleanUp() {
      document.body.classList.remove(colorToCSSClassMapper[currentColor]);
      document.body.classList.remove("inner_content");
    };
  }, [categoryName, categoryInfo]);


  useEffect(() => {
    categoryCssClass && document.body.classList.add(categoryCssClass);
    if (categoryColor && Object.keys(categoryInfo).length > 0) {
      for (let key in categoryInfo) {
        if (categoryInfo[key].color == categoryColor) {
          history.push(`/${locale === "arSA" ? "ar" : "en"}/category/${categoryInfo[key].category_name}`);
          break;
        }
      }
    }
  }, [categoryInfo]);

  useEffect(() => {
    setNews([]);
    setOffset(0);
  }, [startDate, endDate]);


  useEffect(() => {

    setLoadingNews(true);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    async function fetchCategoryNewsData(category, subcategory) {
      //setFiltered(null); //mudar com varios chips ao mesmo tempo
      await fetchNewsByCategory(
        category,
        subcategory,
        offset === 0 ? 7 : 8,
        offset,
        enteredText,
        locale,
        source.token, startDate, endDate)
        .then((newsList) => {

          if (news !== null && news.length > 0 && newsList) {
            // todos os sets sao usados para evitar duplicados
            newsList.items.map((item) => {
              setNews((prevResults) => [
                ...new Set([...prevResults, item])
              ]);
            });
          }
          else if (!newsList) { setLoadingNews(false) }
          else {
            setNews(newsList.items);
            setLoadingNews(false)
          }
          setHasMore(newsList.hasMore);
          setLoadingNews(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    setTimeout(() => {
      if (select && select.length > 0) { fetchCategoryNewsData(select, []) }
      else { fetchCategoryNewsData(categoryName, childCategories) }
    }, 1000);

    return () => {
      source.cancel("Cancelled due to another request")
    }

  }, [allCat, enteredText, offset, locale, select, startDate, endDate])

  const handleInput = (event) => {
    setNews([]);
    setOffset(0);
    history.push(`/${locale === "arSA" ? "ar" : "en"}/category/${categoryName}?q=${event.target.value}`);
    setEnteredText(event.target.value);
    sendAnalyticsEvent({page:`category ${categoryName}`, cookieConsent: cookieConsent,type:"click", label: "search", value:event.target.value}); 

  };

  

  //Ação para chips

  const handleClick = (subc) => {
    setOffset(0);
    setNews([]);
    setLoadingNews(true);
    if (select && select.includes(subc)) {
      setSelect(select.filter((item) => item !== subc));
    } else if (select) {
      setSelect([...select, subc]);
    } else setSelect(subc);

  };

  /*  INFINITE SCROLL */

  const handleOffset = (node) => {
    if (loadingNews) return;
    // o node ee o ultimo elem se nao ee o node queremos dar disconnect
    if (observer.current) observer.current.disconnect();
    // entries vai corresponder a tudo que se vai tornar visivel depois do scroll
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setOffset((prevValue) => {
          if (prevValue === 0) return prevValue + 7;
          else return prevValue + 8;
        });
      }
    });
    if (node) observer.current.observe(node);

  };

  const observer = useRef();
  const lastElementRef = useCallback(debounce(handleOffset, 1000), [loadingNews, hasMore]);

  const ScrollButton = () =>{
  
    const [visible, setVisible] = useState(false)
    
    const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 300){
        setVisible(true)
      } 
      else if (scrolled <= 300){
        setVisible(false)
      }
    };
    
    const scrollToTop = () =>{
      window.scrollTo({
        top: 0, 
        behavior: 'smooth'

      });
    };
    
    window.addEventListener('scroll', toggleVisible);
    
    return (
      <div className="scrollTop list">
       <FaArrowCircleUp onClick={scrollToTop} 
       style={{display: visible ? 'inline' : 'none'}} />
      </div>
      );
    }

  const CategoryFixedLayout = React.memo(() => {

    return (
      <div class="row pl-3">
        <div class="col-12 col-md-6 pl-0">
          {news &&
            <NewsCard
              id={news[0].id}
              type="card"
              icon={news[0]?.fields?.has_video ? "video_icon" : "link_icon"}
              cardSize="card_news big_news square"
              cardinfo={news[0]}
              image={news[0]?.fields?.cover_image?.directURL}
              imageClass={news[0]?.fields?.image_alignment}
              createdDate={
                news[0]?.fields?.authoring_date?.value
              }
              summary={news[0]?.fields?.title}
              tagList={news[0]?.fields?.tags_list}
              analyticsInfo={{page:`category ${categoryName}`, section: "news list"}}
            />
          }
        </div>

        <div class="col-12 col-md-3 pl-0">
          {news && news.slice(1, 4).map((card, index) => (
            <NewsCard
              id={card.id}
              key={index}
              type="card"
              icon={card?.fields?.has_video ? "video_icon" : "link_icon"}
              cardSize="card_news three_row_news"
              cardinfo={card}
              image={card?.fields?.cover_image?.directURL}
              imageClass={card?.fields?.image_alignment}
              createdDate={
                card?.fields?.authoring_date?.value
              }
              summary={card?.fields?.title}
              tagList={card?.fields?.tags_list}
              analyticsInfo={{page:`category ${categoryName}`, section: "news list"}}

            />
          ))}
        </div>

        <div class="col-12 col-md-3 pl-0">
          {news && news.slice(4, 7).map((card, index) => {

            if (index === 2) {
              return (
                <div ref={lastElementRef}>
                  <NewsCard
                    ref={lastElementRef}
                    id={card.id}
                    key={index}
                    newsype="card"
                    icon={card?.fields?.has_video ? "video_icon" : "link_icon"}
                    cardSize="card_news three_row_news"
                    cardinfo={card}
                    image={card?.fields?.cover_image?.directURL}
                    imageClass={card?.fields?.image_alignment}
                    createdDate={
                      card?.fields?.authoring_date?.value
                    }
                    summary={card?.fields?.title}
                    tagList={card?.fields?.tags_list}
                    analyticsInfo={{page:`category ${categoryName}`, section: "news list"}}

                  />
                </div>
              )
            }
            else {
              return (
                <NewsCard
                  id={card.id}
                  key={index}
                  newsype="card"
                  icon={card?.fields?.has_video ? "video_icon" : "link_icon"}
                  cardSize="card_news three_row_news"
                  cardinfo={card}
                  image={card?.fields?.cover_image?.directURL}
                  imageClass={card?.fields?.image_alignment}
                  createdDate={
                    card?.fields?.authoring_date?.value
                  }
                  summary={card?.fields?.title}
                  tagList={card?.fields?.tags_list}
                  analyticsInfo={{page:`category ${categoryName}`, section: "news list"}}

                />
              )
            }
          })}
        </div>
        <ScrollButton></ScrollButton>
      </div>
    );
  });





  const NewsCardIteration = ({ index, card }) => {

    if (news.length === index + 1) {
      return (
        <div ref={lastElementRef} class="col-12 col-md-3 pl-0">
          <NewsCard
            ref={lastElementRef}
            id={card.id}
            key={index}
            type="card"
            icon={card?.fields?.has_video ? "video_icon" : "link_icon"}
            cardSize="card_news three_row_news"
            cardinfo={card}
            image={card?.fields?.cover_image?.directURL}
            imageClass={card?.fields?.image_alignment}
            createdDate={
              card?.fields?.authoring_date?.value
            }
            scheduled_go_live_date={
              card?.fields?.scheduled_go_live_date?.value
            }
            summary={card?.fields?.title}
            tagList={card?.fields?.tags_list}
            analyticsInfo={{page:`category ${categoryName}`, section: "news list"}}

          />
        </div>
      );
    } else {
      return (
        <div className="col-12 col-md-3 pl-0">
          <NewsCard
            key={index}
            id={card.id}
            type="card"
            icon={card?.fields?.has_video ? "video_icon" : "link_icon"}
            cardSize="card_news three_row_news"
            cardinfo={card}
            image={card?.fields?.cover_image?.directURL}
            imageClass={card?.fields?.image_alignment}
            createdDate={
              card?.fields?.authoring_date?.value
            }
            scheduled_go_live_date={
              card?.fields?.scheduled_go_live_date?.value
            }
            summary={card?.fields?.title}
            tagList={card?.fields?.tags_list}
            analyticsInfo={{page:`category ${categoryName}`, section: "news list"}}

          />
        </div>
      );
    }
  }

  const StartDateCustomInput = forwardRef(({ value, onClick }, ref) => (
    <>
      <input type="text" className="" id="start-date" placeholder="انقر للضبط" onClick={onClick} ref={ref} value={value}></input>
      <label for="start-date">تاريخ البدأ</label>
    </>
  ));

  const EndDateCustomInput = forwardRef(({ value, onClick }, ref) => (
    <>
      <input type="text" className="" id="end-date" placeholder="انقر للضبط" onClick={onClick} ref={ref} value={value}></input>
      <label for="end-date">تاريخ الإنتهاء</label>
    </>
  ));


  // MAIN RETURN

  return (

    <div class="container-fluid featured">
      <ShareMetaData title={categoryName}></ShareMetaData>
      <div class="row mb-5">
        <div class="col-12 col-lg-6 d-flex justify-content-start align-items-end">
          {/* falta mudar a class de acordo com a categoria */}
          <h1 class={`align-self-end ${categoryName==="هي لنا دار" ? "icon" : ""} ${categoryCssClass}`}>{categoryName}
          {categoryName==="هي لنا دار" && <img src={national_day_cat_icon} style={{left: '-55px'}} ></img>}
          </h1>
        </div>
        <div class="col-12 col-lg-3 d-flex justify-content-end align-items-end pl-3 mt-5 mt-lg-0">
          <form class="body_search w-100">
            <input
              value={enteredText}
              onChange={handleInput}
              class="body_search_input"
              type="search"
              placeholder={t("categoryPage.search")}
              aria-label="Search"
            />
            <button onClick={(event) => { event.preventDefault() }} class="search_button" type="submit">
              <i class="fal fa-search"></i>
            </button>
          </form>
        </div>
        <div className="col-12 col-lg-3 filters">
          <div className="picker_wrapper" style={{textAlign: "right"}}>
            <DatePicker
              selected={startDate}
              onChange={(update) => {
                setStartDate(update);
              }}
              // isClearable={true}
              customInput={<StartDateCustomInput/>}
              withPortal
            />
            
          </div>
          <div className="picker_wrapper" >
            
            <DatePicker
              selected={endDate}
              onChange={(update) => {
                setEndDate(update);
              }}
              customInput={<EndDateCustomInput/>}
              withPortal
            />
          </div>
        </div>
      </div>

      {/*CHIPS */}

      <div className="chips_wrapper">
        {allSubcat &&
          allSubcat.map((scat, index) => (
            <Chip key={index} label={scat.props.children} onClick={() => handleClick(scat.props.children)} color={select && select.includes(scat.props.children) ? "primary" : ""} />
          ))}
      </div>

      <div>
        {news.length > 6 && <CategoryFixedLayout />}
        <div class="row pl-3">
          {news && news.map((card, index) => {
            // para naao repetirmos os resultados do fixed layout 
            if (news.length > 6 && index > 6) {
              return (
                <NewsCardIteration index={index} card={card} />
              )
            }
            else if (news.length <= 6) {
              return (
                <NewsCardIteration index={index} card={card} />
              )
            }
            else {
              return null;
            }
          })}
        </div>
      </div>
      )
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {loadingNews && window.innerWidth >= 767 && (
          <>
            <NewsCardLoader className="col-12 col-md-3 pl-0" />
            <NewsCardLoader className="col-12 col-md-3 pl-0" />
            <NewsCardLoader className="col-12 col-md-3 pl-0" />
            <NewsCardLoader className="col-12 col-md-3 pl-0" />
          </>
        )
        }
        {loadingNews && window.innerWidth < 767 && (
          <>
            <NewsCardLoader className="col-12 col-md-3 pl-0" />
          </>
        )
        }
      </div>
      {!loadingNews && news.length === 0 &&
          <EmptyResultsPage></EmptyResultsPage>
        }
    </div>
  );
};

export default withTranslation()(CategoryPage);
