import React, { useState } from 'react';
import { useParams } from "react-router-dom";

import VideoPlayer from "../Utils/VideoPlayer"
import { convertDate } from "../../scripts/utils";

export const EpisodeCard = (props) => {
    const { t } = props;

    let index = props.index;

    const [disabledPar, setDisabledPar] = useState(-1);

    return (<div>

        <div className={disabledPar === index ? "epg_card podcast_card active d-flex align-items-center" : "epg_card podcast_card d-flex align-items-center"}>
            <dl className="time">{props.date &&
                <dt>{convertDate(props.date)}</dt>}
                {props.duration &&
                    <dd>{props.duration}</dd>}
            </dl>
            <dl className="info">
                {props.title &&
                    <dt>{props.title}</dt>}
                {props.summary &&
                    <dd>{props.summary}</dd>}
            </dl>
            <PodcastPlayer image={props.image} onDisabledUpdate={setDisabledPar} index={index} episode={props.episode} latest={false} type={props.type} props={props} />
        </div>
    </div>


    )
}

export const LatestEpisodeCard = (props) => {

    const { t } = props.props;

    const [disabledPar, setDisabledPar] = useState(-1);

    return (
        <div>
            <div className="col-12">
                <h3 className="text-uppercase"> {t('podcasts.lastEpisode')}</h3>
                {props.title &&
                    <h2 className="episode">{props.title}</h2>}
                <div className="vid_icon big_icon">
                    <PodcastPlayer image={props.image} onDisabledUpdate={setDisabledPar} episode={props.episode} props={props} latest={true} />
                </div>
                <div className="clearfix">

                </div>
                {props.summary &&
                    <p>{props.summary}</p>}
            </div>
        </div>

    )
}


export const PodcastPlayer = (props) => {

    const { onDisabledUpdate } = props;
    const { podcastType } = useParams();

    const [open, setOpen] = useState(false);
    const [play, setPlay] = useState(false);
    const [disabled, setDisabled] = useState(-1);

    const onOpenModal = () => {
        setOpen(true);
        setDisabled(index);
        // onDisabledUpdate(index);
        setPlay(true);
    };

    const onCloseModal = () => {
        setOpen(false);
        setPlay(false);
        // onDisabledUpdate(-1);
    };

    let index = props.index;


    return (
        <React.Fragment>
            <React.Fragment>
                <a key={index} onClick={onOpenModal} href="#" className="align-self-center stretched-link ml-auto" />
                {props.latest == false ?
                    <div key={index} className={disabled === index ? (play ? "vid_pause" : "vid_icon") : "vid_icon"}>
                        {open == true ?
                            <VideoPlayer onClose={onCloseModal}
                                open={open} episode={props.episode}
                                id={props.props.id}
                                title={props.props.title}
                                summary={props.props.summary}
                                duration={props.props.duration}
                                date={convertDate(props.props.date)}
                                type={props.type}
                                props={props}
                                image={props.props.image} /> : null}
                    </div> :
                    <React.Fragment>
                        {open == true ?
                            <VideoPlayer
                                onClose={onCloseModal}
                                open={open}
                                episode={props.episode}
                                id={props.props.id}
                                title={props.props.title}
                                summary={props.props.summary}
                                duration={props.props.duration}
                                date={convertDate(props.props.date)}
                                type={props.type}
                                props={props}
                                image={props.props.image} /> : null}
                    </React.Fragment>}
            </React.Fragment>
        </React.Fragment>

    )


}




