import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

import NewsCard from "../News/NewsCard";
import NewsCategoryTitle from "../NewsCategory/NewsCategoryTitle";
import MostReadNews from "./MostReadNews";
import { fetchNewsByCategory } from "../../store/actions/news";


const FixedLayout = ({ firstGroup, categoriesNames, viewCounts, props }) => {

  const { t } = props;

  const locale = useSelector((state) => state.info.locale);

  const [news, setNews] = useState(null);
  const [categoryName, setCategoryName] = useState("");

  // facco este tratamento dos nomes aqui para evitar uma rerenderizacao da homepage
  useEffect(() => {

    categoriesNames.map((elem, index) => {
      if (index === 0) setCategoryName(elem)
    })
  }, [categoriesNames])

  useEffect(() => {
    setNews(null);
  }, [locale])

  useEffect(() => {

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    async function fetchCategoryNewsData(cat, limit) {
      await fetchNewsByCategory(cat, [], limit, 0, "", locale, source.token)
        .then((newsList) => {
          setNews(newsList.items);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    categoryName !== "" && fetchCategoryNewsData(categoryName, 4);

    return () => {
      source.cancel("Cancelled due to another request")
    }

  }, [categoryName, locale]);

  return news ? (
    <div className="row mt-4">
      <div className="col-12 col-xl-9">
        <NewsCategoryTitle
          props={props}
          isFixedLayout={true}
          titleColor={firstGroup.color}
          name={categoryName}
          analyticsInfo={{page:"home", section: "news category", value: categoryName}}
          logo={firstGroup.logo}
        />
        <div className="row pl-3">
          <div className="col-12 col-md-8 pl-0 ">
            {/* Big square */}
            <NewsCard
              id={news[0]?.id}
              type="card"
              icon={news[0]?.fields?.has_video ? "video_icon" : "link_icon"}
              cardSize="big_news square card_news"
              cardinfo={news[0]}
              image={news[0]?.fields?.cover_image?.directURLArray?.large}
              imageClass={news[0]?.fields?.image_alignment}
              createdDate={
                news[0]?.fields?.authoring_date?.value
              }
              summary={news[0]?.fields?.title}
              newsticker={false}
              tagList ={news[0]?.fields?.tags_list}
              analyticsInfo={{page:"home", section: "news category"}}
            />
            {/* Big square end */}
          </div>
          <div className="col-12 col-md-4 pl-0 fake_fade">
            <div className="row">
              <div className="col-12">
                {news.slice(1, 4).map((card, index) => (
                  <NewsCard
                    id={card?.id}
                    key={index}
                    type="card"
                    icon={card?.fields?.has_video ? "video_icon" : "link_icon"}
                    cardSize="card_news three_row_news"
                    cardinfo={card}
                    image={card?.fields?.cover_image?.directURLArray?.medium}
                    imageClass={card?.fields?.image_alignment}
                    createdDate={
                      card?.fields?.authoring_date?.value
                    }
                    summary={card?.fields?.title}
                    newsticker={false}
                    tagList ={card?.fields?.tags_list}
                    analyticsInfo={{page:"home", section: "news category"}}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <MostReadNews isViewCountsEnabled={viewCounts} newsticker={false} analyticsInfo={{page:"home", section: "most read news"}}/>
      {/* falta o resto das categrias */}
    </div>
  ) : null;
};

export default FixedLayout;
