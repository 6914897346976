import React, { useState, useEffect, useRef, useCallback } from "react";
import { fetchVideos } from "../../store/actions/videolibrary";
import { fetchLatestAudioPodcasts, fetchLatestVideoPodcasts } from "../../store/actions/podcast"
import VideoCard from "../Podcasts/VideoCard";
import { useDispatch, useSelector } from "react-redux";
import PodcastCard from '../Podcasts/PodcastCard';
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { getDateFromNow, getDateFromNowPrograms } from "../../scripts/utils";
import NewsCardLoader from "../Utils/Loaders/NewsCardLoader";
import moment from "moment";
import env from "../../config/env";

const OurContent = (props) => {

    const locale = useSelector((state) => state.info.locale);
    const programs = useSelector((state) => state.programs.programsList);
    const programLoading = useSelector((state) => state.programs.loading);
    const [videos, setVideos] = useState(null);
    const [loadingVideos, setLoadingVideos] = useState(true);
    const [videoPodcasts, setVideoPodcasts] = useState(null)
    const [audioPodcasts, setAudioPodcasts] = useState(null)
    const [audioPodcastLoading, setAudioPodcastLoading] = useState(false);
    const [videoPodcastLoading, setVideoPodcastLoading] = useState(false);
    const { t } = props;
    const dispatch = useDispatch();
    const theme = useSelector((state) => state.info.theme);

    const getNumberOfCards = useCallback(() => {

        let limit = 0;

        if (window.innerWidth >= 1200) {
            limit = 8;
        }
        else if (window.innerWidth < 1200 && window.innerWidth >= 991) {

            limit = 6;
        }
        else if (window.innerWidth < 991 && window.innerWidth >= 768) {
            limit = 4;
        }
        else if (window.innerWidth < 768) {
            limit = 2;
        }

        return limit;
    }, [window.innerWidth]);

    useEffect(() => {
        // FETCH VIDEOS CONTENT
        setLoadingVideos(true);
        async function fetchAllVideos() {
            await fetchVideos(locale, 12, 0, "", null, null)
            //await fetchLatestVideos(locale, 12)
                .then((videoList) => {
                    setVideos(videoList.items);
                    setLoadingVideos(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoadingVideos(false);
                });
        }
        fetchAllVideos();
    }, [locale]);

    useEffect(() => {
        async function fetchVideoPodcastData() {
            setVideoPodcastLoading(true)
            await fetchLatestVideoPodcasts(locale, 6)
                .then(podcastList => {
                    setVideoPodcastLoading(false)
                    setVideoPodcasts(podcastList.items);
                })
                .catch(error => {
                    console.log(error);
                    setVideoPodcastLoading(false)
                })
        }
        async function fetchAudioPodcastData() {
            setAudioPodcastLoading(true)
            await fetchLatestAudioPodcasts(locale, 6)
                .then(podcastList => {
                    setAudioPodcastLoading(false)
                    setAudioPodcasts(podcastList.items);
                })
                .catch(error => {
                    console.log(error);
                    setAudioPodcastLoading(false)
                })
        }

        fetchAudioPodcastData()
        fetchVideoPodcastData()
    }, [locale])


    return (
        <div>
            <div class="container-fluid featured more_content light_theme">
                <div class="row mt-0 mb-0 mt-md-3 mb-md-3">
                    {!loadingVideos && videos &&
                        videos.length > 0 && <div class="col-12 d-flex justify-content-start mt-0">
                        <h2 class="align-self-end cat_11">{t("podcasts.latestVideos")}</h2>
                        <Link
                            onClick={() =>
                                dispatch({ type: "CHANGED_PATH", path: `/${locale === "arSA" ? "ar" : "en"}/Video-Library` })
                            }
                            to={{
                                pathname: `/${locale === "arSA" ? "ar" : "en"}/Video-Library`
                            }}
                            className="view_all align-self-end cat_11">
                            {t("generic.viewAll")}
                        </Link>
                    </div>}
                </div>
                <div class="row pl-3">

                    {!loadingVideos && videos &&
                        videos.slice(0, 10).map((cardnew, index) => {
                            return (
                                <div class="col-12 col-md-4 col-lg-3 col-xl-2 pl-0 mb-4">
                                    <VideoCard props={props}
                                        loadingVideos={loadingVideos}
                                        key={index}
                                        type="card"
                                        icon="video_icon"
                                        cardSize="card_news three_row_news"
                                        cardinfo={cardnew}
                                        video={cardnew?.fields?.video?.directURL}
                                        image={cardnew?.fields?.cover_image?.directURL}
                                        imageClass={cardnew?.fields?.image_alignment}
                                        createdDate={
                                            cardnew?.fields?.authoring_date?.value
                                        }
                                        summary={cardnew?.description}
                                    />
                                </div>
                            );
                        })}

                </div>
                <div>
 {/*                    {loadingVideos && getNumberOfCards() === 8 ?
                        <div>
                            <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                            <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                            <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                            <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                            <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                        </div>
                        : loadingVideos && getNumberOfCards() === 6 ?
                            <div>
                                <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                                <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                                <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                                <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                            </div>
                            : loadingVideos && getNumberOfCards() === 4 ?
                                <div>
                                    <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                                    <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                                    <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                                </div>
                                : loadingVideos && getNumberOfCards() === 2 ?
                                    <div>
                                        <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                                    </div>
                                    : null

                    } */}
                   {/*  {!loadingVideos &&
                        <h3 style={{ color: theme === "light" ? "black" : "white", float: locale === "enUS" ? "" : "right" }}>{t('errors.noResults')}</h3>
                    } */}
                </div>

                {/* PROGRAMS */}

                {env.showPrograms && !programs.loading && programs && programs.length> 0 && <div class="row mt-2 mb-0 mt-md-5 mb-md-3">
                    <div class="col-12 d-flex justify-content-start mt-0">
                        <h2 class="align-self-end cat_11">{t("podcasts.latestPrograms")}</h2><a href={`/${locale === "arSA" ? "ar" : "en"}/programs/`} class="view_all align-self-end cat_11">{t("generic.viewAll")}</a>
                    </div>
                </div>}
                <div class="row pl-3">
                    {env.showPrograms && !programs.loading && programs &&
                        programs.slice(0, 5).map((program, index) => (
                            <Link className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0"
                                onClick={() =>
                                    dispatch({
                                        type: "CHANGED_PATH",
                                        path: `/${locale === "arSA" ? "ar" : "en"}/programs/${program.title}`
                                    })
                                }
                                to={{
                                    pathname: `/${locale === "arSA" ? "ar" : "en"}/programs/${program.title}`
                                }}
                            >

                                <div class="card_news three_row_news program">
                                    <dl style={{ color: "white" }} class="video_icon">
                                        <dt>{program.title}</dt>
                                        <dt>{program.latestEpisode !== "No latest episode" ? program.latestEpisode : null}</dt>
                                        <dd>{program.latestEpisodeDate !== "No latest episode" ? getDateFromNowPrograms(program.latestEpisodeDate, locale) : null}</dd>
                                    </dl>
                                    <a href="#" title="">
                                        <img src={program.image} alt="news image" class="top" />
                                    </a>
                                </div>

                            </Link>
                        ))
                    }
                </div>
                <div>
                    {/* {programLoading && getNumberOfCards() === 8 ?
                        <div>
                            <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                            <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                            <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                            <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                            <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                        </div>
                        : programLoading && getNumberOfCards() === 6 ?
                            <div>
                                <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                                <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                                <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                                <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                            </div>
                            : programLoading && getNumberOfCards() === 4 ?
                                <div>
                                    <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                                    <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                                    <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                                </div>
                                : programLoading && getNumberOfCards() === 2 ?
                                    <div>
                                        <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                                    </div>
                                    : null

                    } */}
                    {/* {!programLoading && programs && programs.length === 0 &&
                        <h3 style={{ color: theme === "light" ? "black" : "white", float: locale === "enUS" ? "" : "right" }}>{t('errors.noResults')}</h3>
                    } */}
                </div>

                {!audioPodcastLoading && audioPodcasts && audioPodcasts.length > 0 && <div class="row mt-2 mb-0 mt-md-5 mb-md-3">
                    <div class="col-12 d-flex justify-content-start mt-0">
                        <h2 class="align-self-end cat_11">{t("podcasts.latestOnAudio")}</h2>
                        <Link
                            onClick={() =>
                                dispatch({ type: "CHANGED_PATH", path: `/${locale === "arSA" ? "ar" : "en"}/podcasts` })
                            }
                            to={{
                                pathname: `/${locale === "arSA" ? "ar" : "en"}/podcasts`
                            }}
                            className="view_all align-self-end cat_11">
                            {t("generic.viewAll")}
                        </Link>
                    </div>
                </div>}
                <div class="row pl-3">
                    {!audioPodcastLoading && audioPodcasts && audioPodcasts.map((card, index) => (
                        <div class="col-12 col-md-4 col-lg-3 col-xl-2 pl-0">
                            <div class="card_news square program">
                                <Link to={{ pathname: `/${locale === "arSA" ? "ar" : "en"}/podcasts/${card?.type}/${card?.id}` }} title="">
                                    <img className={card?.fields?.image_alignment} src={card?.fields?.cover_image?.directURL} alt="Podcast cover" />
                                </Link>
                            </div>
                            <p class="album_title">{card?.fields?.title}</p>
                            <dl class="album">
                                <dt>{card?.latestEpisodeName}</dt>
                                <dd>{getDateFromNow(card?.latestEpisodeDate, locale)}</dd>
                            </dl>
                        </div>
                    ))}

                </div>
                <div>
                   {/*  {audioPodcastLoading && getNumberOfCards() === 8 ?
                        <div>
                            <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                            <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                            <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                            <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                            <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                        </div>
                        : audioPodcastLoading && getNumberOfCards() === 6 ?
                            <div>
                                <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                                <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                                <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                                <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                            </div>
                            : audioPodcastLoading && getNumberOfCards() === 4 ?
                                <div>
                                    <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                                    <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                                    <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                                </div>
                                : audioPodcastLoading && getNumberOfCards() === 2 ?
                                    <div>
                                        <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                                    </div>
                                    : null

                    } */}
                    {/* {!audioPodcastLoading && audioPodcasts && audioPodcasts.length === 0 &&
                        <h3 style={{ color: theme === "light" ? "black" : "white", float: locale === "enUS" ? "" : "right" }}>{t('errors.noResults')}</h3>
                    } */}
                </div>
                {!videoPodcastLoading && videoPodcasts && videoPodcasts.length > 0 && <div class="row mt-2 mb-0 mt-md-5 mb-md-3">
                    <div class="col-12 d-flex justify-content-start mt-0">
                        <h2 class="align-self-end cat_11">{t("podcasts.latestOnVideo")}</h2>
                        <Link
                            onClick={() =>
                                dispatch({ type: "CHANGED_PATH", path: `/${locale === "arSA" ? "ar" : "en"}/podcasts` })
                            }
                            to={{
                                pathname: `/${locale === "arSA" ? "ar" : "en"}/podcasts`
                            }}
                            className="view_all align-self-end cat_11">
                            {t("generic.viewAll")}
                        </Link>
                    </div>
                </div>}
                <div class="row pl-3">
                    {!videoPodcastLoading && videoPodcasts && videoPodcasts.map((card, index) => (
                        <div class="col-12 col-md-4 col-lg-3 col-xl-2 pl-0">
                            <div class="card_news three_row_news program">
                                <Link to={{ pathname: `/${locale === "arSA" ? "ar" : "en"}/podcasts/${card?.type}/${card?.id}` }} title="">
                                    <img className={card?.fields?.image_alignment} src={card?.fields?.cover_image?.directURL} alt="Podcast cover" />
                                </Link>
                            </div>
                            <p class="album_title">{card?.fields?.title}</p>
                            <dl class="album">
                                <dt>{card?.latestEpisodeName}</dt>
                                <dd>{getDateFromNow(card?.latestEpisodeDate, locale)}</dd>
                            </dl>
                        </div>
                    ))}

                </div>
                <div>
                   {/*  {videoPodcastLoading && getNumberOfCards() === 8 ?
                        <div>
                            <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                            <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                            <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                            <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                            <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                        </div>
                        : videoPodcastLoading && getNumberOfCards() === 6 ?
                            <div>
                                <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                                <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                                <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                                <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                            </div>
                            : videoPodcastLoading && getNumberOfCards() === 4 ?
                                <div>
                                    <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                                    <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                                    <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                                </div>
                                : videoPodcastLoading && getNumberOfCards() === 2 ?
                                    <div>
                                        <NewsCardLoader className="col-12 col-md-4 col-lg-3 col-xl-2 pl-0" />
                                    </div>
                                    : null

                    } */}
                    {/* {!videoPodcastLoading && videoPodcasts && videoPodcasts.length === 0 &&
                        <h3 style={{ color: theme === "light" ? "black" : "white", float: locale === "enUS" ? "" : "right" }}>{t('errors.noResults')}</h3>
                    } */}
                </div>
            </div>
        </div>

    )
}

export default withTranslation()(OurContent);
