import React, { useState, useEffect, useRef } from "react";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import NewsCard from "./NewsCard";
import SpinnerLoader from "../Utils/Loaders/SpinnerLoader";
import { isFutureDate } from "../../scripts/utils";
import { deviceDetect } from "react-device-detect";
import useMobileDetect from "use-mobile-detect-hook";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel-rtl";
//import OwlCarousel from 'react-owl-carousel2fix';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import sendAnalyticsEvent from "../../scripts/analyticsEvent";




export const setDevice = (device) => async (dispatch) => {
  dispatch({ type: "CHANGE_DEVICE", device: device });
};


const NewsHighlights = React.memo(({ props, ...rest }) => {

  const { t, analyticsInfo } = props;

  const sliderRef = useRef();
  const detectMobile = useMobileDetect();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (detectMobile.isMobile()) setIsMobile(true);
    
  }, [detectMobile]);


  const data = useSelector((state) => state.highlightsNews.newsList);
  const loadingHighlights = useSelector((state) => state.highlightsNews.loading);
  const locale = useSelector(state => state.info.locale);
  const cookieConsent = useSelector((state) => state.info.cookieConsent.consent);
  const highlightsState = useSelector((state) => state.highlightsNews);
  
  const responsive = {
    margin: 30,
    responsive: true,
    responsiveClass: true,
    autoWidth: true,
    dots: false,
    responsive: {
        0: {
            items: 4,
            loop: false
        },
        400: {
          items: 4,
          loop: false
        },
        600: {
            items: 2,
        },
        700: {
            items: 3,
        },
        1000: {
            items: 5,
        }
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    //slidesToShow: 3,
    variableWidth: true,
    arrows: false,
    swipe: false,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          infinite: false
        }
      }
    ]
  };

  function next() {
    sliderRef.current.next();
    sendAnalyticsEvent({cookieConsent: cookieConsent ,type:"click", label:`next`, section:"news highlights carousel", page:props.analyticsInfo?.page})

  }
  function previous() {
    sendAnalyticsEvent({cookieConsent: cookieConsent ,type:"click", label:`previous`, section:"news highlights carousel", page:props.analyticsInfo?.page})

    sliderRef.current.prev();
  }

  const [expanded, setExpanded] = useState(false);

  return data ? (
    <div className="col-12 col-md-8 pl-lg-3">
      <div className="row mb-2">
        {highlightsState.newsList.length !== 0 &&
          <div className="col-6">
            {
              isMobile 
              ? 
              <button
                className="button_open"
                role="button"
                aria-expanded={expanded ? "true" : "false"}
                aria-controls="collapseExample"
                onClick={(e) => {
                  setExpanded(!expanded);
                }}
                >
                <h2>{t("newsHighlightsComp.highlights")}</h2>
              </button>

              : <h2>{t("newsHighlightsComp.highlights")}</h2>
              }
            
          </div>}
        {data && data.length >= 4 ?
          <div className="col-6 d-flex justify-content-end">
            <button className="previous" onClick={previous}></button>
            <button className="next" onClick={next}></button>
          </div> : null}
      </div>

      <div className="row">
        <div className={expanded ? "col-12 highlights_wrapper expanded_highlights": "col-12 highlights_wrapper "}> 
          {loadingHighlights && <SpinnerLoader />}
          {!isMobile && (data && data.length >= 4 ) ?
            
            <OwlCarousel ref={sliderRef} className="owl-carousel owl-theme" responsive={responsive} loop={isMobile ? false : true} rtl={true} autoWidth={isMobile ? false : true} dots={false}>
              {!loadingHighlights &&
                data.map((cardnew, index) => {
                  return (
                    typeof cardnew.fields.highlight_expiration_date === 'undefined' || cardnew.fields.highlight_expiration_date === null || isFutureDate(cardnew?.fields?.highlight_expiration_date?.value, locale)
                       ?
                    <div className="item">
                      
                    <NewsCard
                      id={cardnew?.id}
                      loadingHighlights={loadingHighlights}
                      key={index}
                      type="card"
                      icon={cardnew?.fields?.has_video ? "video_icon" : "link_icon"}
                      cardSize="card_news highlight_news"
                      cardinfo={cardnew}
                      image={cardnew?.fields?.cover_image?.directURLArray?.small}
                      imageClass={cardnew?.fields?.image_alignment}
                      createdDate={
                        cardnew?.fields?.authoring_date?.value
                      }
                      summary={cardnew?.fields?.title}
                      analyticsInfo={rest.analyticsInfo}
                      // tagList={cardnew?.fields?.tags_list}
                    />
                  
                  </div> : null
                  );
                })}
            </OwlCarousel>
            : (isMobile && (data && data.length >= 4 ) ?
            <Slider {...settings} className="highlights">
            {!loadingHighlights &&
              data.map((cardnew, index) => {
                return (
                  typeof cardnew.fields.highlight_expiration_date === 'undefined' || cardnew.fields.highlight_expiration_date === null || isFutureDate(cardnew?.fields?.highlight_expiration_date?.value?.substring(0, 10), locale)
                     ?
                  <div className="item">
                    
                  <NewsCard
                    id={cardnew?.id}
                    loadingHighlights={loadingHighlights}
                    key={index}
                    type="card"
                    icon={cardnew?.fields?.has_video ? "video_icon" : "link_icon"}
                    cardSize="card_news highlight_news"
                    cardinfo={cardnew}
                    image={cardnew?.fields?.cover_image?.directURLArray?.small}
                    imageClass={cardnew?.fields?.image_alignment}
                    createdDate={
                      cardnew?.fields?.authoring_date?.value
                    }
                    summary={cardnew?.fields?.title}
                    analyticsInfo={rest.analyticsInfo}
                  /> 
                
                </div> : null
                );
              })}
          </Slider>
            :
            <div className="highlights" style={{ float: locale === "arSA" ? "right" : null }}>
              {!loadingHighlights &&
                data.map((cardnew, index) => {
                  
                  return (
                    cardnew.fields.highlight_expiration_date === null || isFutureDate(cardnew?.fields?.highlight_expiration_date?.value?.substring(0, 10), locale) ?
                    <div>
                      
                    <NewsCard
                      id={cardnew?.id}
                      loadingHighlights={loadingHighlights}
                      key={index}
                      type="card"
                      icon={cardnew?.fields?.has_video ? "video_icon" : "link_icon"}
                      cardSize="card_news highlight_news"
                      cardinfo={cardnew}
                      image={cardnew?.fields?.cover_image?.directURLArray?.small}
                      imageClass={cardnew?.fields?.image_alignment}
                      createdDate={
                        cardnew?.fields?.authoring_date?.value
                      }
                      summary={cardnew?.fields?.title}
                      // tagList={cardnew?.fields?.tags_list}
                      analyticsInfo={rest.analyticsInfo}
                      /> 
                      
                      </div>: null
                      );
                })
              }
            </div>
            )}

        </div>
      </div>
    </div>
  ) : null;
});

export default withTranslation()(NewsHighlights);
