import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import SmallNewsCard from "../components/NewsCategory/SmallNewsCard";
import { fetchMostReadNews } from "../store/actions/news";
import SmallNewsCardLoader from "../components/Utils/Loaders/SmallNewsCardLoader";
import axios from "axios";
import debounce from 'lodash.debounce';
import EmptyResultsPage from "./EmptyResultsPage";
import sendAnalyticsEvent from "../scripts/analyticsEvent";


const MostReadNewsListPage = (props) => {

    /**
     * TODO:
     * adicionar um loading effect
     */

    const { t } = props;

    const { state } = useLocation();

    const locale = useSelector((state) => state.info.locale);
    const cookieConsent = useSelector((state) => state.info.cookieConsent.consent);

    const theme = useSelector((state) => state.info.theme);
    const isMostRead = useSelector((state) => state.homepage.showMostReadViews);

    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);

    const getNumberOfCards = useCallback(() => {

        let limit = 0;

        if (window.innerWidth >= 1200) {
            limit = 8;
        }
        else if (window.innerWidth < 1200 && window.innerWidth >= 991) {

            limit = 6;
            // counter = counter + 1;

            // if (counter === 1) {
            //   limit = 3 - (searchResults.length) % 3 // falta mudar o valor que o loading vai receber laa em baixo
            // }
        }
        else if (window.innerWidth < 991 && window.innerWidth >= 768) {
            limit = 4;
        }
        else if (window.innerWidth < 768) {
            limit = 2;
        }

        return limit;
    }, [window.innerWidth]);

    useEffect(() => {

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        setLoading(true);

        async function fetchMostRead() {
            await fetchMostReadNews(locale, getNumberOfCards(), offset, source.token)
                .then((newsList) => {
                    if (news !== null && news.length > 0) {
                        newsList.map((item) => {
                            setNews((prevResults) => [
                                ...new Set([...prevResults, item])
                            ]);
                            setLoading(false);
                        });
                    } else {
                        setNews(newsList);
                        setLoading(false);
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    setHasMore(false);
                    setLoading(false);
                    console.log(error);
                });
        }
        fetchMostRead();
        return () => {
            source.cancel("Cancelled due to another request")
        }
        
    }, [locale, offset]);

    const handleOffset = (node) => {
        if (loading) return;
        // o node ee o ultimo elem se nao ee o node queremos dar disconnect
        if (observer.current) observer.current.disconnect();
        // entries vai corresponder a tudo que se vai tornar visivel depois do scroll
        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && hasMore) {
                setOffset((prevValue) => prevValue + getNumberOfCards());
            }
        });
        if (node) observer.current.observe(node);

    };
    const observer = useRef();
    const lastElementRef = useCallback(debounce(handleOffset, 1000), [loading, hasMore]);
    /* const lastElementRef = useCallback(
        (node) => {
            if (loading) return;
            // o node ee o ultimo elem se nao e o node queremos dar disconnect
            if (observer.current) observer.current.disconnect();

            // entries vai corresponder a tudo que se vai tornar visivel depois do scroll
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMore) {
                    setOffset((prevValue) => prevValue + getNumberOfCards());
                }
            });

            if (node) observer.current.observe(node);
        },
        [loading, hasMore]
    ); */


    useEffect(() => {
		sendAnalyticsEvent({type:"viewed",page:"most read news list", cookieConsent: cookieConsent});
	}, [])


    return news ? (
        <div className="container-fluid featured">
            <div className="row mb-5">
                <div className="col-12 d-md-flex justify-content-md-start">
                    <h1 className="align-self-end no_cat">
                        {t('newsDetailPage.mostRead')}
                    </h1>
                </div>
            </div>
            <div className="row mt-4 pl-3" style={{ paddingRight: locale === "arSA" ? "1rem" : "" }}>
                {news &&
                    news.map((card, index) => {
                        if (news.length === index + 1) {
                            return (
                                <div
                                    ref={lastElementRef}
                                    className="col-12 col-md-6 col-lg-4 col-xl-3 p-0 mb-4"
                                >
                                    <SmallNewsCard
                                        key={index}
                                        id={card.newsId}
                                        type="News"
                                        title={card.title}
                                        summary={card.summary}
                                        cover_image={card.cover_image?.directURLArray.small}
                                        imageClass={card?.image_alignment}
                                        category={
                                            card.taxonomies && Object.keys(card.taxonomies).length > 0
                                                ? Object.keys(card.taxonomies)[0]
                                                : "No Category"
                                        }
                                        newsticker={false}
                                        counter={card.counter}
                                        viewCounts={isMostRead}
                                    />
                                </div>
                            );
                        } else {
                            return (
                                <div className="col-12 col-md-6 col-lg-4 col-xl-3 p-0 mb-4"
                                >
                                    <SmallNewsCard
                                        ref={lastElementRef}
                                        key={index}
                                        id={card.newsId}
                                        type="News"
                                        title={card.title}
                                        summary={card.summary}
                                        cover_image={card.cover_image?.directURLArray.small}
                                        imageClass={card?.image_alignment}
                                        category={
                                            card.taxonomies && Object.keys(card.taxonomies).length > 0
                                                ? Object.keys(card.taxonomies)[0]
                                                : "No Category"
                                        }
                                        newsticker={false}
                                        counter={card.counter}
                                        viewCounts={isMostRead}
                                    />
                                </div>
                            );
                        }
                    })}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
                {loading && getNumberOfCards() === 8 ?
                    <div>
                        <SmallNewsCardLoader className="col-12 col-md-6 col-lg-4 col-xl-3 p-0 mb-4" />
                        <SmallNewsCardLoader className="col-12 col-md-6 col-lg-4 col-xl-3 p-0 mb-4" />
                        <SmallNewsCardLoader className="col-12 col-md-6 col-lg-4 col-xl-3 p-0 mb-4" />
                        <SmallNewsCardLoader className="col-12 col-md-6 col-lg-4 col-xl-3 p-0 mb-4" />
                    </div>
                    : loading && getNumberOfCards() === 6 ?
                        <div>
                            <SmallNewsCardLoader className="col-12 col-md-6 col-lg-4 col-xl-3 p-0 mb-4" />
                            <SmallNewsCardLoader className="col-12 col-md-6 col-lg-4 col-xl-3 p-0 mb-4" />
                            <SmallNewsCardLoader className="col-12 col-md-6 col-lg-4 col-xl-3 p-0 mb-4" />
                        </div>
                        : loading && getNumberOfCards() === 4 ?
                            <div>
                                <SmallNewsCardLoader className="col-12 col-md-6 col-lg-4 col-xl-3 p-0 mb-4" />
                                <SmallNewsCardLoader className="col-12 col-md-6 col-lg-4 col-xl-3 p-0 mb-4" />
                            </div>
                            : loading && getNumberOfCards() === 2 ?
                                <div>
                                    <SmallNewsCardLoader className="col-12 col-md-6 col-lg-4 col-xl-3 p-0 mb-4" />
                                </div>
                                : null

                } 
            </div>
            {!loading && news.length === 0 &&
                    <EmptyResultsPage></EmptyResultsPage>
                }
        </div>

    ) : null
}

export default withTranslation()(MostReadNewsListPage);