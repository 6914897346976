import React from 'react';
import { withTranslation } from 'react-i18next';

import ProgramsCard from '../Programs/ProgramsCard';
import SpinnerLoader from '../Utils/Loaders/SpinnerLoader';


const ListAllPrograms = ({ programs, isLoading, props }) => {

  const { t } = props;
  return (
    <div class="container-fluid featured">

      <div class="row mt-5 mb-4">
        <div class="col-12 d-flex justify-content-start mt-3">
          <h2 class="align-self-end cat_11">{t("programs.allPrograms")}</h2>
        </div>
      </div>

      <div class="row pl-3">
        {programs && !isLoading ?
          programs.map((program, index) => (
            <ProgramsCard key={index} name={program.title} image={program.image} props={props} />
          ))
          : <SpinnerLoader backgroundColor="white" />
        }
      </div>

    </div>
  )
}

export default withTranslation()(ListAllPrograms);