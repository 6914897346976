import env from "../../config/env";
import axios from "axios";

/**
 * Get all Video Podcasts
 *
 * @returns {*} - list of all Video Podcasts
 */
export function fetchVideoPodcasts(name, locale, limit) {

    let endpoint = "";

    if (name !== "") endpoint = `&summary=${name}&title${name}`

    return new Promise((resolve, reject) => {
        axios({
            url: `${env.serverUrl}/ekh-rest-api/video_podcast/search/all?contentLanguage=${locale}&resultLimit=${limit}${endpoint}`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log('Video Podcast Request Error');
                console.log(error);
                reject();
            });
    });
}


/**
 * Get latest Video Podcasts
 *
 * @returns {*} - list of all Video Podcasts
 */
 export function fetchLatestVideoPodcasts(locale, limit) {

    return new Promise((resolve, reject) => {
        axios({
            url: `${env.serverUrl}/ekh-rest-api/video_podcast/search/latest?contentLanguage=${locale}&resultLimit=${limit}`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log('Video Podcast Request Error');
                console.log(error);
                reject();
            });
    });
}
/**
 * Get all Audio Podcasts
 *
 * @returns {*} - list of all Audio Podcasts
 */
export function fetchAudioPodcasts(name, locale, limit) {

    let endpoint = "";

    if (name !== "") endpoint = `&summary=${name}&title${name}`

    return new Promise((resolve, reject) => {
        axios({
            url: `${env.serverUrl}/ekh-rest-api/audio_podcast/search/all?contentLanguage=${locale}&resultLimit=${limit}${endpoint}`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log('Audio Podcast Request Error');
                console.log(error);
                reject();
            });
    });
}

/**
 * Get latest Audio Podcasts
 *
 * @returns {*} - list of all Audio Podcasts
 */
 export function fetchLatestAudioPodcasts(locale, limit) {

    return new Promise((resolve, reject) => {
        axios({
            url: `${env.serverUrl}/ekh-rest-api/audio_podcast/search/latest?contentLanguage=${locale}&resultLimit=${limit}`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log('Audio Podcast Request Error');
                console.log(error);
                reject();
            });
    });
}


/**
 * Get an Audio Podcast by id
 *
 * @returns {*} - list of Audio Podcasts by id
 */
export function fetchPodcastsByID(type, podcastID, locale) {
    return new Promise((resolve, reject) => {
        axios({
            url: `${env.serverUrl}/ekh-rest-api/${type}/${podcastID}?contentLanguage=${locale}`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log('Audio Podcast Request Error');
                console.log(error);
                reject();
            });
    });
}


/**
 * Get episodes based on name and title and type(audio / video)
 *
 * @returns {*} - list of episodes based on search parameters
 */
export function fetchEpisodeSearch(name, type, locale, podcastID) {
    let endpoint, title, summary;

    if (name) {
        title = name;
        summary = name;
        endpoint = `&summary=${summary}&title=${title}`;
    }
    else
        endpoint = ''
    return new Promise((resolve, reject) => {
        axios({
            url: `${env.serverUrl}/ekh-rest-api/${type}/episodes/search?contentLanguage=${locale}&podcastId=${podcastID}${endpoint}`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log('Audio Podcast Request Error');
                console.log(error);
                reject();
            });
    });

}


export function fetchVideoPodcastById(locale, episodeId) {
    return new Promise((resolve, reject) => {
        axios({
            url: `${env.serverUrl}/ekh-rest-api/video_podcast/episodes/search?contentLanguage=${locale}&episodeId=${episodeId}`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log('Video Podcast Request Error');
                console.log(error);
                reject();
            });
    });
}

