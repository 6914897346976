import React, { useState, useRef, useEffect } from "react";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import Share from "./SocialMediaShare/Share";
import moment from "moment";
import { KalturaPlayerProvider } from "../kaltura-player";
import { PlayerContainer } from "../../pages/player-container";
import { EntriesConfig } from "../../pages/player-container";
import { PlayerBundleConfig } from "../kaltura-player";
import env from "../../config/env";


const VideoPlayer = (props) => {

    const { t } = props;

    const locale = useSelector(state => state.info.locale);
    const [duration, setDuration] = useState(null);

    const [playerConfig, setPlayerConfig] = useState(null);
    const [entriesConfig, setEntriesConfig] = useState(null)


    useEffect(() => {

        setPlayerConfig({
            bundlerUrl: "https://cdnapisec.kaltura.com",
            partnerId: env.partnerId,
            ks: "",
            uiConfId: env.uiConfId
        });
        setEntriesConfig({
            entryId: props.entryId,
            alternateEntryId: "1_p1ekn50i"
        });
    }, [])


    return (
        <div>
            <Modal open={props.open} onClose={props.onClose}
                showCloseIcon={false} closeOnOverlayClick={true}
                styles={{
                    modal: {
                        maxWidth: "46%",
                        width: "100%",
                        padding: "unset",
                    },
                    overlay: {
                        background: "rgba(2, 0, 0, 0.5)",

                    },
                    closeButton: {
                        background: "yellow"
                    },
                }}
                center>
                {playerConfig && entriesConfig && entriesConfig.entryId ? (
                    <>
                        <KalturaPlayerProvider playerBundleConfig={playerConfig}>
                            <PlayerContainer entriesConfig={entriesConfig} />
                        </KalturaPlayerProvider>
                    </>
                ) :
                    <ReactPlayer
                        url={props.episode}
                        controls={true}
                        width="100%"
                        height="100%"
                        onDuration={e => { setDuration(e) }}
                    />
                }
                <div className="row share m-0">
                    <div className="col-12" style={{ display: locale === "arSA" ? "flex" : "", flexDirection: "column", textAlign: "initial" }}>

                        {props.date &&
                            <h6 className="font-weight-bold">{props.date} | {props.title}</h6>}
                        {props.duration || duration &&
                            <h6 >{props.type === "Program-Video" ? moment.unix(props.duration).utc().minutes() + " " + t("programs.minutes") : moment.utc(duration * 1000).format('mm:ss') + " " + t("programs.minutes")}</h6>}
                    </div>
                    <div className="col-12">
                        {/* {props.title &&
                            <h6 className={"font-weight-bold mt-0"}>{props.title}</h6>} */}
                        {props.summary &&
                            <p>{props.summary}</p>}
                    </div>
                    <div className="col-12" style={{ display: locale === "arSA" ? "flex" : "block" }} >
                        <ul class="social_share  my-2 justify-content-start text-dark d-flex">
                            <Share Id={props.id} Title={props.title} Summary={props.summary} image={props.image} type={props.type} videoUrl={props.episode}/>
                        </ul>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default withTranslation()(VideoPlayer);
