import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';

import AllProgramEpisodeCard from './AllProgramEpisodeCard';


const ListAllEpisodes = ({ episodes, props }) => {

  const { t } = props;

  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
  }, [isClicked])

  const handleClick = () => {
    setIsClicked(true)
  }
  

  return (
    <div>
      {episodes &&
        episodes.map((episode, index) => {
          return (
            <AllProgramEpisodeCard
              key={index}
              startTime={episode.start_time}
              programName={episode.programName}
              isLive={episode.isLive}
              duration = {episode.duration}
              props={props}
            />
          )
        })}
    </div>
  )
}

export default withTranslation()(ListAllEpisodes);