import {
  HIGHLIGHTS_LIST_REQUEST,
  HIGHLIGHTS_LIST_SUCESS,
  HIGHLIGHTS_LIST_FAIL,
  NEWSTICKER_LIST_REQUEST,
  NEWSTICKER_LIST_SUCESS,
  NEWSTICKER_LIST_FAIL,
  HOMEPAGE_REQUEST,
  HOMEPAGE_SUCESS,
  HOMEPAGE_FAIL
} from "../constants/newsConstants";

export function listHighlightsNews(state = { newsList: [] }, action) {

  switch (action.type) {
    case HIGHLIGHTS_LIST_REQUEST:
      return {
        ...state,
        newsList: [],
        loading: true
      };
    case HIGHLIGHTS_LIST_SUCESS:
      return {
        ...state,
        newsList: action.payload,
        loading: false
      };
    case HIGHLIGHTS_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: true
      };
    default:
      return state;
  }
}

export function listNewstickerNews(state = { newsList: [] }, action) {

  switch (action.type) {
    case NEWSTICKER_LIST_REQUEST:
      return {
        ...state,
        newsList: [],
        loading: true
      };
    case NEWSTICKER_LIST_SUCESS:
      return {
        ...state,
        newsList: action.payload,
        loading: false
      };
    case NEWSTICKER_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: true
      };

    default:
      return state;
  }
}

export function listHomepage(state = { showMostReadViews: [] }, action) {

  switch (action.type) {
    case HOMEPAGE_REQUEST:
      return {
        ...state,
        showMostReadViews: [],
        loading: true
      };
    case HOMEPAGE_SUCESS:
      return {
        ...state,
        showMostReadViews: action.payload,
        loading: false
      };
    case HOMEPAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: true
      };
    default:
      return state;
  }
}