import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { animated, useTransition } from 'react-spring';
import { withTranslation } from 'react-i18next';
import NewsTicker from "react-advanced-news-ticker";
import {
	BrowserView,
	MobileView,
	isBrowser,
	isMobile
  } from "react-device-detect";

const BreakingNews = React.memo(({ data, props, onClose, showbr }) => {

	/**
	 * TODO:
	 * fazer o scroll parar onMouseIn e continuar onMouseOut
	 */

	const { t } = props;

	const locale = useSelector((state) => state.info.locale);
	const [isVisible, setIsVisible] = useState(false);

	const [scrolled, setScrolled] = useState(false);
	const [breakingnews, setBreakingnews] = useState("");

	const transitions = useTransition(showbr, null, {
		from: { opacity: 1 },
		leave: { opacity: 0 },
	})

	const handleScroll = () => {
		const offset = window.scrollY;
		if (offset > 100) {
			setScrolled(true);
		}
		else {
			setScrolled(false);
		}
	}

	const handleClose = () => {
		//setIsVisible(false);
		onClose();
		data.map((elem, index) => {
			window.sessionStorage.setItem("breakingNews" + elem.id, "false"); 
		});
		
	}

	

	useEffect(() => {
		window.addEventListener('scroll', handleScroll)
	}, [])

	return (showbr && 
		<>
			{data.length !== 0 && transitions.map(({ item, key, props }) => (

				(item) ?
					<animated.div key={key} style={{...props, position: "fixed"}} className={
						isMobile ? 'breaking_news alert alert-dismissible fade show' :
						scrolled ? 'breaking_news alert alert-dismissible fade show fixed-live' : 'breaking_news alert alert-dismissible fade show'} role="alert">
						<div className="pulse_dot float-left">
							<div className="dot"></div>
						</div>
						<div className="breaking_news_title float-left">
							<h2 style={{ minWidth: locale === "enUS" ? "180px" : "" }} className="float-left d-none d-md-block">{t('header.breakingNews')}</h2>
									{/*<NewsTicker style={{float:locale === "arSA" ? "right" : ""}} maxRows = {1}>
									{data.map((elem, index) => 		
										<div style={{marginRight:locale === "arSA" ? "4px" : "", marginLeft:locale === "arSA" ? "" : "4px"}} key={index}>{elem.fields.title}</div>												
									)}  
								</NewsTicker> */}
							<marquee scrollamount="7" direction={locale === "arSA" ? "right" : "left"}>
								<ul class="ticker-left">
									{data.map((elem, index) => {
										if (elem.fields && elem.fields.title) {
											return (
												//<li key={index}>{elem.fields.title.join('\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0')}</li>
												<li key={index}>{elem.fields.title.concat('\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0')}</li>
											)
										}
									})}
								</ul>
							</marquee>
						</div>
						<button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => handleClose() }>
							<span aria-hidden="true"></span>
						</button>
					</animated.div>
					: null
			))}
		</>
	)
});

export default withTranslation()(BreakingNews);