import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"
import rootReducer from './modules/rootReducer';
import CookieConsent from '../components/Utils/CookieConsent';

const midleware = [thunk]

const initialState = {
  locale: "arSA",
  pathname: JSON.parse(localStorage.getItem("pathname")) ? `/${JSON.parse(localStorage.getItem("pathname"))}` : window.location.pathname,
  streamRefresh: true,
  cookieConsent: JSON.parse(localStorage.getItem("cookieConsent"))
  ? JSON.parse(localStorage.getItem("cookieConsent"))
  : {consent: undefined},
}

const store = createStore(rootReducer, { info: initialState }, composeWithDevTools(applyMiddleware(...midleware)));

export default store;